/* eslint-disable react/no-array-index-key */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const PaymentHistoryTable = ({ items, isLoading }) => {
  const tableData =
    items && items.length ? (
      items.map((item, i) => {
        const { Trans_no, Tran_date, Charge, Payment, Description } = item;
        return (
          <tr
            className="text-nowrap lighter_font font_small "
            style={{ fontSize: '14px' }}
            key={i}
          >
            <td>{i + 1}</td>
            <td>{Trans_no}</td>
            <td>{moment(Tran_date).format('DD-MMM-YYYY')}</td>
            <td>{Charge || Payment}</td>
            <td>{Description}</td>
          </tr>
        );
      })
    ) : (
      <tr
        className="text-nowrap lighter_font font_small"
        style={{ fontSize: '14px' }}
      >
        No record
      </tr>
    );
  return (
    <div
      className="table-responsive mb-5 p-0 text-nowrap"
      style={{ width: '28rem' }}
    >
      <table className="table">
        <thead>
          <tr
            className="font-weight-normal mt-0"
            style={{ fontSize: '13px', color: '#1E8E8B' }}
          >
            <th scope="col">#</th>
            <th scope="col">Transaction ID</th>
            <th scope="col">Date</th>
            <th scope="col">Amount</th>
            <th scope="col">Description</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <div>
              <img alt="loading..." src="images/TizetiLoaderColored.gif" />
            </div>
          ) : (
            tableData
          )}
        </tbody>
      </table>
    </div>
  );
};

PaymentHistoryTable.propTypes = {
  items: PropTypes.arrayOf.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PaymentHistoryTable;

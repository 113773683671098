/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { UseAccounts } from '../../../hooks/accounts/useAccounts';
import usePricingPlan from '../../../hooks/pricing-plan/usePricingPlan';
import { useUser } from '../../../hooks/user/useUser';
import Layout from '../../layouts';
import BasicPlanBody from './BasicPlanBody';
import BasicPlanHeader from './BasicPlanHeader';

const BasicPlan = () => {
  const transaction_limit = 400000;
  const [monthNumber, setMonthNumber] = useState('12');
  const basicprice = 500;
  const basicpriceTotal = monthNumber * 500;
  const [monthNum, setMonthNum] = useState('1');
  const months = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const month = [24, 36, 48];
  const [{ itemPrices, accounts }] = UseAccounts();
  const [
    {
      user: { firstName, lastName },
    },
  ] = useUser();
  return (
    <div>
      <Layout>
        <div className="container">
          <BasicPlanHeader />
          <div className="flex  flex-col  mt-3 p-3  rounded-2xl  w-full border-1 border-gray-200">
            <h3 className="text-center w-full text-base">
              {' '}
              Paddy Cover Basic Plan
            </h3>
            <BasicPlanBody
              transaction_limit={transaction_limit}
              basicprice={basicprice}
              basicpriceTotal={basicpriceTotal}
              monthNumber={monthNumber}
              setMonthNumber={setMonthNumber}
              monthNum={monthNum}
              setMonthNum={setMonthNum}
              month={month}
              months={months}
              itemPrices={itemPrices}
              accounts={accounts}
              firstName={firstName}
              lastName={lastName}
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default BasicPlan;

import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { UseAccounts } from '../../../hooks/accounts/useAccounts';
import axios from 'axios';

const UploadFile = ({ accounts }) => {
  const [files, setFiles] = useState({ image1: null, image2: null });
  const [loading, setLoading] = useState(false);
  const [go, setGo] = useState(false);
  console.log(accounts);
  const onChange = (e, key) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    // Validate file type (optional)
    if (!selectedFile.type.startsWith('image/')) {
      toast.error('Only image files are allowed!');
      return;
    }

    setFiles((prev) => ({ ...prev, [key]: selectedFile }));
  };

  const UploadImage = async (data) => {
    setLoading(true);
    const config = {
      method: 'post',
      url: `https://api.tizeti.com/api/v1/installationUploads//userfixupload`,
      data,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      },
    };
    await axios(config)
      .then((response) => {
        if (response.data[0] === 1) {
          setLoading(false);
          setFiles({ image1: null, image2: null });
        }
      })
      .catch((error) => {
        if (error) {
          toast.info('Something went wrong!');
          setLoading(false);
        }
      });
  };

  const uploadFiles = async () => {
    if (!files.image1 || !files.image2) {
      toast.error('Please upload both images.');
      return;
    }

    try {
      const uploadPromises = accounts.map(async (account, i) => {
        // Check if both fields are already uploaded

        if (
          account.contact_information_image !== null &&
          account.user_kyc !== null
        ) {
          toast.error(
            `Account ${account.mac_id} already has all documents uploaded.`,
          );
          setFiles({ image1: null, image2: null }); // Clear the files
          return Promise.resolve(); // Skip this account
        } else if (
          account.contact_information_image === null ||
          account.user_kyc === null
        ) {
          setGo(true);
          // Proceed to upload if at least one field is null
          const formData = new FormData();
          formData.append('contact_information_image', files.image1);
          formData.append('user_kyc', files.image2);
          formData.append('account_id', account.id);

          return UploadImage(formData)
            .then(() => console.log(`Upload successful for account ${i}`))
            .catch(() => {
              console.log(`Upload failed for account ${i}`);
              // Optionally handle per-account failure here
            });
        }
      });

      if (go === true) {
        // Wait for all uploads to complete
        await Promise.all(uploadPromises);

        // If we reach here, it means all promises have resolved
        toast.success('All files uploaded successfully!');
        setFiles({ image1: null, image2: null }); // Clear the files
      }
    } catch (error) {
      toast.error('Some uploads failed. Check individual messages.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid min-vh-100 d-flex flex-column  bg-light">
      <ToastContainer />

      <div className="row w-100 g-4 p-5">
        {/* Image 1 Upload Field */}
        <h5 className="mb-3 font-weight-light"> Upload KYC</h5>

        <div className="col-md-6">
          <div className="bg-white p-3 border rounded shadow-sm">
            <h5 className="mb-3 font-weight-light"> Customer Picture/Selfie</h5>
            <p className="text-muted" style={{ fontSize: '14px' }}>
              The document uploaded should be strictly:
            </p>
            <ul className="list-group list-group-flush text-sm">
              <li className="list-group-item ">
                <i className="fa fa-dot-circle-o  me-2"></i>- Customer
                Picture/Selfie
              </li>
            </ul>

            <div className="p-5">
              <input
                type="file"
                className="form-control mb-3"
                accept="image/*"
                onChange={(e) => onChange(e, 'image1')}
              />
              {files.image1 && (
                <div className="mt-2 text-center">
                  <img
                    src={URL.createObjectURL(files.image1)}
                    alt="Preview"
                    className="img-thumbnail"
                    style={{ maxHeight: '200px' }}
                  />
                  <p className="text-muted small mt-2">{files.image1.name}</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Image 2 Upload Field */}

        <div className="col-md-6 ">
          <div className="bg-white p-3 border rounded shadow-sm">
            <h5 className="mb-3 font-weight-light">Customer KYC</h5>
            <p className="text-muted" style={{ fontSize: '14px' }}>
              The document uploaded should be strictly any of these:
            </p>
            <ul className="list-group list-group-flush text-sm">
              <li className="list-group-item ">
                <i className="fa fa-dot-circle-o  me-2"></i>- NIN Slip
              </li>
              <li className="list-group-item ">
                <i className="fa fa-dot-circle-o  me-2"></i>- Driver's License
              </li>
              <li className="list-group-item ">
                <i className="fa fa-dot-circle-o  me-2"></i>- International
                Passport Page
              </li>
              <li className="list-group-item ">
                <i className="fa fa-dot-circle-o  me-2"></i>- Voters Card
              </li>
            </ul>

            <div className="p-5">
              <input
                type="file"
                className="form-control mb-3"
                accept="image/*"
                onChange={(e) => onChange(e, 'image2')}
              />
              {files.image2 && (
                <div className="mt-2 text-center">
                  <img
                    src={URL.createObjectURL(files.image2)}
                    alt="Preview"
                    className="img-thumbnail"
                    style={{ maxHeight: '200px' }}
                  />
                  <p className="text-muted small mt-2">{files.image2.name}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Upload Button */}
        <div className="mt-4 mb-5">
          {loading ? (
            <button
              className="btn btn-lg w-100 px-5 py-2 text-white text-sm"
              style={{ backgroundColor: '#00c1ac' }}
              disabled
            >
              Uploading Files...
            </button>
          ) : (
            <button
              className="btn btn-lg w-100 px-5 py-2 text-white"
              style={{ backgroundColor: '#00c1ac', fontSize: '14px' }}
              onClick={uploadFiles}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadFile;

/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as url from '../api/auth/AuthUrl';

export const loginAUser = (data) => {
  try {
    return axios.post(url.LOGIN_WITH_EMAIL_URL, data);
  } catch (e) {
    return e;
  }
};

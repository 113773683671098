/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { UseAccounts } from '../../../hooks/accounts/useAccounts';
import { useUser } from '../../../hooks/user/useUser';

const RelocationForm = ({
  show,
  handleClose,
  account,
  account: { mail, address, customerAddress },
  isLoading,
  createRelocationRequest,
}) => {
  const [
    {
      user: { firstName, lastName },
    },
  ] = useUser();

  const [{ accounts }] = UseAccounts();

  const detailsState = { phone: '', oldAddress: '', emails: '', newAddress: '', note: '' };
  const [details, setDetails] = useState(detailsState);
  const { phone, oldAddress, emails, newAddress, note } = details;
  useEffect(() => {
    const relocation = {
      phone: accounts[0]?.address,
      emails: accounts[0]?.mail,
      oldAddress: accounts[0]?.customerAddress,
      newAddress: '',
      note: '',
    };
    setDetails({ ...relocation });
  }, [account, accounts, address, mail, customerAddress]);
  const handleDetails = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      firstName,
      lastName,
      note,
      email: emails,
      phoneNumber: phone,
      old_address: oldAddress,
      new_address: newAddress,
    };

    createRelocationRequest(data);
  };

  const isEnabled = () => {
    return (
      newAddress.length > 3 &&
      (typeof phone === 'string'
        ? phone.length >= 9
        : typeof phone !== 'undefined') &&
      (typeof oldAddress === 'string'
        ? oldAddress.length > 3
        : typeof oldAddress !== 'undefined')
    );
  };
  const submitButton = () => {
    if (isLoading) {
      return (
        <button
          type="button"
          className="btn text-white"
          style={{ width: '20%', backgroundColor: '#1E8E8B' }}
          disabled
        >
          <i className="fa fa-spinner" />
        </button>
      );
    }
    return (
      <button
        type="submit"
        className="btn text-white"
        style={{ width: '20%', backgroundColor: '#1E8E8B' }}
        disabled={!isEnabled()}
      >
        Submit
      </button>
    );
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Relocation Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <div className="px-3">
            <div>
              <label
                className="m-0 font-weight-normal"
                style={{ color: '#1E8E8B', fontSize: '16px' }}
                htmlFor="phone"
              >
                Phone
              </label>
              <div className="input-group mb-3">
                <input
                  type="tel"
                  pattern="[0-9]*"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={handleDetails}
                  required
                />
              </div>
            </div>
          </div>

          <div className="px-3">
            <div>
              <label
                className="m-0 font-weight-normal"
                style={{ color: '#1E8E8B', fontSize: '16px' }}
                htmlFor="emails"
              >
               Email
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="emails"
                  name="emails"
                  value={emails}
                  onChange={handleDetails}
                  required
                />
              </div>
            </div>
          </div>

          <div className="px-3">
            <div>
              <label
                className="m-0 font-weight-normal"
                style={{ color: '#1E8E8B', fontSize: '16px' }}
                htmlFor="oldAddress"
              >
                Old Address
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="oldAddress"
                  name="oldAddress"
                  value={oldAddress}
                  onChange={handleDetails}
                  required
                />
              </div>
            </div>
          </div>

          <div className="px-3">
            <div>
              <label
                className="m-0 font-weight-normal"
                style={{ color: '#1E8E8B', fontSize: '16px' }}
                htmlFor="newAddress"
              >
                New Address
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="newAddress"
                  name="newAddress"
                  value={newAddress}
                  onChange={handleDetails}
                  required
                />
              </div>
            </div>
          </div>

          <div className="px-3">
            <div>
              <label
                className="m-0 font-weight-normal"
                style={{ color: '#1E8E8B', fontSize: '16px' }}
                htmlFor="note"
              >
                Comment
              </label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="note"
                  name="note"
                  value={note}
                  onChange={handleDetails}
                />
              </div>
            </div>
          </div>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {submitButton()}
          </Modal.Footer>
        </form>
      </Modal.Body>
      <ToastContainer />
    </Modal>
  );
};

export default RelocationForm;

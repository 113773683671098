/* eslint-disable react/jsx-indent */
import React from 'react';
import Layout from '../../layouts';
import KycBody from './KycBody';

const Kyc = () => {
  return (
    <div>
      <Layout>
        <KycBody />
      </Layout>
    </div>
  );
};

export default Kyc;

import * as types from '../../constants/types/auth/AuthTypes';

// eslint-disable-next-line import/prefer-default-export
export const ProfileReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case types.START_CHANGING_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };

    case types.STOP_CHANGING_PASSWORD:
      return {
        ...state,
        isLoading: false,
      };

    case types.CHANGING_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      // return state;
      throw new Error();
  }
};

/* eslint-disable react/jsx-indent */
import React from 'react';
import Layout from '../layouts';
import FaqBody from './FaqBody';

const Faq = () => {
  return (
    <div>
      <Layout>
        <FaqBody />
      </Layout>
    </div>
  );
};

export default Faq;

/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { UseSubscribe } from '../../../hooks/subscribe/useSubscribe';
import useAellaPayment from '../../../hooks/subscribe/useAellaPayment';
import BankPayment from '../channels/BankPayment';

const FirstPaymentRow = ({
  user,
  itemprice,
  monthNum,

  autoDebit,
  account,
}) => {
  const {
    subscribeForPlanWithPaystack,
    subscribeForRecurringPlanWithPaystack,
  } = UseSubscribe();

  const payWithPaystack = () => {
    if (account.device_type === 'Wave Nano') {
      if (monthNum === '1') return subscribeForRecurringPlanWithPaystack(user, monthNum);

      return subscribeForPlanWithPaystack(monthNum, itemprice, user);
    } else {
      if (autoDebit === true && monthNum === '1')
        return subscribeForRecurringPlanWithPaystack(user, monthNum);

      return subscribeForPlanWithPaystack(monthNum, itemprice, user);
    }
  };

  const { createAellaPayment } = useAellaPayment();

  const cin = account.customer_ref;

  const aellaLoan = () => {
    const data = { cin, amount: itemprice };
    createAellaPayment(data);
  };

  return (
    <>
      {account.device_type === 'Wave Nano' ? (
        <div className="text-left mt-5 pt-3 flex  flex-col ">
          <div className="capitalize text-color-cyan-800 text-base ">
            Card and Payment Gateways
          </div>
          <div className="grid  gap-4 grid-cols-2 md:grid-cols-3 mt-3 mb-3">
            <div className="roundedimg  transition duration-500 hover:scale-110">
              <button
                type="button"
                className=""
                onClick={() => payWithPaystack()}
                // style={{ height: '58px' }}
              >
                <img
                  alt="Paystack"
                  className="rounded"
                  src="https://res.cloudinary.com/dcsensx8u/image/upload/v1643294087/Screenshot_2022-01-27_at_3.34.39_PM.png"
                />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-left mt-5 pt-3 flex  flex-col ">
          <div className="capitalize text-color-cyan-800 text-base ">
            Card and Payment Gateways
          </div>
          <div className="grid  gap-4 grid-cols-2 md:grid-cols-3 mt-3 mb-3">
            <div className="roundedimg  transition duration-500 hover:scale-110">
              <button
                type="button"
                className=""
                onClick={() => payWithPaystack()}
                // style={{ height: '58px' }}
              >
                <img
                  alt="Paystack"
                  className="rounded"
                  src="https://res.cloudinary.com/dcsensx8u/image/upload/v1643294087/Screenshot_2022-01-27_at_3.34.39_PM.png"
                />
              </button>
            </div>

          

            <div className="roundedimg rounded  border-1 border-cyan-500 transition duration-500 hover:scale-110">
              <button
                type="button"
                data-toggle="modal"
                data-target="#bank-payment"
              >
                <i className="fas fa-university text-base" /> Bank Transfer
              </button>
            </div>

            <div className="roundedimg rounded border-1 border-cyan-500 transition duration-500 hover:scale-110">
              <button
                onClick={aellaLoan}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Aella Credit"
                  className="rounded"
                  src="https://res.cloudinary.com/dcsensx8u/image/upload/v1643300018/Screenshot_2022-01-27_at_5.13.22_PM.png"
                />
              </button>
            </div>

            <div className="roundedimg rounded border-1 border-cyan-500 transition duration-500 hover:scale-110">
              <Link to="/pricing">
                <button>
                  <img
                    alt="Paddy Cover"
                    className="rounded"
                    src="https://res.cloudinary.com/dcsensx8u/image/upload/v1643301014/Screenshot_2022-01-27_at_5.30.03_PM.png"
                  />
                </button>
              </Link>
            </div>
          </div>
          <BankPayment />
        </div>
      )}
    </>
  );
};

export default FirstPaymentRow;

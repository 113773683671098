/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { useParams, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Footer from '../layouts/Footer';
import { useAuth } from '../../hooks/auth/useAuth';

const LoginWithToken = ({ history }) => {
  const { isLoading, token, editUser } = useAuth();
  const { id } = useParams();

  const submitForm = () => {
    return id;
  };

  const isEnabled = submitForm();

  if (token) {
    // eslint-disable-next-line react/prop-types
    history.push('/');
  }

  const displayButton = () => {
    switch (isLoading) {
      case true:
        return (
          <button className="btn btn-grey w-50" disabled={isLoading}>
            Submitting...
          </button>
        );
      case false:
        return (
          <button className="btn btn-grey w-50" disabled={!isEnabled}>
            Proceed
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <span>
      <div className="container">
        <div className="row mt-5">
          <div className="col-12 mt-5 text-center col-md-5 mx-auto">
            <div className="ghana-logo">
              <img src="images/wificom_logo.png" alt="" />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                editUser(id, history);
              }}
            >
              <div className="text-center mt-2 welcome-back">
                <p className="mb-4" style={{ fontSize: '25px' }}>
                  Verify
                </p>
              </div>
              <div className="form-horizontal col-md-12">
                <div className="form-group">{displayButton()}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </span>
  );
};

LoginWithToken.propTypes = {
  history: PropTypes.shape(),
};
export default withRouter(LoginWithToken);

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/button-has-type */
// eslint-disable-next-line jsx-a11y/label-has-associated-control
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import Footer from '../layouts/Footer';
import { useAuth } from '../../hooks/auth/useAuth';

// eslint-disable-next-line react/prop-types
const ResetPassword = ({ match, history }) => {
  // eslint-disable-next-line react/prop-types
  const { token } = match.params;

  const { onResetChange, resetPassword, reset, isLoading } = useAuth();

  const changePassword = (data) => {
    if (data.password !== data.confirm) {
      toast.info('Passwords do not match!');
    } else {
      resetPassword(data, token, history);
    }
  };

  const showSubmitButton = () => {
    if (isLoading) {
      return (
        <button className="btn btn-grey w-50" disabled={isLoading}>
          Resetting...
        </button>
      );
    }

    return (
      <button
        className="btn btn-grey w-50"
        // onClick={() => changePassword(reset)}
      >
        RESET
      </button>
    );
  };

  const { password, confirm } = reset;

  return (
    <span>
      <div className="container">
        <div className="row mt-5">
          <div className="col-12 mt-5 text-center col-md-5 mx-auto">
            <div className="ghana-logo">
              <img src="images/wificom_logo.png" alt="Logo" />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                changePassword(reset);
              }}
            >
              <div className="text-center mt-2 welcome-back">
                <p className="mb-4" style={{ fontSize: '25px' }}>
                  Reset Password
                </p>
              </div>
              <div className="form-horizontal col-md-12">
                <div className="form-group text-left">
                  <label htmlFor="password" className="welcome-back">
                    New Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={password}
                    onChange={onResetChange}
                    className="form-control"
                    placeholder="New Password"
                  />
                </div>
                <div className="form-group text-left">
                  <label htmlFor="confirm" className="welcome-back">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirm"
                    id="confirm"
                    value={confirm}
                    onChange={onResetChange}
                    className="form-control"
                    placeholder="Confirm Password"
                  />
                </div>
                <div className="form-group">{showSubmitButton()}</div>
              </div>
            </form>
            <div className="forget-pwd">
              <p className="welcome-back">
                Ready to
                <span>
                  <Link to="/login"> Login</Link>?
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </span>
  );
};

ResetPassword.proptype = {
  match: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

export default ResetPassword;

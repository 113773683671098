import React from 'react';
import BankPayment from '../channels/BankPayment';

const Plans = () => {
  return (
    <div className="text-center mt-3">
      <span className="sub_text font_22">Payment Channel:</span>

      <div className="d-flex justify-content-center mb-3">
        <button
          type="button"
          className=""
          data-toggle="modal"
          data-target="#bank-payment"
          style={{
            backgroundColor: '#1B3E42',
            borderWidth: '2px',
          }}
        >
          <img
            alt="BankTransfer"
            src="../images/bank_transfer.png"
            width="70%"
          />
        </button>
      </div>

      <BankPayment />
    </div>
  );
};

export default Plans;

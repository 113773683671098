/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import React from 'react';

const BasicPlanHeader = () => {
  return (
      <div className='h-20 border-b-2 border-gray-300 text-center flex    items-center '>
          <h4 className=''> PaddyCover Insurance Plans &#8594;  BasicPlan </h4>
      </div>
  );
};

export default BasicPlanHeader;

/* eslint-disable import/named */
import { useState, useReducer } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
// import * as types from '../../constants/types/referral/referralTypes';
import * as types from '../../constants/types/auth/AuthTypes';
import { CREATE_REFERRAL_URL } from '../../services/api/referral/ReferralUrl';
import { ReferralReducer } from '../../reducers/referral/ReferralReducer';

// eslint-disable-next-line import/prefer-default-export
export const useReferral = () => {
  const changeState = {
    name: '',
    phoneNumber: '',
    customer_name: '',
    email: '',
    cin: '',
    address: '',
    country: 'ng',
  };

  const [change, setChange] = useState(changeState);

  const [
    {
      isLoading,
      isSuccess,
      isSuccessMessage,
      isFail,
      isFailMessage,
      token,
      isError,
      isErrorMessage,
    },
    dispatch,
  ] = useReducer(ReferralReducer, {
    isLoading: false,
    isSuccess: false,
    isSuccessMessage: '',
    isFail: false,
    isFailMessage: '',
    token: false,
  });

  const onChange = (e) => {
    const { name, value } = e.target;
    setChange({
      ...change,
      [name]: value,
    });
  };

  const createReferral = async (data) => {
    dispatch({
      type: types.START_CREATING_REFERRAL,
    });

    try {
      const response = await axios.post(CREATE_REFERRAL_URL, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.status === 200) {
        if (response.data.message === 'success') {
          toast.success('Referral created successfully');
          setChange(changeState);
          dispatch({
            type: types.STOP_CREATING_REFERRAL,
          });
        } else {
          toast.error('oops');
        }
      }
    } catch (e) {
      dispatch({
        type: types.STOP_CREATING_REFERRAL,
      });
    }
  };

  return {
    onChange,
    createReferral,
    change,
    isSuccess,
    isSuccessMessage,
    isFail,
    isFailMessage,
    isLoading,
    token,
    isError,
    isErrorMessage,
  };
};

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PricingColumn from './PricingColumn';
import PricingHeader from './PricingHeader';
import { Link } from 'react-router-dom';

const PricingBody = () => {
  return (
    // <div className="row " style={{ backgroundColor: '#F5F7F7' }}>
    <div className='container flex flex-col space-y-3'>
      <PricingHeader />
      <p className='font-sans text-sm'> Insure your valuables and items with  <a href="https://www.paddycover.com/" className='no-underline'> PaddyCover  </a> ,
      </p>


        {/*<PricingColumn />*/}


        <div class="container mx-auto px-4 sm:px-8 4">
            <div class="py-8">
                <div>
                    <h2 class="text-2xl font-semibold leading-tight">Select PaddyCover Plan</h2>
                </div>
                <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div
                        class="inline-block min-w-full shadow-md rounded-lg overflow-hidden"
                    >
                        <table class="min-w-full leading-normal border">
                            <thead>
                            <tr>
                                <th
                                    class="px-5 py-3 border-2 border-gray-200 bg-teal-500 text-left text-xs font-semibold text-white uppercase tracking-wider text-center"
                                >
                                    Coverage
                                </th>
                                <th
                                    class="px-5 py-3 border-2 border-gray-200 bg-orange-500 text-left text-xs font-semibold text-white uppercase tracking-wider text-center"
                                >
                                    Basic Plan
                                </th>
                                <th
                                    class="px-5 py-3 border-2 border-gray-200 bg-gray-500 text-left text-xs font-semibold text-white uppercase tracking-wider text-center"
                                >
                                    Standard Plan
                                </th>
                                <th
                                    class="px-5 py-3 border-2 border-gray-200 bg-indigo-500 text-left text-xs font-semibold text-white uppercase tracking-wider text-center
                                    "
                                >
                                    Premium Plan
                                </th>

                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td class=" border-b-2 border-gray-200 bg-white text-sm text-center">
                                    <div class="flex justify-center">
                                        <div class="ml-3">
                                            <p class="text-gray-900 whitespace-no-wrap">
                                               Fire
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td class=" border-b border-gray-200 bg-white text-sm">
                                    <p class="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>
                                </td>
                                <td class=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>


                            </tr>

                            <tr >
                                <td className=" border-b-2 border-gray-200 bg-white text-sm text-center">
                                    <div className="flex justify-center">
                                        <div className="ml-3">
                                            <p className="text-gray-900 whitespace-no-wrap">
                                               Burglary
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>


                            </tr>

                            <tr >
                                <td className=" border-b-2 border-gray-200 bg-white text-sm text-center">
                                    <div className="flex justify-center">
                                        <div className="ml-3">
                                            <p className="text-gray-900 whitespace-no-wrap">
                                                 One-Off Alternative Rent Benefit
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>


                            </tr>

                            <tr >
                                <td className=" border-b-2 border-gray-200 bg-white text-sm text-center">
                                    <div className="flex justify-center">
                                        <div className="ml-3">
                                            <p className="text-gray-900 whitespace-no-wrap">
                                                Public Liability
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>


                            </tr>



                            <tr >
                                <td className=" border-b-2 border-gray-200 bg-white text-sm text-center">
                                    <div className="flex justify-center">
                                        <div className="ml-3">
                                            <p className="text-gray-900 whitespace-no-wrap">
                                                Death
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>


                            </tr>


                            <tr >
                                <td className=" border-b-2 border-gray-200 bg-white text-sm text-center">
                                    <div className="flex justify-center">
                                        <div className="ml-3">
                                            <p className="text-gray-900 whitespace-no-wrap">
                                                Permanent Disability
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>


                            </tr>

                            <tr >
                                <td className=" border-b-2 border-gray-200 bg-white text-sm text-center">
                                    <div className="flex justify-center">
                                        <div className="ml-3">
                                            <p className="text-gray-900 whitespace-no-wrap">
                                                Medical
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> <i className="fas fa-check-square text-green-500 ml-3 text-lg"></i> </p>

                                </td>


                            </tr>


                            <tr >
                                <td className=" border-b-2 border-gray-200 bg-white text-sm text-center">
                                    <div className="flex justify-center">
                                        <div className="ml-3">
                                            <p className="text-gray-900 whitespace-no-wrap">
                                                Sum Assured Limit
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> 400,000 NGN </p>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> 800,000 NGN </p>

                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center"> 1,600,000 NGN </p>

                                </td>


                            </tr>


                            <tr >
                                <td className=" border-b-2 border-gray-200 bg-white text-sm text-center">
                                    <div className="flex justify-center">
                                        <div className="ml-3">
                                            <p className="text-gray-900 whitespace-no-wrap">

                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center">
                                        <Link to="/pricing/basic-plan">
                                            <button className='rounded bg-green-500 px-3 py-2 text-white  hover:bg-gray-500  hover:text-white  transition-all duration-150  ease-in-out'> GET STARTED</button>
                                        </Link>
                                    </p>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center">
                                        <Link to="/pricing/standard-plan">
                                            <button className='rounded bg-green-500 px-3 py-2 text-white  hover:bg-gray-500  hover:text-white  transition-all duration-150  ease-in-out'> GET STARTED</button>
                                        </Link>
                                    </p>
                                </td>
                                <td className=" border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap text-center">
                                        <Link to="/pricing/premium-plan">
                                            <button className='rounded bg-green-500 px-3 py-2 text-white  hover:bg-gray-500  hover:text-white  transition-all duration-150  ease-in-out'> GET STARTED</button>
                                        </Link>
                                    </p>
                                </td>


                            </tr>




                            </tbody>
                        </table>

                        <p className="no-underline text-xs mt-4 mb-44">
                            HAVE A QUESTION? Visit PaddyCover
                            <a
                                className="no-underline text-xs"
                                href="https://legacy.tizeti.com/paddycover/paddycover.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            > FAQ </a>
                        </p>

                    </div>
                </div>
            </div>
        </div>





    </div>
  );
};

export default PricingBody;

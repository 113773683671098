/* eslint-disable import/prefer-default-export */
import { useReducer } from 'react';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import { paymentHistoryReducer } from '../../reducers/payment-history/paymentHistoryReducer';
import {
  GENIATECH_GET_PAYMENT_HISTORY_URL,
  GET_USER_PLAN_URL,
} from '../../services/api/accounts/AccountsUrl';
import * as types from '../../constants/types/accounts/AccountsTypes';

export const usePaymentHistory = () => {
  const [state, dispatch] = useReducer(paymentHistoryReducer, {
    isLoading: false,
    paymentsHistory: [],
  });

  const getPaymentsHistory = async (data) => { 
    dispatch({
      type: types.START_FETCHING_PAYMENT_HISTORY,
    });
    try {
      const response = await axios.post(
        `${GENIATECH_GET_PAYMENT_HISTORY_URL}`,
        qs.stringify(data),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (typeof response.data === 'string') {
        dispatch({
          type: types.FETCHED_PAYMENT_HISTORY,
          payload: response.data,
        });
        dispatch({ type: types.STOP_FETCHING_PAYMENT_HISTORY });
      } else if (
        response.data.message === 'user account not found on front accounting'
      ) {
        dispatch({ type: types.STOP_FETCHING_PAYMENT_HISTORY });
        toast.error('Unable to fetch payment(s)');
      } else {
        dispatch({
          type: types.FETCHED_PAYMENT_HISTORY,
          payload: response.data,
        });
      }
    } catch (e) {
      dispatch({ type: types.STOP_FETCHING_PAYMENT_HISTORY });
      toast.error('Unable to fetch payment(s)');
    }
  };

  const filterPaymentsHistory = async (macid, dates) => {
    dispatch({
      type: types.START_FETCHING_PAYMENT_HISTORY,
    });
    try {
      const data = {
        action: 'getDebtorTransPaginated',
        totalRec: 10,
        page_no: 1,
        macid,
        from: dates.from,
        to: dates.to,
      };
      const response = await axios.post(
        `${GET_USER_PLAN_URL}`,
        qs.stringify(data),
      );
      if (response.data.status === false) {
        toast.error('Unable to fetch payment(s)');
        dispatch({ type: types.STOP_FETCHING_PAYMENT_HISTORY });
      } else {
        dispatch({
          type: types.FETCHED_PAYMENT_HISTORY,
          payload: response.data.items,
        });
      }
    } catch (e) {
      dispatch({ type: types.STOP_FETCHING_PAYMENT_HISTORY });
      toast.error('Unable to fetch payment(s)');
    }
  };

  return { state, getPaymentsHistory, filterPaymentsHistory };
};

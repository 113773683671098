import * as types from '../../constants/types/auth/AuthTypes';

// eslint-disable-next-line import/prefer-default-export
export const AuthReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.START_LOGIN_USER:
      return {
        ...state,
        isLoading: true,
      };

    case types.CHECK_IF_TOKEN_EXISTS:
      return {
        ...state,
        token: payload,
      };

    case types.STOP_LOGIN_USER:
      return {
        ...state,
        isLoading: false,
      };

    case types.LOGIN_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isErrorMessage: 'invalid credentials',
      };

    case types.LOGIN_USER:
      return {
        ...state,
      };

    case types.START_FORGET_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };

    case types.FORGET_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isSuccessMessage: payload,
      };

    case types.UNABLE_TO_FORGET_PASSWORD:
      return {
        ...state,
        isLoading: false,
        isFail: true,
        isFailMessage: payload,
      };

    case types.STOP_FORGET_PASSWORD:
      return {
        ...state,
        isLoading: false,
      };

    case types.START_RESET_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };

    case types.RESET_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };

    case types.STOP_RESET_PASSWORD:
      return {
        ...state,
        isLoading: false,
      };

    case types.UNABLE_TO_RESET_PASSWORD:
      return {
        ...state,
        isLoading: false,
      };

    default:
      // return state;
      throw new Error();
  }
};

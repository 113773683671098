/* eslint-disable react/jsx-indent */
import React from 'react';
import Layout from '../layouts';
import OverviewBody from './OverviewBody';

const Overview = () => {
  return (
    <>
      <div className="row" style={{ backgroundColor: '#F5F7F7' }} />

      <Layout>
        <OverviewBody />
      </Layout>
    </>
  );
};

export default Overview;

import { useReducer, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import {
  GET_CUSTOMER_EXPIRY_URL,
  GENIATECH_GET_USER_INFORMATION_URL,
  GET_USER_PLAN_URL,
  GET_ITEM_PRICE_URL,
} from '../../services/api/accounts/AccountsUrl';
import * as types from '../../constants/types/accounts/AccountsTypes';
import { AccountsReducer } from '../../reducers/accounts/AccountsReducer';
import { GET_LOGGED_IN_USER_URL } from '../../services/api/user/UserUrl';
import Helpers from '../../utils/Helpers';
import { USAGE_HISTORY_URL } from '../../services/api/overview/usage-history/UsagehistoryUrl';

// eslint-disable-next-line import/prefer-default-export
export const UseAccounts = () => {
  const [state, dispatch] = useReducer(AccountsReducer, {
    isLoading: false,
    isError: false,
    accounts: [],
    usage: [],
    threeMonthUsage: [],
    itemPrices: [],
    expiry: null,
  });

  const fetchUserAccounts = async () => {
    dispatch({
      type: types.FETCH_STARTS,
      payload: true,
    });

    if (sessionStorage.getItem('email')) {
      const data = {
        email: `${sessionStorage.getItem('email')}`,
      };
      try {
        const response = await axios.post(
          `${GENIATECH_GET_USER_INFORMATION_URL}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        );
        dispatch({
          type: types.FETCHED_ACCOUNTS,
          payload: response.data,
        });
      } catch (e) {
        dispatch({
          type: types.FETCH_FAILURE,
          payload: false,
        });
      }
    } else {
      try {
        const response = await axios.get(`${GET_LOGGED_IN_USER_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        try {
          const newData = {
            email: response.data.email,
          };
          const res = await axios.post(
            `${GENIATECH_GET_USER_INFORMATION_URL}`,

            newData,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`,
              },
            },
          );

          dispatch({
            type: types.FETCHED_ACCOUNTS,
            payload: res.data,
          });
        } catch (e) {
          dispatch({
            type: types.FETCH_FAILURE,
            payload: false,
          });
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  };

  // function checkValidMacIdInArray(param) {
  // console.log('checkValidMacIdInArray param', param);
  // console.log('checkValidMacIdInArray macaddress single item', param[0]);
  // console.log('checkValidMacIdInArray macaddress ', param[0].username);

  // const regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
  // const status =
  //   regex.test(param) === true || (param.length == 15 && param[0] == '2');
  // if (status) {
  //   return param;
  // }

  // const regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
  // return param
  //   .map((arr) => arr.username)
  //   .filter((pp) => regex.test(pp) === true);

  // let status = false;
  // const regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
  // const username = param.map((item) => item.username);
  // username.filter((pp) => {
  //   status = regex.test(pp) === true || pp.length === 15;
  //   return status;
  // });

  // if (status) {
  //   return param[0][username];
  // }
  // }

  const fetchPlanFromFrontAccounting = async () => {
    dispatch({
      type: types.FETCH_USER_PLAN_STARTS,
    });
    const email = {
      email: `${sessionStorage.getItem('email')}`,
    };
    try {
      const response = await axios.post(
        `${GENIATECH_GET_USER_INFORMATION_URL}`,
        email,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );

      const accounts = response.data;

      const itemPrices = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const account of accounts) {
        const checkmac = account.username;

        // console.log(account.username);

        // console.log('checkmac', checkmac);

        const getPlan = qs.stringify({
          TB_PREF: '0_',
          macid: checkmac,
        });
        // eslint-disable-next-line no-await-in-loop
        const planresponse = await axios.post(
          `${GET_ITEM_PRICE_URL}`,
          getPlan,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        );
        // console.log('i got here');

        if (planresponse.data) {
          itemPrices.push(planresponse.data.itemprice);
          // console.log('debug_res', planresponse.data);
          // console.log('debug_request', getPlan);
        }
      }

      console.log({ itemPrices });

      dispatch({
        type: types.FETCH_USER_PLAN,
        payload: itemPrices,
      });
    } catch (e) {
      dispatch({
        type: types.FETCH_USER_PLAN_STOPS,
      });
    }
  };

  const getUsageHistory = async () => {
    if (sessionStorage.getItem('email')) {
      dispatch({
        type: types.START_FETCHING_USAGE,
      });
      const data = {
        email: `${sessionStorage.getItem('email')}`,
      };
      try {
        const response = await axios.post(
          `${GENIATECH_GET_USER_INFORMATION_URL}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        );

        const accounts = response.data;

        // {
        //   response.data.map((used, i) => {
        //     setUse(used.username);
        //     console.log(used.username);
        //   });
        // }

        for (const account of accounts) {
          // const checkmac = checkValidMacIdInArray(account.username);
          const checkmac = account.username;

          // console.log(account.username);

          const userData = {
            startdate: Helpers.getSevenDaysAgoDate(),
            enddate: Helpers.getTodaysDate(),
            mac: checkmac,
          };
          try {
            const res = await axios.post(
              `${USAGE_HISTORY_URL}`,
              qs.stringify(userData),
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
              },
            );
            if (res.data.status === true) {
              dispatch({
                type: types.FETCHED_USAGE,
                payload: res.data.items,
              });
            }
          } catch (e) {
            dispatch({
              type: types.STOP_FETCHING_USAGE,
            });
          }
        }
      } catch (e) {
        dispatch({
          type: types.STOP_FETCHING_USAGE,
        });
      }
    } else {
      dispatch({
        type: types.START_FETCHING_USAGE,
      });
      try {
        const get = await axios.get(`${GET_LOGGED_IN_USER_URL}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (get.data) {
          const data = {
            email: get.data.email,
          };
          try {
            const response = await axios.post(
              `${GENIATECH_GET_USER_INFORMATION_URL}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
              },
            );
            const userData = {
              startdate: Helpers.getSevenDaysAgoDate(),
              enddate: Helpers.getTodaysDate(),
              mac: response.data[0].username,
            };

            try {
              const res = await axios.post(
                `${USAGE_HISTORY_URL}`,
                qs.stringify(userData),
                {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                  },
                },
              );
              if (res.data.status === true) {
                dispatch({
                  type: types.FETCHED_USAGE,
                  payload: res.data.items,
                });
              }
            } catch (e) {
              dispatch({
                type: types.STOP_FETCHING_USAGE,
              });
            }
          } catch (e) {
            dispatch({
              type: types.STOP_FETCHING_USAGE,
            });
          }
        } else {
          dispatch({
            type: types.STOP_FETCHING_USAGE,
          });
        }
      } catch (e) {
        dispatch({
          type: types.STOP_FETCHING_USAGE,
        });
      }
    }
  };

  const getExpiration = async () => {
    if (`${sessionStorage.getItem('email')}`) {
      const data = {
        email: `${sessionStorage.getItem('email')}`,
      };
      try {
        const response = await axios.post(
          `${GENIATECH_GET_USER_INFORMATION_URL}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            },
          },
        );
        if (response.data) {
          // eslint-disable-next-line array-callback-return
          response.data.map((respo) => {
            const mac = qs.stringify({
              mac_id: respo.username,
            });

            axios
              .post(`${GET_CUSTOMER_EXPIRY_URL}`, mac, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
              })
              .then((resp) => {
                if (resp.data.debtor_no) {
                  // const last = resp.data[resp.data.length - 1];
                  dispatch({
                    type: types.GET_EXPIRY_DATE,
                    // payload: last.Tran_date
                    payload: resp.data.debtor_no,
                  });
                }
              })
              .catch((err) => {
                if (err) {
                  toast.info(`Unable to get user's expiry date`);
                }
              });
          });
        } else {
          toast.info('unable to get user information');
        }
      } catch (e) {
        toast.info('Something went wrong');
      }
    }
  };

  const getThreeMonthUsageHistory = async (mac) => {
    dispatch({
      type: types.START_FETCHING_USAGE,
    });
    const data = {
      startdate: Helpers.getThreeMonthsAgoDate(),
      enddate: Helpers.getTodaysDate(),
      mac,
    };
    try {
      const res = await axios.post(`${USAGE_HISTORY_URL}`, qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (res.data.items) {
        dispatch({
          type: types.FETCHED_THREE_MONTH_USAGE,
          payload: res.data.items,
        });
      }
    } catch (e) {
      dispatch({ type: types.STOP_FETCHING_USAGE });
      toast.info('Error fetching usage history');
    }
  };

  useEffect(() => {
    fetchUserAccounts();
    fetchPlanFromFrontAccounting();
    getUsageHistory();
    getExpiration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, getThreeMonthUsageHistory];
};

import React from 'react';

const BankPayment = () => {
  return (
    <div
      id="bank-payment"
      className="modal fade in rubik mt-5"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog mt-5">
        <div className="modal-content payment-modal">
          <div className="modal-header">
            <h4
              className="modal-title"
              style={{ color: 'rgb(8,166,179)', marginLeft: '150px' }}
              id="myModalLabel"
            >
              Pay with Bank
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body font-weight-light">
            <h6>
              You can make payment into any of our Bank accounts listed below
            </h6>

            <p className="lighter_font">
              To confirm your payment please send scanned copy of teller or
              screenshot of success message to
              <b>
                <a
                  href="mailto:internet@wifi.com.ng"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  {' '}
                  internet@wifi.com.ng
                </a>
              </b>
              . Your account will be credited within 30 minutes.
            </p>
            <table className="table lighter_font">
              <tr>
                <th scope="col">Bank Name</th>
                <th scope="col" className="px-0">
                  Account Number
                </th>
                <th scope="col">Account Name</th>
              </tr>
              <tr>
                <td>Zenith Bank</td>
                <td>1013184381</td>
                <td>Tizeti Networks Limited</td>
              </tr>
              {/* <tr>
                <td>Fidelity Bank</td>
                <td>1010021194</td>
                <td>Tizeti Networks Limited</td>
              </tr>
              <tr>
                <td>Access Bank PLC</td>
                <td>1038891129</td>
                <td>Tizeti Networks Limited</td>
              </tr> */}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankPayment;

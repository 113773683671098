import React from 'react';
import Plans from '../plans/Plans';
import Footer from '../../layouts/Footer';
import Account from './Account';
import UnauthenticatedNav from '../../layouts/UnauthenticatedNav';

const SubscribeWithMac = () => {
  return (
    <div className="container-fluid p-0">
      <UnauthenticatedNav message="You Are Seeing This Because Your Account Has Expired. Please Make Payment." />
      <hr className="mt-0 mb-3 d-flex d-md-none" />
      <div className="container" id="page-container">
        <Account />
        <Plans />
      </div>
      <Footer />
    </div>
  );
};

export default SubscribeWithMac;

/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react';
// import Footer from './Footer';
// import SideBar from './sidebar/SideBar';
import Footer from './Footer';
import MobileSidebar from './sidebar/MobileSidebar';
import SidebarNew from './sidebar/SidebarNew';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-row w-full flex-wrap sm:flex-col md:flex-row">
      <div
        className="w-full max-w-full h-[50px] sm-max-w-sm  md:w-1/4 md:h-screen "
        style={{ backgroundColor: '#1E8E8B' }}
      >
        {/* <SideBar /> */}
        <SidebarNew />
        <MobileSidebar />
      </div>

      <div className="w-full  md:w-3/4  flex flex-col md:h-screen">
        <div className="overflow-y-auto  h-4/5 grow">{children}</div>

        <Footer />
      </div>
    </div>
  );
};

export default Layout;

// <div className="container-fluid" style={{ backgroundColor: '#F5F7F7' }}>
//   <div className="row ">
//     <div
//       className="col-sm-3 col-lg-2 h-full class text-white "
//       style={{ backgroundColor: '#1E8E8B' }}
//     >
//       <SideBar />
//     </div>
//     <div className="col-sm-9 col-lg-10 pt-4 h-full bg-white text-black">
//       {/* {children} */}
//       <Footer />
//     </div>
//   </div>
// </div>
//   );
// };
//
// export default Layout;

import React from 'react';
import BigScreenLogout from '../../auth/BigScreenLogout';
import { Link } from 'react-router-dom';

export const NavRoutes = [
  {
    id: 1,
    label: 'Overview',
    path: '/',
    icon: 'fa fa-home',
  },
  {
    id: 2,
    label: 'Subscribe',
    path: '/subscribe',
    icon: 'fa fa-shopping-cart',
  },
  {
    id: 3,
    label: 'Profile',
    path: '/profile',
    icon: 'fa fa-id-badge',
  },
  {
    id: 4,
    label: 'Refer a Friend',
    path: '/referral',
    icon: 'fa fa-users',
  },
  {
    id: 5,
    label: 'Store',
    path: '/store',
    icon: 'fa fa-store',
  },
  {
    id: 6,
    label: 'FAQ',
    path: '/faq',
    icon: 'fa fa-question-circle',
  },
  {
    id: 7,
    label: 'KYC',
    path: '/kyc',
    icon: 'fa fa-database',
  },
];

const SidebarNew = () => {
  return (
    <div className="h-full">
      <ul className=" md:flex hidden space-y-5 md:flex-col md:justify-center md:h-full md:space-y-3 md:w-full md:justify-self-center">
        <li className="hidden md:block  md:mx-auto">
          <Link className="navbar-brand-logo mt-3 mx-auto mb-5 " to="/">
            <img
              src="images/wificom_logo.png"
              alt="GhanaWifi Logo"
              className="sm:p-0"
              style={{
                backgroundColor: 'white',
                padding: '5px',
                borderRadius: '5px',
              }}
            />
          </Link>
        </li>
        {NavRoutes.map((item) => (
          <li
            key={item.id}
            className="h-7  hover:border-b-4 text-center text-center cursor-pointer  transition-all duration-150  ease-in-out  w-32 md:mx-auto"
          >
            <Link
              className="flex items-center justify-start  text-sm text-decoration-none  text-white "
              to={item.path}
              target="_self"
            >
              <i className={item.icon + ' mr-1'} /> {item.label}
            </Link>
          </li>
        ))}
        <li className="h-7  hover:border-b-4 text-center text-center cursor-pointer  transition-all duration-150  ease-in-out  w-32 md:mx-auto">
          <BigScreenLogout />
        </li>
      </ul>
    </div>
  );
};

export default SidebarNew;

/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { withRouter, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Footer from '../layouts/Footer';
import { useAuth } from '../../hooks/auth/useAuth';

const LoginWithEmail = ({ history }) => {
  const { email } = useParams();
  const { isLoading, token, onLoginChange, loginUserWithEmail } = useAuth();

  const submitForm = () => {
    return email.length > 0;
  };

  const isEnabled = submitForm();

  if (token) {
    history.push('/');
  }

  const displayButton = () => {
    switch (isLoading) {
      case true:
        return (
          <button
            style={{ marginTop: '10rem' }}
            className="btn btn-grey w-50"
            disabled={isLoading}
          >
            Submitting...
          </button>
        );
      case false:
        return (
          <button
            style={{ marginTop: '10rem' }}
            className="btn btn-grey w-50"
            disabled={!isEnabled}
          >
            Proceed
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <span>
      <div className="container">
        <div className="row mt-5">
          <div className="col-12 mt-5 text-center col-md-5 mx-auto">
            <div className="ghana-logo">
              <img src="images/wificom_logo.png" alt="" />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                loginUserWithEmail(email, history);
              }}
            >
              <div className="form-horizontal col-md-12">
                <div
                  className="form-group text-left"
                  style={{ display: 'none' }}
                >
                  <label htmlFor="email" className="welcome-back">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={onLoginChange}
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Email Address"
                    readOnly
                  />
                </div>
                <div className="form-group">{displayButton()}</div>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </span>
  );
};

LoginWithEmail.propTypes = {
  history: PropTypes.shape(),
};
export default withRouter(LoginWithEmail);

/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { UseAccounts } from '../../../hooks/accounts/useAccounts';
import { useUser } from '../../../hooks/user/useUser';
import Layout from '../../layouts';
import PremiumPlanBody from './PremiumPlanBody';
import PremiumPlanHeader from './PremiumPlanHeader';

const PremiumPlan = () => {
  const transaction_limit = 1600000;
  const [monthNumber, setMonthNumber] = useState('1');
  const premiumprice = 1500;
  const premiumpriceTotal = monthNumber * 1500;
  const [monthNum, setMonthNum] = useState('1');
  const months = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const month = [24, 36, 48];
  const [{ itemPrices, accounts }] = UseAccounts();
  const [
    {
      user: { firstName, lastName },
    },
  ] = useUser();
  return (
    <div>
      <Layout>
        <div className="container">
          <PremiumPlanHeader /> 
          <div className="flex  flex-col  mt-3 p-3  rounded-2xl  w-full border-1 border-gray-200">
            <h3 className="text-center w-full text-base">
              {' '}
              Paddy Cover Premium Plan
            </h3>
            <PremiumPlanBody
              transaction_limit={transaction_limit}
              premiumprice={premiumprice}
              premiumpriceTotal={premiumpriceTotal}
              monthNumber={monthNumber}
              setMonthNumber={setMonthNumber}
              monthNum={monthNum}
              setMonthNum={setMonthNum}
              months={months}
              itemPrices={itemPrices}
              accounts={accounts}
              firstName={firstName}
              month={month}
              lastName={lastName}
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default PremiumPlan;

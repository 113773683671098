/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../BaseUrl';
import { GET_LOGGED_IN_USER_URL } from '../user/UserUrl';

export const CREATE_REFERRAL_URL = `${API_URL}/referral/createReferral`;

export const getLoggedInUser = async () => {
  try {
    const response = await axios.get(GET_LOGGED_IN_USER_URL, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
    });

    if (response.status === 200) {
      return {
        ...response?.data,
      };
    }
  } catch (e) {
    throw new Error(e);
  }
};

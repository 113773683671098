/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import Nav from '../layouts/Nav';
import Footer from '../layouts/Footer';
import { UseAccounts } from '../../hooks/accounts/useAccounts';

const UsageHistory = ({ location: { appState = {} } }) => {
  const [
    { threeMonthUsage, isLoading },
    getThreeMonthUsageHistory,
  ] = UseAccounts();

  useEffect(() => {
    getThreeMonthUsageHistory(appState.macid);
  }, []);

  const usageHistoryBody =
    appState.macid === undefined ? (
      <tr>
        <td colSpan="3" className="text-center">
          Kindly Visit The Overview Page First
        </td>
      </tr>
    ) : threeMonthUsage && threeMonthUsage.length ? (
      threeMonthUsage.map((used, i) => (
        <tr key={i + 1}>
          <td>{moment(used.from).format('DD-MMMM')}</td>
          <td>
            <i className="fas fa-arrow-down arrow" />
            {` ${used.uploaded.slice(0, -2) >= 0 ? used.uploaded : `0 `}b`}
          </td>
          <td>
            <i className="fas fa-arrow-up arrow" />
            {` ${used.downloaded.slice(0, -2) >= 0 ? used.downloaded : `0 `}b`}
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="3" className="text-center">
          No Usage History Within The Last 3 Months
        </td>
      </tr>
    );

  return (
    <div>
      <Nav />
      <hr className="mt-0 mb-3 d-flex d-md-none" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="table-responsive rubik scroll text-center theadFixed">
              <table className="table text-nowrap">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Download</th>
                    <th scope="col">Upload</th>
                  </tr>
                </thead>
                <tbody style={{ fontWeight: 'normal' }}>
                  {isLoading ? (
                    <tr>
                      <td colSpan="3" className="text-center">
                        <i
                          className="fa fa-spinner fa-spin"
                          style={{ fontSize: '120px' }}
                        />
                      </td>
                    </tr>
                  ) : (
                    usageHistoryBody
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
};

UsageHistory.propTypes = {
  location: PropTypes.objectOf.isRequired,
};

export default UsageHistory;

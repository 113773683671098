/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import React from 'react';

const FaqHeader = () => {
  return (
    // <div
    //   className=" container font_small font-weight-light"
    //   // style={{ backgroundColor: '#F5F7F7' }}
    // >
    //   <div className="mt-1">
    //     <p>
    //       Home > FAQ <br />
    //     </p>
    //   </div>
    //   <div className="font_big mt-2">
    //     <h6>Curiosity brought you here and we've got answers</h6>
    //     <hr />
    //   </div>
    // </div>

      <div className="container h-20 border-b-2 border-gray-300 text-center flex    items-center  ">
        <h4 className="">
          FAQ
        </h4>
      </div>
  );
};

export default FaqHeader;

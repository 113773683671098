/* eslint-disable import/prefer-default-export */
import { useReducer, useEffect } from 'react';
import axios from 'axios';
import { userReducer } from '../../reducers/user/UserReducer';
import { GET_LOGGED_IN_USER_URL } from '../../services/api/user/UserUrl';
import * as types from '../../constants/types/user/UserTypes';
import { EDIT_PROFILE_URL } from '../../services/api/profile/ProfileUrl';

export const useUser = () => {
  const [state, dispatch] = useReducer(userReducer, {
    isLoading: false,
    isError: false,
    isSuccess: false,
    userPlans: [],
    user: [],
  });

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${GET_LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        dispatch({
          type: types.FETCHED_USER,
          payload: response.data,
        });
      }
    } catch (e) {
      dispatch({ type: types.STOP_FETCHING_USER });
    }
  };

  const fetchUserPlans = async () => {
    dispatch({
      type: types.FETCH_USER_PLANS_STARTS,
    });

    try {
      const response = await axios.get(`${GET_LOGGED_IN_USER_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        dispatch({
          type: types.FETCHED_USER_PLANS,
          payload: response.data.accounts,
        });
      } else {
        dispatch({
          type: types.FETCH_USER_PLANS_FAILURE,
        });
      }
    } catch (e) {
      dispatch({
        type: types.FETCH_USER_PLANS_FAILURE,
      });
    }
  };

  const editProfile = async (profile, id) => {
    dispatch({
      type: types.START_EDITING_PROFILE,
      // payload: true,
    });
    try {
      const response = await axios.patch(`${EDIT_PROFILE_URL}/${id}`, profile, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data.editStatus === 'success') {
        dispatch({
          type: types.FETCHED_USER,
          payload: response.data
        });
      } else {
        dispatch({
          type: types.FAILURE_EDITING_PROFILE,
          payload: false,
        });
      }
    } catch (e) {
      dispatch({
        type: types.FAILURE_EDITING_PROFILE,
        payload: false,
      });
    }
  };

  useEffect(() => {
    fetchUser();
    fetchUserPlans();
  }, []);

  return [state, editProfile];
};

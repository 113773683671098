import React from 'react';
import { UseAccounts } from '../../../hooks/accounts/useAccounts';
import { useUser } from '../../../hooks/user/useUser';

const UsageHistoryBody = () => {
  const [{ isLoading }] = useUser();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const getDateAndMonth = (item) => {
    const date = new Date(item.from).getDate();
    const month = monthNames[new Date(item.from).getMonth()];
    return <td>{`${date}-${month}`}</td>;
  };

  const [{ usage }] = UseAccounts();
  return (
    <div
      className="border bg-gray-100 rounded-2xl mt-3 p-3 hover:border-black overflow-x-auto"
      // style={{ backgroundColor: '#F5F7F7' }}
      id=""
    >
      <hr className="mt-0 mb-3 d-flex d-md-none" />
      <div className="container-fluid" id="page-container">
        {/* <div className="ml-xl-5 row d-flex align-items-center"> */}
        <div className="row d-flex align-items-right">
          <div
            className="border-0 ml-3 mt-3 mb-0 mr-3"
            style={{ width: '30rem', backgroundColor: '#F5F7F7' }}
          >
            <h6
              className="font-weight-normal mt-3"
              style={{ fontSize: '14px' }}
            >
              Usage History
            </h6>
            {/* <hr className="mt-3 " /> */}
            <div className="table-responsive w-auto mb-5">
              <table className="table">
                <thead>
                  <tr
                    className="font-weight-normal mt-3"
                    style={{ fontSize: '13px', color: '#1E8E8B' }}
                  >
                    <th scope="col">Date</th>
                    <th scope="col">Download</th>
                    <th scope="col">Upload</th>
                  </tr>
                </thead>
                <tbody className="lighter_font">
                  {isLoading ? (
                    <div>
                      <img
                        alt="loading..."
                        src="images/TizetiLoaderColored.gif"
                      />
                    </div>
                  ) : (
                    usage.map((item, idx) => (
                      <tr
                        className="text-nowrap"
                        style={{ fontSize: '14px' }}
                        key={idx}
                      >
                        {getDateAndMonth(item)}
                        <td>
                          <i className="fas fa-arrow-down red" />
                          {` ${item.uploaded ? item.uploaded : `0 `}b`}
                        </td>
                        <td>
                          <i className="fas fa-arrow-up arrow" />
                          {` ${item.downloaded ? item.downloaded : `0 `}b`}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageHistoryBody;

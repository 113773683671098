import React from 'react';
import qs from 'qs';
import useFeedback from './api/useFeedback';
import { useState } from 'react';
import { useParams} from 'react-router-dom';
import './App.css';

const FeedbackSystem = () => {
  const { postFeedback, isLoading } = useFeedback();
  const [experiences, setExperiences] = useState('');
  const [rating, setRating] = useState(1);
  let searchParams = useParams();

  const { installationId } = searchParams;

  if (!installationId) {
    return <p>Page not found</p>;
  }
  
  // const installationId = '0e4a182f-7ba0-4943-9a4d-207495dfcce2';


  const onSubmit = async (e) => {
    e.preventDefault();
    const data = qs.stringify({
      experience: experiences,
      rating: rating,
      installation_id: installationId,
    });

    try {
      await postFeedback(data);
      setRating(1);
      setExperiences('');
    } catch (error) {
      console.log(error);
    }
  };

  return (
  <div class="container-sm justify content center pb-5">
   <p className="p-5" style={{color: '#08A6B3', fontSize: '24px'}}>Rate your experience</p>
   <div class="card">
  <div class="card-body p-4 floatLeft" style={{backgroundColor: '#fbfdff', fontSize: '13px'}}>
      
  <div className="rate2">
              <p className="rate3">
                {' '}
                Feedbacks are important to us, so please rate your experience
                and the service.{' '}
              </p>
            </div>

            <div className="rate2">
              <div className="pic">
                <img className="ah" src="images/a.png" alt="image1" />
              </div>
            </div>

  </div>
 

</div>

<form
              className="form-horizontal mt-2 col-12 col-md-12 p-5 rounded-2xl mb-10 "
              onSubmit={onSubmit}
            >
<div className="text-center p-5">
<p>  1. How would you rate the engineer on site?</p>
</div>

<div class="d-flex justify-content-center">
            <div className="col-sm"><img style={{maxHeight:"50px"}}  onClick={(e) => setRating(1)}   src="images/bad.png" />
            <p className="how2" onClick={(e) => setRating(1)}>Terrible</p></div>                       
            <div className="col-sm"><img style={{maxHeight:"50px"}}  onClick={(e) => setRating(2)}   src="images/bad.png" />
            <p className="how2" onClick={(e) => setRating(2)}>Bad</p></div>
            <div className="col-sm"><img style={{maxHeight:"50px"}} onClick={(e) => setRating(3)}    src="images/bad.png" />
            <p className="how2" onClick={(e) => setRating(3)}>Average</p></div>                       
            <div className="col-sm"><img style={{maxHeight:"50px"}} onClick={(e) => setRating(4)}    src="images/bad.png" />
            <p className="how2" onClick={(e) => setRating(4)}>Very good</p></div>
            <div className="col-sm"><img style={{maxHeight:"50px"}}  onClick={(e) => setRating(5)}   src="images/bad.png" />
            <p className="how2" onClick={(e) => setRating(5)}>Excellent</p></div>                       
          
            </div>
            

            <div className="text-center p-5">
       <p>  2. Kindly share your experience concerning the installation process.</p>
          </div>
          <div class="d-flex justify-content-center pb-5">
          <textarea
                  type="text"
                  name="experience"
                  onChange={(e) => setExperiences(e.target.value)}
                  value={experiences}
                  className="form-control"
                  style={{backgroundColor:"#F1F1F1",   width: '70%',
                  paddingTop:'1rem',
                  height: '170px',
                   border: 'none',
                   borderRadius: '10px'}}  
                >
                  {' '}
                  Please share your experience.
                </textarea>
              </div>
             
              <div className="text-center">
              <a href="#">
                    {' '}
                    <button className="submit" type="submit"  style={{ backgroundColor:'#08A6B3',
     color: 'white', 
     width: '170px',
      padding:'1rem',
      height: '50px',
       border: 'none', 
       borderRadius: '10px'}}>
                      {' '}
                      Submit
                    </button>{' '}
                  </a>
              </div>
              </form>
</div>

);
}

export default FeedbackSystem;

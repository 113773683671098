import { GET_NOMINATION, START_NOMINATION, STOP_NOMINATION } from "../../constants/types/nomination/nominationTypes";


const initialState = {
  item: [],
  list: [],
  isLoading: false,
};

const nomFemaleReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case START_NOMINATION:
      return {
        ...state,
        isLoading: true,
      };

      case GET_NOMINATION:
        return {
          ...state,
          item: payload.item,
          list: payload.list,
          isLoading: false,
        };
      
    case STOP_NOMINATION:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default nomFemaleReducers;

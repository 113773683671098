import React, { useEffect } from 'react';
import qs from 'qs'; 
import './App.css';
import useMaleStaff from '../../hooks/nomination/useMaleStaff';
import useFemaleStaff from '../../hooks/nomination/useFemaleStaff';
import { TizetiGIFLogoTable } from '../../icon/loadingHelper';

const NominationResult = () => {

    const { list, getMale, isLoading } = useMaleStaff();
    const { item, getFemale } = useFemaleStaff();

  
    useEffect(() => {
        getMale( qs.stringify({ action: 'get_nomination_result', category: 'best_dressed_male'  }));
        getFemale( qs.stringify({ action: 'get_nomination_result', category: 'best_dressed_female'   }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
  

  return (
    <body>
    <nav class="navbar fixed-top navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#"><img src="images/tizeti-logo.png" alt="" class="img-fluid"/></a>
        {/* style={{ backgroundImage:  `url("images/cart.jpeg")` }} */}
      </div>
    </nav>  
    <section class="cat" id="category" >
      <div class="container">
        <div class="row justify-content-center">
   
          <section class="wow fadeIn animated" style={{visibility: 'visible', animationName: 'fadeIn'}}>
            <div class="container">
                <div class="row">
                <h5 class="pt-5 pb-5">Voting Results for Best Dressed Male</h5>
                {isLoading ? (
              <TizetiGIFLogoTable/>
            ) : (
              list.map((lis) => (
                    <div class="col-md-3 col-sm-6 bottom-margin text-center counter-section wow fadeInUp sm-margin-bottom-ten animated" data-wow-duration="300ms"  style={{visibility: 'visible', animationDuration: '1200ms', animationName: 'fadeInUp'}}>
                        {/* <i class="fa fa-beer medium-icon"></i> */}
                        <span id="anim-number-pizza" class="counter-number"></span>
                        <span class="timer counter alt-font appear" data-to="980" data-speed="7000">{lis.frequency}</span>
                        <p class="counter-title pt-4">{lis.name}</p>
                    </div>
            ))
            )}
                   
                </div>
            </div>
        </section>

        
        <section class="wow fadeIn animated" style={{visibility: 'visible', animationName: 'fadeIn'}}>
            <div class="container">
                <div class="row">
                <h5 class="pt-5 pb-5">Voting Results for Best Dressed Female</h5>
                {isLoading ? (
              <TizetiGIFLogoTable/>
            ) : (
              item.map((ite) => (
                    <div class="col-md-3 col-sm-6 bottom-margin text-center counter-section wow fadeInUp sm-margin-bottom-ten animated" data-wow-duration="300ms"  style={{visibility: 'visible', animationDuration: '1200ms', animationName: 'fadeInUp'}}>
                        {/* <i class="fa fa-beer medium-icon"></i> */}
                        <span id="anim-number-pizza" class="counter-number"></span>
                        <span class="timer counter alt-font appear" data-to="980" data-speed="7000">{ite.frequency}</span>
                        <p class="counter-title pt-4">{ite.name}</p>
                    </div>
            ))
            )}
                   
                </div>
            </div>
        </section>
        
        </div>
        </div>
        </section>       
        <footer class="footer-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center">
            <small>©2022 Tizeti Inc.</small>
          </div>
        </div>
      </div>
      </footer>
  </body>


);
}

export default NominationResult;

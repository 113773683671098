/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../../hooks/user/useUser';
import BigScreenLogout from '../auth/BigScreenLogout';
import SmallScreenLogout from '../auth/SmallScreenLogout';
import NotificationBar from './NotificationBar';
import SmallLink from './custom/SmallLink';
import BigLink from './custom/BigLink';
// import Notification from './Notification';

const listenOnNav = (e) => {
  const navs = document.getElementsByClassName('nav-item');
  for (let i = 0; i < navs.length; i++) {
    navs[i].classList.remove('active');
  }
  // console.log(e);
  e.classList.add('active');
};

const Nav = () => {
  const [
    {
      user: { nin },
    },
  ] = useUser();

  return (
    <span>
      {!nin ? <NotificationBar /> : null}
      <nav className="navbar navbar-light d-flex d-md-none">
        <Link to="/" className="navbar-brand">
          <img src="images/wificom_logo.png" alt="GhanaWifi Logo" />
        </Link>

        <div className="dropdown">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent1"
            aria-controls="navbarSupportedContent1"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars" />
          </button>

          <div
            className="navbar-collapse collapse dd pt-4 pb-2"
            id="navbarSupportedContent1"
          >
            <Link target="_self" to="/" className="dropdown-item">
              Overview 2
            </Link>
            <Link target="_self" to="/pricing" className="dropdown-item">
              Pricing
            </Link>
            {/* <BigLink target="_self" to="/fund-wallet" className="dropdown-item">
        Fund Wallet
        </BigLink> */}
            <Link target="_self" to="/subscribe" className="dropdown-item">
              Subscribe
            </Link>
            <Link target="_self" to="/profile" className="dropdown-item">
              Profile
            </Link>
            <Link className="dropdown-item" to="/store" target="_self">
              Store
            </Link>
            <Link target="_self" to="/faq" className="dropdown-item">
              FAQ
            </Link>
            <Link target="_self" to="/kyc" className="dropdown-item">
              KYC
            </Link>
            <SmallLink link="/cac" name="CAC" />
            <SmallScreenLogout />
          </div>
        </div>
      </nav>

      <nav className="navbar navbar-expand-lg navbar-light bg-ligh d-none d-md-flex">
        <Link className="navbar-brand mr-5" to="/">
          <img src="images/wificom_logo.png" alt="GhanaWifi Logo" />
        </Link>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav col-md-10 mt-2">
            <li
              className="nav-item"
              onClick={(e) => {
                listenOnNav(e.target);
              }}
            >
              <Link className="nav-link" to="/" target="_self">
                <i className="fas fa-home" />
                Overview
                <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={(e) => {
                listenOnNav(e.target);
              }}
            >
              <Link className="nav-link" to="/pricing" target="_self">
                <i className="fas fa-credit-card" />
                Pricing
                <span className="sr-only">(current)</span>
              </Link>
            </li>
            {/*  <li className="nav-item" onClick={(e) => {listenOnNav(e.target)}}>
              <BigLink className="nav-link" to="/fund-wallet" target="_self">
                <i className="fas fa-wallet" />
                Fund Wallet
              </BigLink>
            </li>  */}
            <li
              className="nav-item"
              onClick={(e) => {
                listenOnNav(e.target);
              }}
            >
              <Link className="nav-link" to="/subscribe" target="_self">
                <i className="fas fa-file-alt" />
                Subscribe
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={(e) => {
                listenOnNav(e.target);
              }}
            >
              <Link className="nav-link" to="/profile" target="_self">
                <i className="fas fa-user-circle" />
                Profile
              </Link>
            </li>

            <li
              className="nav-item"
              onClick={(e) => {
                listenOnNav(e.target);
              }}
            >
              <Link className="nav-link" to="/referral" target="_self">
                <i className="fas fa-user-plus" />
                Refer a Friend
              </Link>
            </li>

            <li
              className="nav-item"
              onClick={(e) => {
                listenOnNav(e.target);
              }}
            >
              <Link className="nav-link" to="/store" target="_self">
                <i className="fas fa-store-alt" />
                Store
              </Link>
            </li>

            <li
              className="nav-item"
              onClick={(e) => {
                listenOnNav(e.target);
              }}
            >
              <Link className="nav-link" to="/faq" target="_self">
                <span className="fa fa-question-circle" />
                FAQ
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={(e) => {
                listenOnNav(e.target);
              }}
            >
              <Link className="nav-link" to="/kyc" target="_self">
                <span className="fa fa-user" />
                KYC
              </Link>
            </li>
            <BigLink link="/cac" name="CAC" />

            {/* <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Dropdown
              </button>
              <Notification />
            </div> */}

            <BigScreenLogout />
          </ul>
        </div>
      </nav>
      <br />
    </span>
  );
};

export default Nav;

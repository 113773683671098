import React from 'react';
import {Link} from 'react-router-dom';

const SmallLink = ({link,name}) => {
  return (
    <Link target="_self" to={link} className="dropdown-item">
      {name}
    </Link>

  );
};

export default SmallLink;

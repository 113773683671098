import React from 'react';
import Footer from '../layouts/Footer';
import UnauthenticatedNav from '../layouts/UnauthenticatedNav';

const ErrorPage = () => {
  const goHome = () => {
    window.history.back();
  };

  return (
    <div className="container-fluid p-0" id="">
      <UnauthenticatedNav />
      <hr className="mt-0 mb-3 d-flex d-md-none" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <h1 className="text404">404</h1>
              <h5 className="p-3" style={{ color: '#ec4341' }}>
                Oops! The page you requested does not exist
              </h5>
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={() => goHome()}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ErrorPage;

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import React from 'react';

const StoreHeader = () => {
  return (
      <div className="h-20 border-b-2 border-gray-300 text-center flex    items-center  ">
          <h4 className="text-capitalize">
              Store &#8594;Browse and Purchase OEM devices
          </h4>
      </div>

  );
};

export default StoreHeader;

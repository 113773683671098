import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReducer } from 'react';
import { GET_NOMINATION, START_NOMINATION, STOP_NOMINATION } from '../../constants/types/nomination/nominationTypes';
import TizetiCloudReducers from '../../reducers/tizeti-cloud/TizetiCloudReducers';

toast.configure()

function useTizetiCloud() {
  const [
    {list, isLoading }, dispatch,
  ] = useReducer(TizetiCloudReducers, {
    list: [],
    isLoading: false,
  });



  const getCloudPlans = async (data) => {
    dispatch({ type: START_NOMINATION });
    try {
      const response = await axios.post(`${'https://legacy.tizeti.com/geniatechapi/index.php'}`, data);
      if (response.data) {
        console.log(response.data.item);
        dispatch({
          type: GET_NOMINATION,
          payload: {
            list: response.data.item,
          },
        });
      }
    } catch (e) {
      toast.info('Could not fetch Names');
      if (e.response) {
        dispatch({ type: STOP_NOMINATION });
      }
    }
  };

  const postCloud = async (data) => {
    dispatch({ type: START_NOMINATION });
    try {
      const response = await axios.post(`${'https://legacy.tizeti.com/geniatechapi/index.php'}`, data);
      if (response.data) {
        console.log(response);
        toast.info('Info submitted successfully');
        window.location.reload();
        dispatch({
          type: STOP_NOMINATION,
        });
      } else {
        toast.error('oops');
      }

    } catch (e) {
      toast.info(e.response.data.message);
      if (e.response) {
        dispatch({ type: STOP_NOMINATION });
      }
    }
  };


  return {
    getCloudPlans,
    postCloud,
    list,
    isLoading,
  };
}

export default useTizetiCloud;

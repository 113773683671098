/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line jsx-a11y/anchor-is-valid
import React from 'react';
import Nav from '../layouts/Nav';
import Footer from '../layouts/Footer';

const FundWallet = () => {
  return (
    <span>
      <Nav />
      <hr className="mt-0 mb-3 d-flex d-md-none" />
      <div className="container" id="page-container">
        <div className="row">
          <div className="col-12 col-md-5 m-auto">
            <form action="" className="form-horizontal">
              <div className="form-group mb-3">
                <label htmlFor="" className="mb-1">
                  Enter Amount
                </label>
                <div className="input-group">
                  <div className="input-group-append">
                    <span
                      className="input-group-text GHC_box"
                      id="my-addon"
                      style={{ color: '#0B2325' }}
                    >
                      <b>GH₵</b>
                    </span>
                  </div>
                  <input
                    className="form-control border-left-0 account_input"
                    type="text"
                    name=""
                    placeholder=""
                    aria-label=""
                    aria-describedby="my-addon"
                  />
                </div>
              </div>
              <label htmlFor="" className="mb-1">
                Select Payment channel
              </label>
              <div className="form-group pay_div pt-2">
                <a href="#">
                  <img
                    src="images/theteller.png"
                    alt="The Teller Logo"
                    className="mx-auto d-block theteller_img"
                  />
                </a>
              </div>

              <div className="form-group pay_div">
                <a href="#">
                  <img
                    src="images/download.png"
                    alt="Flutterwave Logo"
                    className="flutterwave_img mx-auto d-block"
                  />
                </a>
              </div>

              <div className="form-group pay_div pt-3">
                <a href="#">
                  <img
                    src="images/slydepay-logo.png"
                    alt="Slydepay Logo"
                    className="slydepay_img mx-auto d-block"
                  />
                </a>
              </div>
              <div className="mt-4">
                <button type="button" className="btn btn-info w-100 last_btn">
                  Fund Wallet
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </span>
  );
};

export default FundWallet;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../components/auth/Login';
import Accounts from '../components/accounts/Accounts';
import FundWallet from '../components/fund-wallet/FundWallet';
import Overview from '../components/overview/Overview';
import Subscribe from '../components/subscribe/Subscribe';
import App from '../App';
import ForgotPassword from '../components/auth/ForgotPassword';
import ResetPassword from '../components/auth/ResetPassword';
import { PrivateRoute } from './PrivateRoute';
import Profile from '../components/profile/Profile';
import Referral from '../components/referral/Referral';
import SubscribeWithMac from '../components/subscribe/macid/SubscribeWithMacId';
import LoginWithToken from '../components/auth/LoginWithToken';
import LoginWithEmail from '../components/auth/LoginWithEmail';
import PaymentHistory from '../components/history/PaymentHistory';
import UsageHistory from '../components/history/UsageHistory';
import ErrorPage from '../components/error-page/ErrorPage';
import ComingSoon from '../components/coming-soon/ComingSoon';
import Faq from '../components/faq/Faq';
import Store from '../components/store/Store';
import Pricing from '../components/pricing/Pricing';
import BasicPlan from '../components/pricing/basic-plan/BasicPlan';
import PremiumPlan from '../components/pricing/premium-plan/PremiumPlan';
import StandardPlan from '../components/pricing/standard-plan/StandardPlan';
import Kyc from '../components/profile/kyc/Kyc';
import Cac from '../components/profile/CAC';
import FeedbackSystem from '../components/feedback/FeedbackSystem';
import Nomination from '../components/nomination/Nomination';
import TizetiCloud from '../components/tizeti-cloud/TizetiCloud';
import NominationResult from '../components/nomination-result/NominationResult';
import TermsandConditions from '../components/terms-and-condtions/TermsandConditions';


const Routes = () => (
  <App>
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/login/:email" component={LoginWithEmail} />
      <Route exact path="/verify/:id" component={LoginWithToken} />
      {/* <Route exact path="/login/:token" component={LoginWithToken} /> */}
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset/:token" component={ResetPassword} />
      <Route exact path="/subscribe/:macid" component={SubscribeWithMac} />
      <Route exact path="/feedback/:installationId" component={FeedbackSystem} />
      <Route exact path="/nomination" component={Nomination} />
      <Route exact path="/nomination-result" component={NominationResult} />
      <Route path="/terms-and-condition" component={TermsandConditions} />
      {/* <Route exact path="/tizeti-cloud" component={TizetiCloud} /> */}
{/* 
      <PrivateRoute exact path="/accounts" component={Accounts} /> */}
      <PrivateRoute exact path="/fund-wallet" component={FundWallet} />
      <PrivateRoute exact path="/" component={Overview} />
      <PrivateRoute exact path="/pricing" component={Pricing} />
      <PrivateRoute exact path="/pricing/basic-plan" component={BasicPlan} />
      <PrivateRoute
        exact
        path="/pricing/standard-plan"
        component={StandardPlan}
      />
      <PrivateRoute
        exact
        path="/pricing/premium-plan"
        component={PremiumPlan}
      />
      <PrivateRoute exact path="/faq" component={Faq} />
      <PrivateRoute exact path="/store" component={Store} />
      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute exact path="/referral" component={Referral} />
      <PrivateRoute exact path="/subscribe" component={Subscribe} />
      <PrivateRoute exact path="/coming-soon" component={ComingSoon} />
      <PrivateRoute exact path="/payment-history" component={PaymentHistory} />
      <PrivateRoute exact path="/usage-history" component={UsageHistory} />
      <PrivateRoute exact path="/kyc" component={Kyc} />
      <PrivateRoute exact path="/cac" component={Cac} />
      <PrivateRoute component={ErrorPage} />
    </Switch>
  </App>
);

export default Routes;

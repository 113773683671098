import { useReducer } from 'react';
import qs from 'qs';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as types from '../../constants/types/auth/AuthTypes';
import { AellaPaymentReducer } from '../../reducers/aella/AellaPaymentReducer';
import { GENIATECH_URL } from '../../services/api/accounts/AccountsUrl';

const useAellaPayment = () => {
  const [{ aellaLoading }, dispatch] = useReducer(AellaPaymentReducer, {
    aellaLoading: false,
  });

  const createAellaPayment = async ({ cin, amount }) => {
    dispatch({ type: types.START_AELLA_PAYMENT });
    const data = qs.stringify({
      action: 'generate_aella_url',
      cin,
      email: `${sessionStorage.getItem('email')}`,
      amount,
    });
    try {
      const response = await axios.post(GENIATECH_URL, data);
      if (response.status === 200) {
        window.location.href = response.data.url;
      }
    } catch (e) {
      toast.error('Unsuccessful');
      dispatch({ type: types.STOP_AELLA_PAYMENT });
    }
  };

  return { aellaLoading, createAellaPayment };
};

export default useAellaPayment;

import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

function BigScreenLogout({ history }) {
  const logoutUser = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('email');
    // eslint-disable-next-line react/prop-types
    history.push('/');
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <div
      className="h-7  hover:border-b-4 text-center text-center cursor-pointer  transition-all duration-150  ease-in-out  w-32"
      onClick={logoutUser}

    >
      <span className="flex items-center justify-start  text-sm text-decoration-none  text-white ">
        <i className="fas fa-sign-out-alt" />
        Logout
      </span>
    </div>
  );
}

BigScreenLogout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default withRouter(BigScreenLogout);

import React, { useEffect, useState } from 'react';
import qs from 'qs';
import Select from 'react-select';
import useNomination from '../../hooks/nomination/useNomination';
import './App.css';
import useStaff from '../../hooks/nomination/useMaleStaff';
import useMaleStaff from '../../hooks/nomination/useMaleStaff';
import useFemaleStaff from '../../hooks/nomination/useFemaleStaff';

const Nomination = () => {

  const { postNomination, isLoading } = useNomination();
  const { list, getMaleStaff } = useMaleStaff();
  const { item, getFemaleStaff } = useFemaleStaff();
  const [NominationCategory, setNominationCategory] = useState();
  const [NominationName, setNominationName] = useState();
  const [NominationEmail, setNominationEmail] = useState();
  console.log(NominationName)


  // console.log(item.name);
  const data = qs.stringify({
    action: 'cast_nomination',
    name: NominationName?.value,
    category: NominationCategory,
    email: NominationEmail,
  });

  console.log(data)

  const onSubmit = (e) => {
    e.preventDefault();
    postNomination(data);
  };
  
  const handleChange = value => {
    setNominationName(value);
  };

  useEffect(() => {
    getMaleStaff( qs.stringify({ action: 'get_staff', sex: 'male'  }));
    getFemaleStaff( qs.stringify({ action: 'get_staff', sex: 'female'   }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let FemaleOptions = item.map(function (ite) {
    return { value: ite.name, label: ite.name };
  })

 let MaleOptions = list.map(function (lis) {
  return { value: lis.name, label: lis.name };
})
  

  return (
    <body>
    <nav class="navbar fixed-top navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#"><img src="images/tizeti-logo.png" alt="" class="img-fluid"/></a>
      </div>
    </nav>  
    <section class="cat" id="category"  style={{ backgroundImage:  `url("images/cart.jpeg")` }}>
      <div class="container">
        <div class="row justify-content-left">
          <div class="col-md-5 pt-3">
        
            <h2>Nomination for <br/>Award Categories</h2>
            <div class="box">
              <form   onSubmit={onSubmit}>
              <div class="mb-4">
                <label class="mb-1">Email</label>
                <input class="form-control" type="email" aria-label="Default select example" name="NominationCategory" value={NominationEmail}   placeholder="Enter your Tizeti email address" onChange={(e) => setNominationEmail(e.target.value)} required>
                    </input>
                    </div>
                <div class="mb-4">
                <label class="mb-1">Categories</label>
                <select class="form-select" aria-label="Default select example" name="NominationCategory" value={NominationCategory}   onChange={(e) => setNominationCategory(e.target.value)} required>
              
                 <option value="">Select a category</option>
                      <option value="best_dressed_male">Best Dressed Male </option> 
                      <option value="best_dressed_female">Best Dressed Female</option>
                    </select>
                    </div>
                    {NominationCategory !== 'best_dressed_male' ? (
                         <><label class="mb-1">Best Dressed Female</label> <br/>
                         {/* <button type="button" class="btn btn-i text-dark" data-toggle="modal" data-target="#staticBackdrop"> <i class="fa fa-info"></i> Get information
                    </button><div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-sm">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div class="text-right text-white"> <p data-dismiss="modal"> Close</p> </div>
                              
                                  <div class="text-white mt-2">
                                    
                                    <div class="mt-2"> <span class="intro-2">Voters should consider the following criteria when deciding who to vote for: <br/>
                                  1. Both the man and the woman must be people who can be counted on to help solve problems.<br/>
                                  2. Both the male and female must be able to work well with staff from other departments or teams.<br/>
                                  3. Both the male and female must have a high level of influence in the organization.</span> </div>
                      
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    */}
                      <><Select 
                        name="NominationName"
                        class="form-select"
                        onChange={handleChange}
                        labelKey='name'
                        valueKey='name'
                        value={NominationName}
                        options={FemaleOptions} /></></>

               
                ) : (
                <div class="mb-4">
                  <label class="mb-1">Best Dressed Male</label> <br/>
                  {/* <button type="button" class="btn btn-i text-dark" data-toggle="modal" data-target="#staticBackdrop"> <i class="fa fa-info"></i> Get information
                    </button><div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-sm">
                          <div class="modal-content">
                            <div class="modal-body">
                              <div class="text-right text-white"> <p data-dismiss="modal"> Close</p> </div>
                              
                                  <div class="text-white mt-2">
                                    
                                    <div class="mt-2"> <span class="intro-2">Voters should consider the following criteria when deciding who to vote for: <br/>
                                  1. Both the man and the woman must be people who can be counted on to help solve problems.<br/>
                                  2. Both the male and female must be able to work well with staff from other departments or teams.<br/>
                                  3. Both the male and female must have a high level of influence in the organization.</span> </div>
                      
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                  <Select
                      name="NominationName"
                      class="form-select"
                      onChange= {handleChange}
                      labelKey='name'
                      valueKey='name'
                      value={NominationName}
                      options={MaleOptions}/>
                </div>
                    )}
                <div>
                  <button class="btn btn-primary px-4 py-2 mt-4">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center">
            <small>©2022 Tizeti Inc.</small>
          </div>
        </div>
      </div>
    </footer>
  </body>


);
}

export default Nomination;

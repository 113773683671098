import React, { useEffect } from 'react';
import { UseAccounts } from '../../../hooks/accounts/useAccounts';

const Accounts = () => {
  const [{ accounts, isLoading }] = UseAccounts();


  return (
      <div
      className="rounded-2xl mt-3 border bg-gray-100 "
      style={{  fontSize: '10px' }}
      >
          {isLoading ? (
          <div className="rounded-2xl  ">
            <img alt="loading..." src="images/TizetiLoaderColored.gif"  className='mx-auto'/>
          </div>
        ) : (
            accounts.map((account) => (
          <div
          className='flex justify-between px-2 pt-3  rounded-2xl  mb-4   flex-col md:flex-row'
          style={{ backgroundColor: '#F5F7F7'}}
          >
            <div className='flex  xs:flex-row md:flex-col justify-between  space-y-2 mb-3 border-b-2 md:border-b-0'>
                <div className='text-sm ' style={{ color: '#1E8E8B'}} > Account Name </div>
                <div className='text-sm capitalize m-0' style={{ color: '#1E8E8B'}}>  {account.name}  </div>
            </div>

            <div className='flex xs:flex-row md:flex-col justify-between space-y-2 mb-3 border-b-2 md:border-b-0'>
                <div className='text-sm ' style={{ color: '#1E8E8B'}}> CIN </div>
                <div className='text-sm m-0' style={{ color: '#1E8E8B'}}>   {account.customer_ref} </div>
            </div>


            <div className='flex sm:flex-row md:flex-col justify-between space-y-2 mb-3 border-b-2 md:border-b-0'>
                <div className='text-sm ' style={{ color: '#1E8E8B'}}> Mac Address </div>
                <div className='text-sm m-0' style={{ color: '#1E8E8B'}}>    {account.username} </div>
            </div>


            <div className='flex sm:flex-row md:flex-col justify-between space-y-2  mb-3 border-b-2 md:border-b-0'>
                <div className='text-sm ' style={{ color: '#1E8E8B'}}> Password </div>
                <div className='text-sm m-0' style={{ color: '#1E8E8B'}}>    {account.router_password}  </div>
            </div>


            <div className='flex sm:flex-row md:flex-col justify-between space-y-2  md:w-1/5 mb-3 border-b-2 md:border-b-0'>
                <div className='text-sm ' style={{ color: '#1E8E8B'}}> Address</div>
                <div className='text-sm m-0 capitalize' style={{ color: '#1E8E8B'}}>
                {account.customerAddress}

                </div>
            </div>


            <div className='flex sm:flex-row md:flex-col justify-between items-center space-y-2 mb-3 border-b-2 md:border-b-0'>
                <div className='text-sm  text-red-500' > Expiry Date </div>
                <div className='text-sm text-red-500 m-0' >   {account.value}  </div>
            </div>

           </div>

           )

         ))  }
      </div>
  );
          }

export default Accounts;

import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReducer } from 'react';
import { START_FEEDBACK, STOP_FEEDBACK } from './feedbackTypes';
import FeedbackReducer from './FeedbackReducer';

toast.configure()

function useFeedback() {
  const [
    { isLoading }, dispatch,
  ] = useReducer(FeedbackReducer, {
    isLoading: false,
  });

  const postFeedback = async (data) => {
    dispatch({ type: START_FEEDBACK });
    try {
      const response = await axios.post(`${'https://api.tizeti.com/api/v1/ifs'}`, data);
      if (response.data) {
        toast.success('Feedback submitted successfully');
        window.location.assign('https://www.tizeti.com');
        dispatch({
          type: STOP_FEEDBACK,
        });
      } else {
        toast.error('oops');
      }

    } catch (e) {
      toast.info(e.response.data.message);
      if (e.response) {
        dispatch({ type: STOP_FEEDBACK });
      }
    }
  };

  return {
    postFeedback,
    isLoading,
  };
}

export default useFeedback;

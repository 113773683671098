import React from 'react';
import UploadFile from './UploadFile';
import { KycProvider } from '../../../context/kyc/KycContext';
import KycHeader from './KycHeader';
import { useUser } from '../../../hooks/user/useUser';

const KycBody = () => {
  const [{ user }] = useUser();

  const accounts = user?.accounts;

  return (
    // <div className="" style={{ backgroundColor: '#F5F7F7' }}>
    <div>
      <KycProvider>
        <KycHeader />
        <span>
          {!user && !accounts ? (
            <div className="flex m-auto h-[100vh] items-center justify-center">
              <img alt="loading..." src="images/TizetiLoaderColored.gif" />
            </div>
          ) : (
            <UploadFile w="8" accounts={accounts} />
          )}
        </span>
      </KycProvider>
    </div>
  );
};

export default KycBody;

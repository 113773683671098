/* eslint-disable no-underscore-dangle */
import { useState, useReducer, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { stringify } from 'qs';
import { AuthReducer } from '../../reducers/auth/AuthReducer';
import * as types from '../../constants/types/auth/AuthTypes';
import * as url from '../../services/api/auth/AuthUrl';
import { loginAUser } from '../../services/auth/AuthService';

// eslint-disable-next-line import/prefer-default-export
export const useAuth = () => {
  const [
    {
      isLoading,
      isSuccess,
      isSuccessMessage,
      isFail,
      isFailMessage,
      token,
      isError,
      isErrorMessage,
    },
    dispatch,
  ] = useReducer(AuthReducer, {
    isLoading: false,
    isSuccess: false,
    isSuccessMessage: '',
    isFail: false,
    isFailMessage: '',
    token: false,
  });
  const loginState = { email: '', password: '' };
  const forgotState = { email: '' };
  const resetState = { password: '', confirm: '' };

  const [login, setLogin] = useState(loginState);
  const [forgot, setForgot] = useState(forgotState);
  const [reset, setReset] = useState(resetState);
  const [showAcceptCheckbox, setShowAcceptCheckbox] = useState(false);
  const [terms, setTerms] = useState(false);
  /**
   * to show th ebutton if the user is yet to agree to terms
   */
  const [yetToAgree, setYetToAgree] = useState(false);
  const onLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({
      ...login,
      [name]: value,
    });
  };

  const onForgotChange = (e) => {
    const { name, value } = e.target;

    setForgot({
      ...forgot,
      [name]: value,
    });
  };

  const onResetChange = (e) => {
    const { name, value } = e.target;

    setReset({
      ...reset,
      [name]: value,
    });
  };

  const handleTermsChange = (event) => {
    setTerms(!terms);
    setYetToAgree(true);
  };

  const loginUser = async (user, history) => {
    dispatch({
      type: types.START_LOGIN_USER,
    });

    if (user) {
      const { email, password } = user;

      const data = {
        email,
        password,
      };

      try {
        const response = await axios.post(`${url.OLD_LOGIN_URL}`, data);
        // eslint-disable-next-line no-underscore-dangle
        if (response.data._token) {
          // eslint-disable-next-line no-underscore-dangle
          sessionStorage.setItem('token', response.data._token);
          sessionStorage.setItem('email', email);
          setLogin(loginState);

          history.push('/');
          dispatch({
            type: types.STOP_LOGIN_USER,
          });
        }
      } catch (e) {
        if (e.response) {
          if (e.response.data.message === 'invalid credentials') {
            toast.error(e.response.data.message);
            dispatch({
              type: types.LOGIN_USER_FAIL,
            });
          } else {
            dispatch({
              type: types.LOGIN_USER_FAIL,
              payload: false,
            });
          }
        }
      }
    }
  };

  const forgotPassword = async (data) => {
    dispatch({
      type: types.START_FORGET_PASSWORD,
    });

    try {
      const response = await axios.post(`${url.FORGOT_PASSWORD_URL}`, data);
      switch (response.data.status) {
        case 'success':
          toast.success(response.data.message.toUpperCase());
          dispatch({
            type: types.FORGET_PASSWORD_SUCCESSFUL,
            payload: response.data.message,
          });
          setForgot(forgotState);
          break;

        case 'failed':
          toast.info(response.data.message);
          dispatch({
            type: types.UNABLE_TO_FORGET_PASSWORD,
            payload: response.data.message,
          });
          break;

        default:
          dispatch({
            type: types.UNABLE_TO_FORGET_PASSWORD,
            payload: 'Unable to send email!',
          });
      }
    } catch (e) {
      if (e) {
        toast.success('Something went wrong!');
        dispatch({
          type: types.STOP_FORGET_PASSWORD,
          payload: 'Something went wrong!',
        });
      }
    }
  };

  const resetPassword = async (data, id, history) => {
    dispatch({
      type: types.START_RESET_PASSWORD,
    });
    const change = {
      password: data.password,
      confirm_password: data.password,
      token: id,
    };
    try {
      const response = await axios.post(`${url.RESET_PASSWORD_URL}`, change);

      if (
        response.data.message === 'Password reset successful' &&
        response.data.status === 'success'
      ) {
        dispatch({
          type: types.RESET_PASSWORD_SUCCESSFUL,
        });
        toast.success(response.data.message);
        history.push('/login');
      } else {
        dispatch({
          type: types.STOP_RESET_PASSWORD,
        });
        toast.info('Something went wrong!');
      }
    } catch (e) {
      if (
        e.response.data.message === 'link has expired, please reset' &&
        e.response.data.status === 'failed'
      ) {
        dispatch({
          type: types.UNABLE_TO_RESET_PASSWORD,
        });
        toast.info(e.response.data.message);

        setTimeout(() => {
          history.push('/forgot-password');
        }, 2000);
      }
    }
  };
  const loginUserWithEmail = async (value, history) => {
    dispatch({
      type: types.START_LOGIN_USER,
    });

    const data = { email: window.atob(value) };
    try {
      const response = await loginAUser(data);
      if (response.data._token) {
        sessionStorage.setItem('token', response.data._token);
        sessionStorage.setItem('email', window.atob(value));
        history.push('/');
        dispatch({
          type: types.STOP_LOGIN_USER,
        });
      }
    } catch (e) {
      if (e.response) {
        if (e.response.data.message === 'invalid credentials') {
          toast.error(e.response.data.message);
          dispatch({
            type: types.LOGIN_USER_FAIL,
          });
        } else {
          dispatch({
            type: types.LOGIN_USER_FAIL,
            payload: false,
          });
        }
      } else {
        toast.error('Unable to log in user');

        dispatch({
          type: types.LOGIN_USER_FAIL,
          payload: false,
        });
      }
    }
  };
  const editUser = async (id, history) => {
    const data = { emailVerified: 'no' };
    try {
      const response = await axios.patch(`${url.VERIFY_USER_URL}/${id}`, data);
      if (response.data && typeof response.data === 'object') {
        history.push('/login');
      } else {
        toast.error('Unable to verify user!');
      }
    } catch (err) {
      toast.error('something went wrong!');
    }
  };

  //   const acceptTermsAndConditions = async (value, history) => {
  //     try {
  //       const response = await axios.post(
  //         url.AGREE_TO_TERMS_URL,
  //         stringify({
  //           email: value.email,
  //         }),
  //         {
  //           headers: {
  //             Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  //           },
  //         },
  //       );

  //       //   console.log('customer_accepted_terms_and_conditions', response);
  //       //   loginUser(value, history);
  //       if (response.data) {
  //         loginUser(value, history);
  //       } else {
  //         toast.info('Unable to accept terms and conditions');
  //       }
  //     } catch (error) {
  //       toast.info('Something went wrong!');
  //     }
  //   };
  //   const checkIfUserHasAgreedToTerms = async (value, history) => {
  //     dispatch({
  //       type: types.START_LOGIN_USER,
  //     });
  //     // if (terms) {
  //     //     acceptTermsAndConditions(value, history);
  //     // } else {

  //     // }
  //     const data = {
  //       email: value.email,
  //     };
  //     const response = await axios.post(
  //       `${url.CHECK_IF_USER_HAS_AGREED_TO_TERMS_URL}`,
  //       data,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${sessionStorage.getItem('token')}`,
  //         },
  //       },
  //     );

  //     if (response.data.status) {
  //       loginUser(value, history);
  //     } else {
  //       dispatch({
  //         type: types.STOP_LOGIN_USER,
  //       });
  //       setYetToAgree(true);
  //       setShowAcceptCheckbox(true);
  //     }
  //   };

  useEffect(() => {
    const checkIfLoggedIn = () => {
      if (sessionStorage.getItem('token')) {
        dispatch({
          type: types.CHECK_IF_TOKEN_EXISTS,
          payload: true,
        });
      }
    };

    checkIfLoggedIn();
  }, []);
  return {
    onLoginChange,
    loginUser,
    onForgotChange,
    forgotPassword,
    onResetChange,
    resetPassword,
    login,
    forgot,
    reset,
    isSuccess,
    isSuccessMessage,
    isFail,
    isFailMessage,
    isLoading,
    token,
    isError,
    isErrorMessage,
    loginUserWithEmail,
    editUser,
    showAcceptCheckbox,
    terms,
    handleTermsChange,
    yetToAgree,
  };
};

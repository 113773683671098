import { API_URL } from '../BaseUrl';

export const LOGIN_URL = `https://api.tizeti.com/api/v1/login/email`;
export const FORGOT_PASSWORD_URL = `${API_URL}/forgotPassword`;
export const RESET_PASSWORD_URL = `${API_URL}/reset`;
export const LOGIN_WITH_EMAIL_URL = `${API_URL}/login/email`;
export const VERIFY_USER_URL = `${API_URL}/users/verify`;
export const CHECK_IF_USER_HAS_AGREED_TO_TERMS_URL = `https://api.tizeti.com/api/v1/legacy/customerAcceptedTerms`;
export const AGREE_TO_TERMS_URL = `https://api.tizeti.com/api/v1/legacy/acceptedTerms`;
export const OLD_LOGIN_URL = `https://api.tizeti.com/api/v1/login`;

/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import usePricingPlan from '../../../hooks/pricing-plan/usePricingPlan';
import useRadioPrice from '../../../hooks/pricing-plan/useRadioPrice';

const PremiumPlanBody = ({
  transaction_limit,
  premiumprice,
  premiumpriceTotal,
  monthNumber,
  setMonthNumber,
  monthNum,
  setMonthNum,
  month,
  months,
  itemPrices,
  accounts,
  firstName,
  lastName,
}) => {
  const { getPricingPlan } = usePricingPlan();
  const { getRadioPrice, setIsLoading, radioPrice } = useRadioPrice();


  // const [item_name_b, setItemName_b] = useState('');
  // const [item_name_c, setItemName_c] = useState('');
  // const [item_name_d, setItemName_d] = useState('');
  // const [item_name_e, setItemName_e] = useState('');

  const detailsState = {
   
    first_Name: '',
    last_Name: '',
    custAddress: '',
    custNum: '',
    dob: '',
    planType: '',
    item_name_value_a: '',
    item_name_value_b: '',
    item_name_value_c: '',
    item_name_value_d: '',
    item_name_value_e: '',
    item_price_value_a: '',
    item_price_value_b: '',
    item_price_value_c: '',
    item_price_value_d: '',
    item_price_value_e: '',
  };
  const [details, setDetails] = useState(detailsState);
  const [price, setPrice] = useState([]);
  const {
    first_Name,
    last_Name,
    custAddress,
    custNum,
    dob,
    planType,
    item_name_value_a,
    item_name_value_b,
    item_name_value_c,
    item_name_value_d,
    item_name_value_e,
    item_price_value_a,
    item_price_value_b,
    item_price_value_c,
    item_price_value_d,
    item_price_value_e,
  } = details;

  useEffect(() => {
    getRadioPrice();
    const paddyplan = {
      first_Name: firstName,
      last_Name: lastName,
      custAddress: accounts[0]?.customerAddress,
      custNum: accounts[0]?.customer_ref,
      dob: '',
      planType: 'paddy-bronze-plan',
      item_name_value_a: 'Tizeti Radio Device (Required)',
      item_name_value_b: '',
      item_name_value_c: '',
      item_name_value_d: '',
      item_name_value_e: '',
      item_price_value_a: radioPrice.map((radio) => {
        return radio.price.replace(/,/g, '');
      }),
      item_price_value_b: '',
      item_price_value_c: '',
      item_price_value_d: '',
      item_price_value_e: '',
    };
    setDetails({ ...paddyplan });
  }, [accounts, itemPrices, firstName, lastName]);

  const item_total =
    1 * item_price_value_a +
    1 * item_price_value_b +
    1 * item_price_value_c +
    1 * item_price_value_d +
    1 * item_price_value_e;

  function handleDetails(e) {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  }
  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      firstname: first_Name,
      lastname: last_Name,
      cin: custNum,
      dob,
      amount: monthNum * itemPrices + premiumpriceTotal,
      no_of_months_pc: monthNumber,
      no_of_months_internet: monthNum,
      type: planType,
      address: custAddress,
      item_name_a: item_name_value_a,
      item_name_b: item_name_value_b,
      item_name_c: item_name_value_c,
      item_name_d: item_name_value_d,
      item_name_e: item_name_value_e,
      item_price_a: item_price_value_a,
      item_price_b: item_price_value_b,
      item_price_c: item_price_value_c,
      item_price_d: item_price_value_d,
      item_price_e: item_price_value_e,
    };

    getPricingPlan(data);
  };

  return (
    <div>
      <form className="mb-20" onSubmit={onSubmit}>
        <div className="flex flex-col space-x-3 mt-3">
          <div className=" grid grid-cols-1 md:grid-cols-4  gap-4">
            <div className="flex flex-col ">
              <p className="text-xs text-gray-500">First Name (Required) </p>
              <input
                type="text"
                className="border-2 border-gray-400 p-2  text-black rounded "
                name="first_Name"
                value={first_Name}
                onChange={handleDetails}
                placeholder="First Name"
              />
            </div>

            <div className="flex flex-col ">
              <p className="text-xs text-gray-500">Last Name (Required) </p>
              <input
                type="text"
                className="border-2 border-gray-400 p-2  text-black rounded "
                name="last_Name"
                value={last_Name}
                onChange={handleDetails}
                placeholder="Last Name"
              />
            </div>

            <div className="flex flex-col">
              <p className="text-xs text-gray-500">CIN (Required) </p>
              <input
                type="text"
                className="border-2 border-gray-400 p-2  text-black rounded "
                name="custNum"
                value={custNum}
                disabled
                onChange={handleDetails}
                placeholder="CIN"
              />
            </div>

            <div className="flex flex-col">
              <p className="text-xs text-gray-500">Address (Required) </p>
              <input
                type="text"
                className="border-2 border-gray-400 p-2  text-black rounded "
                name="custAddress"
                value={custAddress}
                onChange={handleDetails}
                placeholder="Address"
              />
            </div>

            <div className="flex flex-col justify-content-center align-items-center">
              <p className="text-xs text-gray-500">Date of Birth (Required)</p>
              <input
                type="date"
                className="border-2 border-gray-400 p-2  text-black rounded "
                name="dob"
                placeholder="DOB (YYYY-MM-DD)"
                value={dob}
                onChange={handleDetails}
              />
            </div>
          </div>

          <div className=" grid grid-cols-1  gap-1 mx-0 mt-3">
            <div className="flex flex-row space-x-3  items-center justify-start h-10">
              <h2 className="text-base text-capitalize m-0 text-base mr-3">
                {' '}
                Tizeti Internet Plan
              </h2>
              <select
                defaultValue={monthNum}
                id="monthNum"
                className=" mt-0 mb-0 ml-3 mr-3 border-2 p-2 rounded "
                onChange={(e) => setMonthNum(e.target.value)}
              >
                <option value={1}>1 Month</option>
                {months.map((month) => (
                  <option value={month}> {month} Months</option>
                ))}
              </select>
              {itemPrices ? (
                <label className="m-0 lighter_font cinFont" htmlFor="monthNum">
                  NGN
                  <span className="ml-1">{monthNum * itemPrices}</span>
                </label>
              ) : null}
            </div>

            <div className="flex flex-row space-x-3 items-center justify-start h-10">
              <h2 className="text-base text-capitalize m-0 text-base mr-3">
                {' '}
                Paddy Cover Insurance Plan
              </h2>
              <select
                defaultValue={monthNumber}
                id="monthNumber"
                className=" mt-0 mb-0 ml-3 mr-3 border-2 p-2 rounded "
                onChange={(e) => setMonthNumber(e.target.value)}
              >
                <option value={1}>1 Month</option>
                {month.map((monthe) => (
                  <option value={monthe}> {monthe} Months</option>
                ))}
              </select>
              {premiumprice ? (
                <label
                  className="m-0 lighter_font cinFont"
                  htmlFor="monthNumber"
                >
                  NGN
                  <span className="ml-1">{monthNumber * premiumprice}</span>
                </label>
              ) : null}
            </div>
          </div>

          {/* <div className=" grid grid-cols-1 mt-3 mx-0"> */}
          {/*  <textarea */}
          {/*      placeholder="Address" */}
          {/*      className=" border-2 border-gray-400 p-2 text-black rounded" */}
          {/*      rows="4"> */}
          {/*  {address} */}

          {/*  </textarea> */}
          {/* </div> */}

          <div className="flex flex-col mx-0">
            <h3 className="text-base mt-3  mx-0 ">
              Add Items to Insurance Plan (Total Insurance Limit ={' '}
              <span className="text-red-500">
                {transaction_limit.toLocaleString()} NGN{' '}
              </span>{' '}
              ){' '}
            </h3>
            <div className="grid grid-cols-3 gap-1 mt-1">
              <p>S/N</p>
              <p>Device Name</p>
              <p>Amount</p>

              <p>1.</p>
              <p>{item_name_value_a} </p>
              <p> {item_price_value_a} </p>

              <p>2.</p>
              <div>
                <input
                  type="text"
                  className="border-0  p-2  text-black rounded w-full "
                  name="item_name_value_b"
                  value={item_name_value_b}
                  onChange={handleDetails}
                  placeholder="Enter device Name (Optional)"
                />
              </div>
              <div>
                <input
                  type="number"
                  className="border-0  p-2  text-black rounded w-full "
                  name="item_price_value_b"
                  value={item_price_value_b}
                  onChange={handleDetails}
                  placeholder="0"
                />
              </div>

              <p>3.</p>
              <div>
                <input
                  type="text"
                  className="border-0  p-2  text-black rounded w-full "
                  name="item_name_value_c"
                  value={item_name_value_c}
                  onChange={handleDetails}
                  placeholder="Enter device Name (Optional)"
                />
              </div>
              <div>
                <input
                  type="number"
                  className="border-0  p-2  text-black rounded w-full "
                  name="item_price_value_c"
                  value={item_price_value_c}
                  onChange={handleDetails}
                  placeholder="0"
                />
              </div>

              <p>4.</p>
              <div>
                <input
                  type="text"
                  className="border-0  p-2  text-black rounded w-full "
                  name="item_name_value_d"
                  value={item_name_value_d}
                  onChange={handleDetails}
                  placeholder="Enter device Name (Optional)"
                />
              </div>
              <div>
                <input
                  type="number"
                  className="border-0  p-2  text-black rounded w-full "
                  name="item_price_value_d"
                  value={item_price_value_d}
                  onChange={handleDetails}
                  placeholder="0"
                />
              </div>

              <p>5.</p>
              <div>
                <input
                  type="text"
                  className="border-0  p-2  text-black rounded w-full "
                  name="item_name_value_e"
                  value={item_name_value_e}
                  onChange={handleDetails}
                  placeholder="Enter device Name (Optional)"
                />
              </div>
              <div>
                <input
                  type="number"
                  className="border-0  p-2  text-black rounded w-full "
                  name="item_price_value_e"
                  value={item_price_value_e}
                  onChange={handleDetails}
                  placeholder="0"
                />
              </div>

              <p className="border-b-2 border-black"> </p>
              <p className="border-b-2 border-black"> </p>
              <p className="border-b-2 border-black"> </p>

              <p className="border-t-1 ">Total</p>

              {item_total <= transaction_limit ? (
                <>
                  <p className="text-green-500 text-bold">
                    {' '}
                    {item_total.toLocaleString()}
                  </p>
                  <button className="bg-green-500 rounded p-3 text-white hover:bg-gray-500 transition-all duration-150 ease-out">
                    {' '}
                    Proceed
                  </button>
                </>
              ) : (
                <p className="text-red-500"> Exceeded Limit </p>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PremiumPlanBody;

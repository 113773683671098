import { useState, useReducer } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as types from '../../constants/types/auth/AuthTypes';
import { CHANGE_PASSWORD_URL } from '../../services/api/profile/ProfileUrl';
import { ProfileReducer } from '../../reducers/profile/ProfileReducer';

// eslint-disable-next-line import/prefer-default-export
export const useProfile = () => {
  const changeState = { password: '', newPassword: '', confirmPassword: '' };

  const [change, setChange] = useState(changeState);

  const [
    {
      isLoading,
      isSuccess,
      isSuccessMessage,
      isFail,
      isFailMessage,
      token,
      isError,
      isErrorMessage,
    },
    dispatch,
  ] = useReducer(ProfileReducer, {
    isLoading: false,
    isSuccess: false,
    isSuccessMessage: '',
    isFail: false,
    isFailMessage: '',
    token: false,
  });

  const onChangePassword = (e) => {
    const { name, value } = e.target;
    setChange({
      ...change,
      [name]: value,
    });
  };

  const changePassword = async (data) => {
    dispatch({
      type: types.START_CHANGING_PASSWORD,
    });

    try {
      const response = await axios.post(CHANGE_PASSWORD_URL, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data.status) {
        toast.success(response.data.message);
        setChange(changeState);
        dispatch({
          type: types.STOP_CHANGING_PASSWORD,
        });
      }
    } catch (e) {
      dispatch({
        type: types.STOP_CHANGING_PASSWORD,
      });
    }
  };

  return {
    onChangePassword,
    changePassword,
    change,
    isSuccess,
    isSuccessMessage,
    isFail,
    isFailMessage,
    isLoading,
    token,
    isError,
    isErrorMessage,
  };
};

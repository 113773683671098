/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import React from 'react';

const CACHeader = () => {
  return (
    <div
      className="font_small font-weight-light"
      // style={{ backgroundColor: '#F5F7F7' }}
    >
      <div className="mt-1">
        <p>
          Home > CAC <br />
        </p>
      </div>
      <div className="font_big mt-2">
        <h6>Upload your files</h6>
        <hr />
      </div>
    </div>
  );
};

export default CACHeader;

import React from 'react';

const CurrentPlans = ({
  isLoading,
  itemprice,
  account,
  monthNum,
  setMonthNum,
  autoDebit,
  setAutoDebit,
}) => {
  const months = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <div className="p-4 rounded-2xl border-1 border-gray-200 flex flex-col justify-between space-y-1">
      <div className="flex flex-col">
        <h6 className="font-bold ">Make Payment</h6>
        <p className="font-light text-sm">
          Choose all preferred payment method and plan
        </p>
      </div>

      <div className="flex flex-row rounded-2xl border-1 border-cyan-600 justify-center w-full p-2 space-y-5 space-x-3">
        {isLoading ? (
          <div>
            <img alt="loading..." src="images/TizetiLoaderColored.gif" />
          </div>
        ) : (
          <div className="flex flex-col  justify-center w-full space-y-5  mx-2">
            <div className="flex flex-col  ">
              <p className="text-color-cyan-800 text-base  mt-4 mb-0">
                <i className="fas fa-seedling " /> Default Plan
              </p>
              <p className="text-color-cyan-800 text-sm font-normal">
                {monthNum}
                Month(s) - Total ({monthNum * itemprice})
              </p>
            </div>

            <div className="flex flex-col    ">
              <p className="text-color-cyan-800 text-base mt-0 mb-0">
                {' '}
                <i className="far fa-address-card" /> Customer ID Number{' '}
              </p>
              <p className="text-color-cyan-800 text-sm font-normal">
                {account.customer_ref}{' '}
              </p>
            </div>

            <div className="flex flex-col ">
              <p className="text-color-cyan-800 text-base mt-0 mb-0 ">
                {' '}
                <i className="fas fa-plane-departure" /> Choose Plan{' '}
              </p>
              <div className="text-color-cyan-800 text-sm font-normal mb-4 flex-col space-x-2">
                <div className="flex flex-row space-x-2">
                  <select
                    value={monthNum}
                    id="monthNum"
                    className="form-control-sm inputBorder mt-2 mr-3 "
                    onChange={(e) => setMonthNum(e.target.value)}
                  >
                    <option value={1}>1 Month</option>
                    {months.map((month) => (
                      <option value={month}> {month} Months</option>
                    ))}
                  </select>

                  {itemprice ? (
                    <label
                      className="d-flex mt-3 lighter_font cinFont"
                      htmlFor="monthNum"
                    >
                      NGN
                      <span className="ml-1">{monthNum * itemprice}</span>
                    </label>
                  ) : null}
                </div>

                <div className="mt1">
                  {monthNum === '1' ? (
                    <div className="custom-control custom-checkbox">
                      <label
                        htmlFor="autoDebit"
                        className="checkbox-inline mt-0 lighter_font text-nowrap"
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={
                            account.device_type === 'Wave Nano'
                              ? 'true'
                              : autoDebit
                          }
                          id="autoDebit"
                          onChange={(e) => setAutoDebit(e.target.checked)}
                          hidden
                        />
                        {account.device_type === 'Wave Nano' ? null : (
                          <div className="ml-1 custom-control-label autoDebitFont">
                            I want to be charged monthly
                          </div>
                        )}
                      </label>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="font_small font-weight-light  mt-1 text-left ">
              <p>* All payments are conducted on their secure site.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CurrentPlans;

import React from 'react';
import { Link } from 'react-router-dom';

const listenOnNav = (e) => {
  const navs = document.getElementsByClassName('nav-item');
  for (let i = 0; i < navs.length; i++) {
    navs[i].classList.remove('active');
  }
  // console.log(e);
  e.classList.add('active');
};
const BigLink = ({ link, name }) => {
  return (
    <li
      className="row text-white"
      onClick={(e) => {
        listenOnNav(e.target);
      }}
    >
      <Link
        className="text-white font-weight-light text-decoration-none pt-3"
        to={link}
        target="_self"
        style={{ fontSize: '15px' }}
      >
        <img
          src="images/cac.png"
          alt="CAC"
          style={{ width: '1.5rem', height: '1.5rem' }}
          className="pr-1 pb-1"
        />
        {name}
      </Link>
    </li>
  );
};

export default BigLink;

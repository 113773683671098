/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { toast } from 'react-toastify';
import { CREATE_RELOCATION_URL } from '../../services/api/relocation/RelocationURL';

export const useRelocation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState({});
  const getCustomer = (account) => setDetails(account);
  const handleClose = () => setShow(false);
  const handleShow = (ac) => {
    setShow(true);
    getCustomer(ac);
  };
  const createRelocationRequest = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${CREATE_RELOCATION_URL}`,
        qs.stringify(data),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (response.data.data.id) {
        setShow(false);
        handleClose()
        setIsLoading(false);
        toast.success('Request Successfully Submitted!');
      } else {
        toast.info('Request Not Submitted!');
        setIsLoading(false);
      }
    } catch (e) {
      toast.info('Something went wrong!');
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    createRelocationRequest,
    show,
    details,
    handleShow,
    handleClose,
  };
};

/* eslint-disable react/jsx-indent */
import React from 'react';
import Layout from '../layouts';
import ProfileBody from './ProfileBody';

const Profile = () => {
  return (
    <div>
      <Layout>
        <ProfileBody />
      </Layout>
    </div>
  );
};

export default Profile;

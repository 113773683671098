/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserProfile = ({ currentUser, editProfile, load, isSuccess }) => {
  const [profile, setProfile] = useState(currentUser);

  useEffect(() => {
    setProfile(currentUser);
  }, [currentUser]);

  const onChange = (e) => {
    const { name, value } = e.target;

    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const {
    address,
    alternatePhoneNumber,
    firstName,
    email,
    lastName,
    phoneNumber,
    nin,
  } = profile;

  const updateProfile = (user, id) => {
    editProfile(user, id);
  };

  if (currentUser.editStatus) {
    toast.success('Edited Successfully!');
    window.location.reload();
  }

  return (
    <div
      className="tab-pane show active mt-3"
      id="nav-profile"
      role="tabpanel"
      aria-labelledby="nav-profile-tab"
    >
      <ToastContainer />

      <div className="form-horizontal col-md-12 p-0">
        <div className="form-group col-12 col-md-12 d-flex p-0 flex-wrap">
          <div className="col-md-6 col-12 mb-3  pt-1 font-weight-light">
            <label htmlFor="">First Name</label>
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              className="form-control input-sm"
              value={firstName}
              onChange={onChange}
              style={{ fontSize: '15px' }}
            />
          </div>

          <div className="col-md-6 col-12 mb-3 pt-1 font-weight-light">
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="form-control"
              value={lastName}
              onChange={onChange}
              style={{ fontSize: '15px' }}
            />
          </div>
        </div>

        <div className="form-group col-12 col-md-12 d-flex p-0 flex-wrap">
          <div className="col-md-6 col-12 mb-3 pt-1 font-weight-light">
            <label htmlFor="">Phone Number 1</label>
            <input
              type="text"
              placeholder="Phone Number"
              className="form-control"
              value={phoneNumber}
              // readOnly
              style={{ fontSize: '15px' }}
            />
          </div>

          <div className="col-md-6 col-12 mb-3 pt-1 font-weight-light">
            <label htmlFor="">Phone Number 2</label>
            <input
              type="text"
              placeholder="Phone Number 2"
              className="form-control input-sm"
              value={alternatePhoneNumber}
              onChange={onChange}
              name="alternatePhoneNumber"
              // readOnly
              style={{ fontSize: '15px' }}
            />
          </div>
        </div>

        <div className="form-group col-12 col-md-12 d-flex p-0 flex-wrap">
          <div className="col-md-6 col-12 mb-3 pt-1 font-weight-light">
            <label htmlFor="">Email Address</label>
            <input
              type="text"
              placeholder="Email Address"
              className="form-control input-sm"
              value={email}
              disabled={true}
              // readOnly
              style={{ fontSize: '15px' }}
            />
          </div>

          <div className="col-md-6 col-12 mb-3 pt-1 font-weight-light">
            <label htmlFor="">Physical Address</label>
            <input
              type="text"
              placeholder="Physical Address"
              className="form-control input-sm"
              value={address}
              onChange={onChange}
              name="address"
              disabled={true}
              // readOnly
              style={{ fontSize: '15px' }}
            />
          </div>

          <div className="col-md-6 col-12 mb-3 pt-1 font-weight-light">
            <label htmlFor="">National Identification Number (NIN)</label>
            <input
              type="text"
              placeholder="National Identification Number (NIN)"
              className="form-control"
              maxLength="11"
              value={nin}
              onChange={onChange}
              disabled={true}
              name="nin"
              style={{ fontSize: '15px' }}
              // readOnly={currentUser.nin !== null && currentUser.nin !== ''}
            />
          </div>
        </div>

        <div className="form-group col-12">
          {load ? (
            <button
              className="btn w-100 text-white bg-teal-700 hover:bg-gray-500 transition-all duration-150 ease-out"
              // style={{ backgroundColor: '#1E8E8B', fontSize: '15px' }}
              onClick={() => updateProfile(profile, profile.id)}
              disabled={load}
            >
              Updating Profile
            </button>
          ) : (
            <button
              className=" w-100 bg-teal-700 rounded p-2 text-white hover:bg-gray-500 transition-all duration-150 ease-out"
              // style={{ backgroundColor: '#1E8E8B', fontSize: '15px' }}
              onClick={() => updateProfile(profile, profile.id)}

            >
              UPDATE PROFILE
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

UserProfile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentUser: PropTypes.object.isRequired,
  editProfile: PropTypes.func.isRequired,
  load: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
};

export default UserProfile;

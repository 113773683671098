/* eslint-disable camelcase */
import { useReducer } from 'react';
import qs from 'qs';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as types from '../../constants/types/auth/AuthTypes';
import { PricingPlanReducer } from '../../reducers/pricing-plan/PricingPlanReducer';
import { GET_PRICING_PLAN } from '../../services/api/pricing-plan/PricingPlanUrl';

const usePricingPlan = () => {
  const [{ goldPlanLoading }, dispatch] = useReducer(PricingPlanReducer, {
    goldPlanLoading: false,
  });

  const getPricingPlan = async ({
    cin,
    amount,
    name,
    type,
    no_of_months_pc,
    dob,
    address,
    firstname,
    lastname,
    no_of_months_internet,
    item_name_a,
    item_name_b,
    item_name_c,
    item_name_d,
    item_name_e,
    item_price_a,
    item_price_b,
    item_price_c,
    item_price_d,
    item_price_e,
  }) => {
    dispatch({ type: types.START_PRICING_PLAN });
    const data = qs.stringify({
      action: 'generate_paddy_payment_url',
      cin,
      email: `${sessionStorage.getItem('email')}`,
      amount,
      dob,
      address,
      firstname,
      lastname,
      no_of_months_pc,
      no_of_months_internet,
      name,
      type,
      item_name_a,
      item_name_b, 
      item_name_c,
      item_name_d,
      item_name_e,
      item_price_a,
      item_price_b,
      item_price_c,
      item_price_d,
      item_price_e,
    });

    try {
      const response = await axios.post(GET_PRICING_PLAN, data);
      if (response.status === 200) {
        // console.log('debug_res', response.data);
        // console.log(data);
        window.location.href = response.data.url;
      }
    } catch (e) {
      toast.error('Unsuccessful');
      dispatch({ type: types.STOP_PRICING_PLAN });
    }
  };

  return { goldPlanLoading, getPricingPlan };
};

export default usePricingPlan;

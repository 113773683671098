/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable react/button-has-type */
// eslint-disable-next-line react/button-has-type
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UseAccounts } from '../../hooks/accounts/useAccounts';
import { useReferral } from '../../hooks/referral/useReferral';
import { getLoggedInUser } from '../../services/api/referral/ReferralUrl';
import ReferralHeader from './ReferralHeader';

const ReferralBody = () => {
  const { onChange, change, createReferral } = useReferral();
  const { name, phoneNumber, email, address } = change;
  const [customer_name, setCustomer_name] = useState('');

  const [isLoading, setisLoading] = useState(false);
  const [{ accounts }] = UseAccounts();

  const updateCustomer_name = async () => {
    try {
      const response = await getLoggedInUser();

      setCustomer_name(`${response.firstName} ${response.lastName}`);
    } finally {
      //
    }
  };

  const cin = accounts[0]?.customer_ref;

  const onSubmit = async (e) => {
    e.preventDefault();
    setisLoading(true);
    try {
      const data = {
        name,
        phoneNumber,
        customer_name,
        email,
        cin,
        address,
      };
      await createReferral(data);
    } catch (error) {
      throw new Error(error);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-return-await
    (async () => await updateCustomer_name())();
  }, []);

  return (
    <div className="container">
      <ReferralHeader />
      <ToastContainer />
      {/* <Nav /> */}
      <form
        className="form-horizontal mt-2 col-12 col-md-12 p-5 rounded-2xl mb-10 "
        onSubmit={onSubmit}
      >
        <input
          type="hidden"
          name="cin"
          value={cin}
          placeholder="Customer's Identification Number"
          className="form-control"
          onChange={onChange}
        />
        <div className="col-md-11 col-xl-9 mb-4 mx-auto">
          <div className=" shadow  rounded-2xl p-5">
            <div className="card-body">
              <div className="form-group mb-3 font-weight-light">
                <label>Friend's Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  placeholder="Name"
                  className="form-control input-sm"
                  onChange={onChange}
                  style={{ fontSize: '15px' }}
                />
              </div>

              <div className="form-group mb-3 font-weight-light">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label> Friend's Phone Number</label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={phoneNumber}
                  placeholder="Your Phone Number"
                  className="form-control"
                  onChange={onChange}
                  style={{ fontSize: '15px' }}
                />
              </div>

              <div className="form-group mb-3 font-weight-light">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label> Friend's Email </label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Your Email"
                  className="form-control"
                  onChange={onChange}
                  style={{ fontSize: '15px' }}
                />
              </div>

              <div className="form-group mb-3 font-weight-light">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label> Friend's Physical Address </label>
                <input
                  type="text"
                  name="address"
                  value={address}
                  placeholder="Your Address"
                  className="form-control input-sm"
                  onChange={onChange}
                  style={{ fontSize: '15px' }}
                />
              </div>

              <div className="form-group font-weight-light">
                {isLoading ? (
                  <button className="btn btn-grey w-100" disabled={isLoading}>
                    Submitting...
                  </button>
                ) : (
                  <button
                    className="btn w-100 text-white"
                    style={{ backgroundColor: '#1E8E8B', fontSize: '15px' }}
                  >
                    SUBMIT
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>



    </div>
  );
};

export default ReferralBody;

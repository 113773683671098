import React, { useState } from 'react';
import BigScreenLogout from '../../auth/BigScreenLogout';
import { Link } from 'react-router-dom';
import { NavRoutes } from './SidebarNew';

const MobileSidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  return (
    <div className="h-full md:hidden">
      <div
        className={`fixed z-10 top-0 left-0 h-full flex justify-center w-1/2 bg-[#1E8E8B] transform transition-transform ease-in-out ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {/* Your sidebar content goes here */}
        <ul className="md:hidden mt-[100px] space-y-5 flex-col justify-center h-full space-y-3 w-full justify-self-center">
          <li>
            <Link className="mt-3 mx-auto mb-5 max-w-[50px]" to="/">
              <img
                src="images/wificom_logo.png"
                alt="GhanaWifi Logo"
                className="p-2 bg-white rounded-lg object-cover w-12 "
              />
            </Link>
          </li>
          {NavRoutes.map((item) => (
            <li
              key={item.id}
              className="h-7  hover:border-b-4 text-center text-center cursor-pointer  transition-all duration-150  ease-in-out  w-32 md:mx-auto"
            >
              <Link
                className="flex items-center justify-start  text-sm text-decoration-none  text-white "
                to={item.path}
                target="_self"
              >
                <i className={item.icon + ' mr-1'} /> {item.label}
              </Link>
            </li>
          ))}
          <li className="h-7  hover:border-b-4 text-center text-center cursor-pointer  transition-all duration-150  ease-in-out  w-32 md:mx-auto">
            <BigScreenLogout />
          </li>

          
        </ul>
      </div>
      <button
        className={`fixed top-2 left-4 md:hidden z-50 bg-[#1E8E8B] text-white p-2 rounded-lg transition-transform ease-in-out ${
          sidebarOpen ? 'translate-x-3/4' : 'translate-x-0'
        }`}
        onClick={toggleSidebar}
      >
        <i className={`fas ${sidebarOpen ? 'fa-times' : 'fa-bars'}`} />
      </button>
    </div>
  );
};

export default MobileSidebar;

import * as types from '../../constants/types/auth/AuthTypes';

// eslint-disable-next-line import/prefer-default-export
export const ReferralReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case types.START_CREATING_REFERRAL:
      return {
        ...state,
        isLoading: true,
      };

    case types.STOP_CREATING_REFERRAL:
      return {
        ...state,
        isLoading: false,
      };

    case types.REFERRAL_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
      };

    default:
      // return state;
      throw new Error();
  }
};

import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReducer } from 'react';
import { GET_NOMINATION, START_NOMINATION, STOP_NOMINATION } from '../../constants/types/nomination/nominationTypes';
import nominationReducers from '../../reducers/nomination/nominationReducers';

toast.configure()

function useMaleStaff() {
  const [
    {list, item, isLoading }, dispatch,
  ] = useReducer(nominationReducers, {
    list: [],
    item: [],
    isLoading: false,
  });



  const getMaleStaff = async (data) => {
    dispatch({ type: START_NOMINATION });
    try {
      const response = await axios.post(`${'https://legacy.tizeti.com/geniatechapi/index.php'}`, data);
      if (response.data) {
        console.log(response.data.item);
        dispatch({
          type: GET_NOMINATION,
          payload: {
            list: response.data.item,
          },
        });
      }
    } catch (e) {
      toast.info('Could not fetch Names');
      if (e.response) {
        dispatch({ type: STOP_NOMINATION });
      }
    }
  };

  const getMale = async (data) => {
    dispatch({ type: START_NOMINATION });
    try {
      const response = await axios.post(`${'https://legacy.tizeti.com/geniatechapi/index.php'}`, data);
      if (response.data) {
        console.log(response.data.result);
        dispatch({
          type: GET_NOMINATION,
          payload: {
            list: response.data.result,
          },
        });
      }
    } catch (e) {
      toast.info('Could not fetch Names');
      if (e.response) {
        dispatch({ type: STOP_NOMINATION });
      }
    }
  };


  return {
    getMaleStaff,
    getMale,
    item,
    list,
    isLoading,
  };
}

export default useMaleStaff;

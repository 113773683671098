/* eslint-disable react/button-has-type */
// eslint-disable-next-line react/button-has-type
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../../hooks/user/useUser';
import { useProfile } from '../../hooks/profile/useProfile';

const ChangePassword = () => {
  const { onChangePassword, change, changePassword, isLoading } = useProfile();
  const [{ user }] = useUser();
  const { confirmPassword, newPassword, password } = change;
  const { email } = user;

  const onSubmit = (e) => {
    e.preventDefault();

    if (change.newPassword !== change.confirmPassword) {
      // toast message here to let users know passwords dont match
      toast.info('Passwords do not match');
    } else {
      const data = {
        new_password: newPassword,
        confirm_password: confirmPassword,
        old_password: password,
        email,
      };
      changePassword(data);
    }
  };

  const submitForm = () => {
    return (
      password.length > 0 &&
      newPassword.length > 0 &&
      confirmPassword.length > 0
    );
  };

  const isEnabled = submitForm();

  return (
    <div
      className="tab-pane mt-3"
      id="nav-password"
      role="tabpanel"
      aria-labelledby="nav-password-tab"
    >
      <ToastContainer />
      <form
        className="form-horizontal mt-2 col-12 col-md-12"
        onSubmit={onSubmit}
      >
        <div className="form-group mb-3 pt-1 font-weight-light">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>Enter Existing password</label>
          <input
            type="password"
            name="password"
            value={password}
            placeholder="Existing password"
            className="form-control"
            onChange={onChangePassword}
            style={{ fontSize: '15px' }}
          />
        </div>

        <div className="form-group mb-3 pt-1 font-weight-light">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>New password</label>
          <input
            type="password"
            name="newPassword"
            value={newPassword}
            placeholder="New password"
            className="form-control"
            onChange={onChangePassword}
            style={{ fontSize: '15px' }}
          />
        </div>

        <div className="form-group mb-3 pt-1 font-weight-light">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="">Re-enter New password</label>
          <input
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            placeholder="Re-enter password"
            className="form-control"
            onChange={onChangePassword}
            style={{ fontSize: '15px' }}
          />
        </div>

        <div className="form-group pt-1 font-weight-light">
          {isLoading ? (
            <button
              className="btn w-100 text-white"
              style={{ backgroundColor: '#1E8E8B', fontSize: '15px' }}
              disabled={isLoading}
            >
              Changing...
            </button>
          ) : (
            <button
              className="btn w-100 text-white"
              style={{ backgroundColor: '#1E8E8B', fontSize: '15px' }}
              disabled={!isEnabled}
            >
              CHANGE PASSWORD
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;

/* eslint-disable react/jsx-indent */
import React from 'react';
import ReferralBody from './ReferralBody';
import Layout from '../layouts';

const Referral = () => {
  return (
    <div className="row " >
      <Layout>
        <ReferralBody />
      </Layout>
    </div>
  );
};

export default Referral;

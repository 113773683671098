/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import FaqContent from './FaqContent';
import FaqHeader from './FaqHeader';
import FaqVideo from './FaqVideo';

function FaqBody() {
  return (
    <div className="container">
    {/* // <div className="" style={{ backgroundColor: '#F5F7F7' }}> */}
      <FaqHeader />

      <div className="row">
        <div className="col-sm-6">
          <FaqContent />
        </div>
        <div className="col-sm-6">
          <FaqVideo />
        </div>
      </div>
    </div>
  );
}

export default FaqBody;

import * as types from '../../constants/types/auth/AuthTypes';

// eslint-disable-next-line import/prefer-default-export
export const PricingPlanReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case types.START_PRICING_PLAN:
      return {
        ...state,
        goldPlanLoading: true,
      };

    case types.STOP_PRICING_PLAN:
      return {
        ...state,
        goldPlanLoading: false,
      };

    default:
      // return state;
      throw new Error();
  }
};

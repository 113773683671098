import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const unauthenticatedNavStyles = {
  warning: {
    textAlign: 'center',
    color: 'red',
    marginTop: '1em',
    fontSize: '1.2em',
  },
};
const UnauthenticatedNav = ({ message }) => {
  return (
    <span>
      <nav className="navbar navbar-light d-flex d-md-none">
        <Link to="/" className="navbar-brand">
          <img src="images/wificom_logo.png" alt=" Logo" />
        </Link>

        <div className="dropdown">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent1"
            aria-controls="navbarSupportedContent1"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars" />
          </button>

          <div
            className="navbar-collapse collapse dd pt-4"
            id="navbarSupportedContent1"
          >
            <Link target="_self" to="/" className="dropdown-item">
              Overview
            </Link>

            {/* <BigLink target="_self" to="/fund-wallet" className="dropdown-item">
        Fund Wallet
        </BigLink> */}
          </div>
        </div>
      </nav>

      <nav className="navbar navbar-expand-lg navbar-light bg-ligh d-none d-md-flex">
        <Link className="navbar-brand mr-5" to="/">
          <img src="images/wificom_logo.png" alt=" Logo" />
        </Link>
      </nav>
      <p style={unauthenticatedNavStyles.warning}>{message}</p>

      <br />
    </span>
  );
};

UnauthenticatedNav.propTypes = {
  // eslint-disable-next-line react/require-default-props
  message: PropTypes.string,
};
export default UnauthenticatedNav;

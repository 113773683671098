import React from 'react';

function FaqContent() {
  return (
    <div className="">
      <div
        className="border-0 rounded-2xl ml-3 mt-5 mb-52 mr-3"
        style={{ width: '30rem', backgroundColor: '#F5F7F7' }}
      >
        <div className="justify-content-left">
          <div
            className=" mt-0 mb-5 p-5 accordion md-accordion"
            id="accordionEx"
            role="tablist"
            aria-multiselectable="true"
          >
            <div className="">
              <div className="" role="tab" id="headingOne1">
                <div className="card-body">
                  {/* Anim pariatur cliche reprehenderit, enim eiusmod high life
        accusamus terry richardson ad squid. 3 wolf moon officia aute,
        non cupidatat skateboard dolor brunch. Food truck quinoa
        nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua
        put a bird on it squid single-origin coffee nulla assumenda
        shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
        wes anderson cred nesciunt sapiente ea proident. Ad vegan
        excepteur butcher vice lomo. Leggings occaecat craft beer
        farm-to-table, raw denim aesthetic synth nesciunt you probably
        haven't heard of them accusamus labore sustainable VHS. */}
                </div>
              </div>
            </div>

            <div className="">
              <div className="" role="tab" id="headingTwo2">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTwo2"
                  aria-expanded="false"
                  aria-controls="collapseTwo2"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I want to make payments
                  </h6>
                </a>
              </div>

              <div
                id="collapseTwo2"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingTwo2"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p className="link_line">
                    You can make payments via
                    <br />
                    <a
                      href="https://pay.tizeti.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://pay.tizeti.com
                    </a>
                    {/* <br />
                    <a
                      href="https://quickteller.com/tizeti"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://quickteller.com/tizeti
                    </a> */}
                    <br />
                    <a
                      href="https://dashboard.tizeti.com/#/login"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://dashboard.tizeti.com
                    </a>
                    <br />
                    <a
                      href="https://www.tizeti.com/customer/login.php"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.tizeti.com/customer/login.php
                    </a>
                    <br />
                    <br />
                    OR
                    <br />
                    <br />
                    <p>
                      You can make a direct transfer to our Zenith Bank Account:
                      <br />
                      Account Name: Tizeti Network Limited
                      <br />
                      Account No:1013184381
                      <br />
                      <br />
                      Kindly forward evidence of payment to
                      <a href="mailto:internet@wifi.com.ng" className="mx-1">
                        internet@wifi.com.ng
                      </a>
                      for a swift follow up.
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-2 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingThree3">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseThree3"
                  aria-expanded="false"
                  aria-controls="collapseThree3"
                >
                  <h6 className="ml-2 text-dark " style={{ fontSize: '14px' }}>
                    I want to know about your packages
                  </h6>
                </a>
              </div>

              <div
                id="collapseThree3"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingThree3"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>We offer residential, office and enterprise plans</p>
                </div>
              </div>
            </div>
            <hr className="mt-2 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingFour4">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseFour4"
                  aria-expanded="false"
                  aria-controls="collapseFour4"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    How do I upgrade my plan?
                  </h6>
                </a>
              </div>

              <div
                id="collapseFour4"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingFour4"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>Kindly click to have a team call you</p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingFive5">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseFive5"
                  aria-expanded="false"
                  aria-controls="collapseFive5"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I want to relocate my device.
                  </h6>
                </a>
              </div>

              <div
                id="collapseFive5"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingFive5"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>
                    Kindly make a payment of 23,000 Naira for residential and
                    send the receipt to internet @wifi.com.ng
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingSix6">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseSix6"
                  aria-expanded="false"
                  aria-controls="collapseSix6"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I do not have my Customer Account Number
                  </h6>
                </a>
              </div>

              <div
                id="collapseSix6"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingSix6"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>Kindly check your customer portal and click on overview</p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingSeven7">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseSeven7"
                  aria-expanded="false"
                  aria-controls="collapseSeven7"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I need the receipt / invoice of my last payment
                  </h6>
                </a>
              </div>

              <div
                id="collapseSeven7"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingSeven7"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>Kindly download the invoice on your customer portal</p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingEight8">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseEight8"
                  aria-expanded="false"
                  aria-controls="collapseEight8"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I need the daily usage on my account
                  </h6>
                </a>
              </div>

              <div
                id="collapseEight8"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingEight8"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>Your daily usage is on your customer portal</p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingNine9">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseNine9"
                  aria-expanded="false"
                  aria-controls="collapseNine9"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I want to change my password
                  </h6>
                </a>
              </div>

              <div
                id="collapseNine9"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingNine9"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>
                    Kindly select a preferred appointment date on your customer
                    portal and our field support team will be at your location
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingTen10">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTen10"
                  aria-expanded="false"
                  aria-controls="collapseTen10"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I want to purchase a new device/ referral
                  </h6>
                </a>
              </div>

              <div
                id="collapseTen10"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingTen10"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>Kindly click to have a team call you</p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingEleven11">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseEleven11"
                  aria-expanded="false"
                  aria-controls="collapseEleven11"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I need a static IP
                  </h6>
                </a>
              </div>

              <div
                id="collapseEleven11"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingEleven11"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>
                    Kindly make a payment of 6,000 Naira for residential and
                    send the receipt to internet @wifi.com.ng
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingTwelve12">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTwelve12"
                  aria-expanded="false"
                  aria-controls="collapseTwelve12"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I want to change my ssid/username
                  </h6>
                </a>
              </div>

              <div
                id="collapseTwelve12"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingTwelve12"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>
                    Kindly select a preferred appointment date on your customer
                    portal.
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingThirteen13">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseThirteen13"
                  aria-expanded="false"
                  aria-controls="collapseThirteen13"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    May I know the areas/states covered by Tizeti
                  </h6>
                </a>
              </div>

              <div
                id="collapseThirteen13"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingThirteen13"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  We have coverage in Lagos, Abeokuta, Port Harcourt, Ibadan, Benin and Ghana
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingFourteen14">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseFourteen14"
                  aria-expanded="false"
                  aria-controls="collapseFourteen14"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    Is the teleport service still avaialble?
                  </h6>
                </a>
              </div>

              <div
                id="collapseFourteen14"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingFourteen14"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>No, we no longer offer the service</p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingFifteen15">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseFifteen15"
                  aria-expanded="false"
                  aria-controls="collapseFifteen15"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    May I get the zenith bank account number?
                  </h6>
                </a>
              </div>

              <div
                id="collapseFifteen15"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingFifteen15"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>1013184381</p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingSixteen16">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseSixteen16"
                  aria-expanded="false"
                  aria-controls="collapseSixteen16"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    What is my next expiration date?
                  </h6>
                </a>
              </div>

              <div
                id="collapseSixteen16"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingSixteen16"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>Your expiration plan is stated on your customer portal</p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingSeventeen17">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseSeventeen17"
                  aria-expanded="false"
                  aria-controls="collapseSeventeen17"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I will like to access my payment history
                  </h6>
                </a>
              </div>

              <div
                id="collapseSeventeen17"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingSeventeen17"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>Kindly check your customer portal</p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingEighteen18">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseEighteen18"
                  aria-expanded="false"
                  aria-controls="collapseEighteen18"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I will like to purchase a booster/poe/router
                  </h6>
                </a>
              </div>

              <div
                id="collapseEighteen18"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingEighteen18"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>
                    Kindly click on the shop on your customer portal to make
                    your purchase
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingNineteen19">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseNineteen19"
                  aria-expanded="false"
                  aria-controls="collapseNineteen19"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I will like to change the position of my router in my
                    location
                  </h6>
                </a>
              </div>

              <div
                id="collapseNineteen19"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingNineteen19"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>
                    Kindly select a preferred appointment date on your customer
                    portal and our field support team will be at your location
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingTwenty20">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTwenty20"
                  aria-expanded="false"
                  aria-controls="collapseTwenty20"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I need my router password?
                  </h6>
                </a>
              </div>

              <div
                id="collapseTwenty20"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingTwenty20"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>Kindy access your password on your customer portal</p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />

            <div className="">
              <div className="" role="tab" id="headingTwentyOne21">
                <a
                  className="collapsed text-decoration-none mt-0"
                  data-toggle="collapse"
                  data-parent="#accordionEx"
                  href="#collapseTwentyOne21"
                  aria-expanded="false"
                  aria-controls="collapseTwentyOne21"
                >
                  <h6 className="ml-2 text-dark" style={{ fontSize: '14px' }}>
                    I will like to know the number of devices connected to my
                    internet
                  </h6>
                </a>
              </div>

              <div
                id="collapseTwentyOne21"
                className="collapse"
                role="tabpanel"
                aria-labelledby="headingTwentyOne21"
                data-parent="#accordionEx"
              >
                <div
                  className="card-body font-weight-light"
                  style={{ fontSize: '15px' }}
                >
                  <p>
                    The number of devices connected to your router is stated on
                    your customer portal
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2 ml-2 mr-2" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqContent;

/* eslint-disable import/prefer-default-export */
import {
  FAILURE_EDITING_PROFILE,
  FETCH_USER_PLANS_FAILURE,
  FETCH_USER_PLANS_STARTS,
  FETCHED_USER,
  FETCHED_USER_PLANS,
  // PROFILE_EDIT_SUCCESS,
  START_EDITING_PROFILE, STOP_FETCHING_USER,
} from '../../constants/types/user/UserTypes';

export const userReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USER_PLANS_STARTS:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case FETCHED_USER_PLANS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        userPlans: payload,
      };

    case FETCH_USER_PLANS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case FETCHED_USER:
      return {
        ...state,
        user: payload,
        isLoading: false,
        isError: false,
        isSuccess: true,
      };

    case START_EDITING_PROFILE:
      return {
        ...state,
        isLoading: payload,
      };

    case FAILURE_EDITING_PROFILE:
      return {
        ...state,
        isLoading: false,
      };

    case STOP_FETCHING_USER:
      return {
        ...state,
      };

    default:
      throw new Error();
  }
};

/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

function FaqVideo() {
  return (
    <div className="row">
      <div
        className="border-0 rounded-2xl ml-3 mt-5 mb-52 mr-3"
        style={{ width: '30rem', backgroundColor: '#F5F7F7' }}
      >
        <div className=" mt-3 p-5 ">
          <p className="">Internet Troubleshooting Steps</p>
          <video width="100%" height="240" controls>
            <source src="movie.mp4" type="video/mp4" />
            <source
              src=" https://dashboard.tizeti.com/tizeti_troubleshhoting_tips.mp4"
              type="video/ogg"
            />
            Your browser does not support the video tag.
          </video>
          <p className="mt-3">Troubleshooting.mp4</p>
          <p className="font_small font-weight-light">34.5MB</p>
          <hr className="mt-2 mb-2" />
          <p
            className="mt-3 mb-3"
            style={{ fontSize: '13px' }}
          >
            Created 17, May 2020
          </p>
          <hr className="mt-2 mb-2" />
          <p className="mt-3 mb-3">Description</p>
          <p className="font_small font-weight-light">
            Video to show how to troubleshoot internet connection problems
          </p>
        </div>
      </div>
    </div>
  );
}

export default FaqVideo;

/* eslint-disable import/prefer-default-export */
import {
  STOP_FETCHING_PAYMENT_HISTORY,
  START_FETCHING_PAYMENT_HISTORY,
  FETCHED_PAYMENT_HISTORY,
} from '../../constants/types/accounts/AccountsTypes';

export const paymentHistoryReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case START_FETCHING_PAYMENT_HISTORY:
      return {
        ...state,
        isLoading: true,
      };

    case STOP_FETCHING_PAYMENT_HISTORY:
      return {
        ...state,
        isLoading: false,
      };

    case FETCHED_PAYMENT_HISTORY:
      return {
        ...state,
        paymentsHistory: payload,
        isLoading: false,
      };

    default:
      // return state;
      throw new Error();
  }
};

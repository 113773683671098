/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

const Store_Item = ({ image_url, item_description, payment_url }) => {
  return (
    <div className="border-1 rounded-2xl border-grey-200 p-3  flex flex-col text-left  hover:border-black transition-all duration-150 ease-out">
      <img
        className="p-2 rounded-2xl"
        style={{ width: 'auto', height: '10rem' }}
        src={image_url}
      />
      <div className="p-2 text-sm text-teal-900 p-3">{item_description}</div>
      <a
        className="text-decoration-none text-dark p-3"
        href={`mailto:sales@tizeti.com?subject=${item_description}&body=I want to purchase ${item_description}`}
      >
        <button className="bg-teal-700 text-white rounded p-3 w-full  hover:border-gray-500 transition-all duration-150 ease-out">
          BUY
        </button>
      </a>
    </div>
  );
};

export default Store_Item;

/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from 'react';
import loader from './TizetiLoaderColored.gif';

export const TizetiGIFLogoTable = ({ colSpan }) => {
  return (
    <tr className="text-center">
      <td colSpan={colSpan}>
        <img src={loader} alt="Loading..." />
      </td>
    </tr>
  );
};

export const NoItemRow = ({ colSpan, text }) => {
  return (
    <tr>
      <td colSpan={colSpan} className="text-center">
        {text}
      </td>
    </tr>
  );
};

import React from 'react';
import UserProfile from './UserProfile';
import ChangePassword from './ChangePassword';
import { useUser } from '../../hooks/user/useUser';
import ProfileHeader from './ProfileHeader';

const ProfileBody = () => {
  const [{ user, isLoading, isSuccess }, editProfile] = useUser();

  const updateProfile = async (profile, id) => {
    await editProfile(profile, id);
  };

  return (
    <span >
      <ProfileHeader />
      <hr className="mt-0 mb-3 d-flex d-md-none container" />
      <div className="p-4 rounded-2xl border-1 border-gray-200 flex flex-col justify-between space-y-1  container">
        <div className="container" id="content">
          <div className="row">
            <div className="col-12 col-md-10 m-auto offset-md-3">
              <div className="section_tab">
                <nav className="col-md-12">
                  <div
                    className="nav nav-tabs nav-fill"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      className="nav-item nav-link active text-center pt-1 font-weight-light-bold"
                      id="nav-profile-tab"
                      data-toggle="tab"
                      href="#nav-profile"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="true"
                      style={{ fontSize: '15px' }}
                    >
                      Profile Details
                    </a>
                    <a
                      className="nav-item nav-link text-center pt-1 font-weight-light-bold"
                      id="nav-password-tab"
                      data-toggle="tab"
                      href="#nav-password"
                      role="tab"
                      aria-controls="nav-password"
                      aria-selected="false"
                      style={{ fontSize: '15px' }}
                    >
                      Change Password
                    </a>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  <UserProfile
                    currentUser={user}
                    editProfile={updateProfile}
                    load={isLoading}
                    success={isSuccess}
                  />
                  <ChangePassword />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

export default ProfileBody;

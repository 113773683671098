export const FETCH_STARTS = 'FETCH_STARTS';
export const FETCHED_ACCOUNTS = 'FETCHED_ACCOUNTS';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const START_FETCHING_USAGE = 'START_FETCHING_USAGE';
export const FETCHED_USAGE = 'FETCHED_USAGE';
export const STOP_FETCHING_USAGE = 'STOP_FETCHING_USAGE';
export const GET_EXPIRY_DATE = 'GET_EXPIRY_DATE';
export const FETCH_USER_PLAN = 'FETCH_USER_PLAN';
export const FETCH_USER_PLAN_STARTS = 'FETCH_USER_PLAN_STARTS';
export const FETCH_USER_PLAN_STOPS = 'FETCH_USER_PLAN_STOPS';
export const FETCHED_DETAIL = 'FETCHED_DETAIL';
export const START_FETCHING_PAYMENT_HISTORY = 'START_FETCHING_PAYMENT_HISTORY';
export const STOP_FETCHING_PAYMENT_HISTORY = 'STOP_FETCHING_PAYMENT_HISTORY';
export const FETCHED_PAYMENT_HISTORY = 'FETCHED_PAYMENT_HISTORY';
export const FETCHED_THREE_MONTH_USAGE = 'FETCHED_THREE_MONTH_USAGE';
export const RELOCATION_REQUEST = 'RELOCATION_REQUEST';

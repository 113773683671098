/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify';
import axios from 'axios';
import * as keys from '../../services/config/Keys';
import Helpers from '../../utils/Helpers';

export const UseSubscribe = () => {
  const subscribeForPlanWithPaystack = (monthNum, itemprice, user) => {
    const { firstName, lastName, phoneNumber, email, state, id } = user;

    //  console.log("subscribeForPlaWithPaystack called email", email.replace(/\s/g, ""));


    const spaceless_email = email.replace(/\s/g, '');
    const my_amount = Helpers.formattedPaystackSubscriptionAmount(
      monthNum,
      itemprice,
    );
    if (
      spaceless_email !== null &&
      spaceless_email !== '' &&
      my_amount >= 1250000
    ) {
      const handler = window.PaystackPop.setup({
        key: `${keys.PAYSTACK_PK_LIVE}`,
        email: spaceless_email,
        amount: my_amount,
        currency: 'NGN',
        ref: Helpers.generateReferenceForPaystackPayment(),
        metadata: {
          custom_fields: [
            {
              email: spaceless_email,
              firstname: firstName,
              lastname: lastName,
              month: monthNum,
              userId: id,
              state: state,
              payment_type: 'subscription',
              amount: Helpers.formattedPaystackSubscriptionAmount(
                monthNum,
                itemprice,
              ),
              phoneNumber,
            },
          ],
        },
        callback(callbackResponse) {
          if (callbackResponse.status === 'success') {
            const options = {
              method: 'GET',

              url: `${keys.VERIFICATION_URL}${callbackResponse.reference}`,
            };
            axios(options).then((verifyResponse) => {
              if (
                verifyResponse.data.data.status === 'success' &&
                verifyResponse.data.data.domain === 'live'
              ) {
                toast.success('Payment Successful!');
              }
            });
          } else {
            toast.warn('Payment not successful!');
          }
        },
        onClose() {},
      });
      handler.openIframe();
    } else {
      toast.warn('Error, Please reload page and try again');
      // console.log('spaceless ', spaceless_email);
    }
  };

  const subscribeForRecurringPlanWithPaystack = (user, monthNum) => {
    const { firstName, lastName, phoneNumber, email, state, id } = user;

    const spaceless_email = email.replace(/\s/g, '');
    if (spaceless_email !== null && spaceless_email !== '') {
      const handler = window.PaystackPop.setup({
        key: `${keys.PAYSTACK_PK_LIVE}`,
        email,
        currency: 'NGN',
        plan: `${keys.PAYSTACK_12500_PLAN}`,
        channels: ['card'],
        firstname: firstName,
        lastname: lastName,
        ref: Helpers.generateReferenceForPaystackPayment(),
        metadata: {
          custom_fields: [
            {
              email,
              firstname: firstName,
              lastname: lastName,
              month: monthNum,
              userId: id,
              state: state,
              payment_type: 'subscription',
              phoneNumber,
            },
          ],
        },

        callback(callbackResponse) {
          if (callbackResponse.status === 'success') {
            const verifyOptions = {
              method: 'GET',

              url: `${keys.VERIFICATION_URL}${callbackResponse.reference}`,
            };
            axios(verifyOptions).then((verifyResponse) => {
              if (
                verifyResponse.data.data.status === 'success' &&
                verifyResponse.data.data.domain === 'live'
              ) {
                toast.success('Payment Successful!');
              }
            });
          } else {
            toast.warn('Payment not successful!');
          }
        },
        onClose() {},
      });
      handler.openIframe();
    } else {
      toast.warn('Error, Please reload page and try again');
    }
  };

  return {
    subscribeForPlanWithPaystack,
    subscribeForRecurringPlanWithPaystack,
  };
};

import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useKyc } from '../../hooks/user/kyc/useKyc';

export const KycContext = createContext({});
export const KycProvider = ({ children }) => {
  const {
    uploadFile,
    load,
    onChange,
    view,
    fileInput,
    kycs,
    uploadFileForCac,
  } = useKyc();
  return (
    <KycContext.Provider
      value={{
        uploadFile,
        load,
        onChange,
        view,
        fileInput,
        kycs,
        uploadFileForCac,
      }}
    >
      {children}
    </KycContext.Provider>
  );
};
KycProvider.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.shape(),
};

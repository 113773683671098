import React, { useEffect, useState } from 'react';
import { UseAccounts } from '../../hooks/accounts/useAccounts';
import { useUser } from '../../hooks/user/useUser';
import CurrentPlans from './payment-optionss/CurrentPlans';
import FirstPaymentRow from './payment-optionss/FirstPaymentRow';
import SubscribeHeader from './SubscribeHeader';

const SubscribeBody = () => {
  const [{ isLoading, user }] = useUser();

  const [{ itemPrices, accounts }] = UseAccounts();

  const [monthNum, setMonthNum] = useState('3');
  const [autoDebit, setAutoDebit] = useState(false);

  const userState = user.accounts;

  // // console.log('userState', userState);
  // console.log('accounts', accounts);

  const dataWithDeviceType = accounts.map((item) => {
    const user = userState.find((acc) => acc.mac_id === item.username);

    return {
      ...item,

      device_type: user ? user.device_type : null,
    };
  });

  // const formattedAccounts = accounts.map((account) => {
  //   const data = userState?.find((user) => user?.mac_id === account?.username);

  //   return {
  //     ...account,
  //     ...data,
  //   };
  // });
// console.log(dataWithDeviceType.length);
  return (
    <div className="container flex flex-col space-y-3" id="">
      <SubscribeHeader />

      {dataWithDeviceType.length === 0 && (
        <div className="flex m-auto h-[100vh] items-center justify-center">
          <img alt="loading..." src="images/TizetiLoaderColored.gif" />
        </div>
      )}

      {dataWithDeviceType.map((account, index) => (
        // steps: 1. since i have accss to the individual account of the customer,
        // geeet the username of this particular account
        // step 3. makee an api call to get_item_price with this uussername and store in a local variable

        <section
          key={account}
          className=" grid  grid-cols-1 md:grid-cols-2 gap-4  p-3"
        >
          <CurrentPlans
            key={`plans_${itemPrices[index]}`}
            isLoading={isLoading}
            itemprice={itemPrices[index]}
            account={account}
            monthNum={monthNum}
            setMonthNum={setMonthNum}
            autoDebit={autoDebit}
            setAutoDebit={setAutoDebit}
          />

          <div className="p-4 rounded-2xl border-1 border-gray-200 flex flex-col justify-between space-y-1">
            <FirstPaymentRow
              key={`payments_${itemPrices[index]}`}
              user={user}
              itemprice={itemPrices[index]}
              autoDebit={autoDebit}
              setAutoDebit={setAutoDebit}
              monthNum={monthNum}
              account={account}
            />
          </div>
        </section>
      ))}
    </div>
  );
};

export default SubscribeBody;

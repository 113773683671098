import React from 'react';
import { KycProvider } from '../../context/kyc/KycContext';
import CACHeader from './CACHeader';
import FilePreview from './kyc/FilePreview';
import UploadFile from './kyc/UploadFile';
// import UploadFile from './UploadFile';
// import { KycProvider } from '../../../context/kyc/KycContext';
// import KycHeader from './KycHeader';
// import FilePreview from './FilePreview';

const CACBody = () => {
  return (
    // <div className="" style={{ backgroundColor: '#F5F7F7' }}>
    <div>
      <KycProvider>
        <CACHeader />
        <span>
          <div className="row">
            <div className="col-sm-6 justify-content-center">
              <FilePreview />
            </div>
            <div className="col-sm-6 justify-content-center">
              <div className="border-0 ml-5 mb-5">
                <UploadFile w="8" />
              </div>
            </div>
          </div>
        </span>
      </KycProvider>
    </div>
  );
};

export default CACBody;

import { useState, createRef, useEffect, useReducer } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../../../services/api/BaseUrl';
import { kycReducer } from '../../../reducers/user/kyc/kycReducer';

export const useKyc = () => {
  const [load, setLoad] = useState(false);
  const fileInput = createRef();
  const [file, setFile] = useState(null);
  const [view, setView] = useState(null);
  const [{ kycs }, dispatch] = useReducer(kycReducer, { kycs: [] });

  const onChange = (e) => {
    const { files } = e.target;

    const filesArray = Array.from(e.target.files);
    if (files.length > 1) {
      setFile(e.target.files);
      setView(filesArray.map((fil) => URL.createObjectURL(fil)));
    } else {
      setFile(files);
      setView(URL.createObjectURL(files[0]));
    }
  };

  const uploadFileForCac = async () => {
    setLoad(true);
    const data = new FormData();
    data.append('file', file);
    try {
      const response = await axios.post(
        `${API_URL}/userKycUpload/upload`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (response.data.id) {
        setFile(null);
        toast.success('File Uploaded Successfully!');
        setLoad(false);
        // dispatch({ type: 'GET_USER_KYCS', payload: [response.data, ...kycs] });
        setView(null);
      } else {
        toast.error('Unable to upload file');
      }
    } catch (e) {
      setLoad(false);
      if (
        e.response.data.message ===
        "That fileType isn't allowed, only image files allowed!!"
      ) {
        toast.error('That file type is not allowed!!');
      } else {
        toast.error('Something went wrong');
      }
    }
  };
  const uploadFile = async () => {
    setLoad(true);
    const data = new FormData();
    // data.append('photo', file);
    // try {
    //   const response = await axios.post(`${API_URL}/userProfile`,
    for (let x = 0; x < file.length; x++) {
      data.append('file', file[x]);
    }
    try {
      const response = await axios.post(
        `${API_URL}/userKycUpload/upload`,

        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        },
      );
      if (response.data.id) {
        setFile(null);
        toast.success('File Uploaded Successfully!');
        setLoad(false);
        dispatch({ type: 'GET_USER_KYCS', payload: [response.data, ...kycs] });
        setView(null);
      } else {
        toast.error('Unable to upload file');
      }
    } catch (e) {
      setLoad(false);
      if (e.response.data.message === "That fileType isn't allowed!!") {
        toast.error('That file type is not allowed!!');
      } else {
        toast.error('Something went wrong');
      }
    }
  };
  async function getUserKycs() {
    try {
      return axios.get(`${API_URL}/userProfile`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
    } catch (e) {
      return e;
    }
  }

  useEffect(() => {
    getUserKycs()
      .then((data) => {
        if (data.data.rows) {
          dispatch({ type: 'GET_USER_KYCS', payload: data.data.rows });
        }
      })
      .catch((err) => {});
  }, [dispatch]);
  return {
    uploadFile,
    load,
    onChange,
    view,
    fileInput,
    kycs,
    uploadFileForCac,
  };
};

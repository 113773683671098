// Modal.js
import React from 'react';
import { Link } from 'react-router-dom';

const Modal = ({ onClose, children }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg max-w-lg w-full shadow-lg relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-2xl font-semibold text-gray-600 hover:text-gray-800"
        >
          &times;
        </button>

        <div className="text-center">
          {children}
          <Link
            to="/kyc" // Make sure this points to the correct KYC page
            className="mt-6 inline-block px-6 py-3 bg-[#00c1ac] text-white font-semibold rounded-lg hover:bg-[#00a999] transition duration-300"
          >
            Go to KYC Page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Modal;

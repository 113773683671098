export const FETCH_USER_PLANS_STARTS = 'FETCH_USER_PLANS_STARTS';
export const FETCHED_USER_PLANS = 'FETCHED_USER_PLANS';
export const FETCH_USER_PLANS_FAILURE = 'FETCH_USER_PLANS_FAILURE';
export const STOP_FETCHING_USER = 'STOP_FETCHING_USER';

export const FETCHED_USER = 'FETCHED_USER';
export const START_EDITING_PROFILE = 'START_EDITING_PROFILE';
export const FAILURE_EDITING_PROFILE = 'FAILURE_EDITING_PROFILE';
export const PROFILE_EDIT_SUCCESS = 'PROFILE_EDIT_SUCCESS';


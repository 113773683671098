/* eslint-disable react/jsx-indent */
import React from 'react';
import Layout from '../layouts';
import CACBody from './CACBody';
import KycBody from './kyc/KycBody';

const CAC = () => {
  return (
    <div>
      <Layout>
        <CACBody />
      </Layout>
    </div>
  );
};

export default CAC;

/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import React from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAuth } from '../../hooks/auth/useAuth';
import Footer from '../layouts/Footer';

function ForgotPassword() {
  // const onSubmit = e => {
  //   e.preventDefault();
  //   action(formData)
  //     .then(() => {
  //       props.history.push("/");
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  const { forgot, isLoading, forgotPassword, onForgotChange } = useAuth();

  // to do for chibuzor
  //           1. type err and succ in full
  // 2. clear logs

  const submitForm = () => {
    const { email } = forgot;
    return email.length > 0;
  };

  const isEnabled = submitForm();

  const displayButton = () => {
    switch (isLoading) {
      case true:
        return (
          <button
            className="btn btn-grey w-50"
            onClick={() => forgotPassword(forgot)}
            disabled={isLoading}
          >
            Sending Email...
          </button>
        );
      case false:
        return (
          <button
            className="btn btn-grey w-50"
            onClick={() => forgotPassword(forgot)}
            disabled={!isEnabled}
          >
            Send Email
          </button>
        );
      default:
        return null;
    }
  };

  const { email } = forgot;

  return (
    <span>
      <div className="container">
        <div className="row mt-5">
          <div
            className="col-12 text-center col-md-5 ml-auto mr-auto"
            style={{ marginTop: '10%' }}
          >
            <div className="ghana-logo">
              <img src="images/wificom_logo.png" alt="" />
            </div>
            <form>
              <div className="form-horizontal col-md-12">
                <div className="form-group text-left">
                  <label htmlFor="email" className="welcome-back">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    name="email"
                    id="email"
                    onChange={onForgotChange}
                    className="form-control"
                    placeholder="Email Address"
                  />
                </div>
              </div>
              <div className="form-group">{displayButton()}</div>
            </form>
            <div className="forget-pwd">
              <p className="welcome-back">
                Remember your login details?
                <span>
                  <Link to="/login"> Login</Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </span>
  );
}

export default ForgotPassword;

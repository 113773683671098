import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReducer } from 'react';
import { GET_NOMINATION, START_NOMINATION, STOP_NOMINATION } from '../../constants/types/nomination/nominationTypes';
import nominationReducers from '../../reducers/nomination/nominationReducers';
import nomFemaleReducers from '../../reducers/nomination/nomFemaleReducers';

toast.configure()

function useFemaleStaff() {
  const [
    { item, list, isLoading }, dispatch,
  ] = useReducer(nomFemaleReducers, {
    item: [],
    list: [],
    isLoading: false,
  });


  const getFemaleStaff = async (data) => {
    dispatch({ type: START_NOMINATION });
    try {
      const response = await axios.post(`${'https://legacy.tizeti.com/geniatechapi/index.php'}`, data);
      if (response.data) {
        // console.log(response.data.item);
        dispatch({
          type: GET_NOMINATION,
          payload: {
            item: response.data.item,
          },
        });
      }
    } catch (e) {
      toast.info('Could not fetch Female Names');
      if (e.response) {
        dispatch({ type: STOP_NOMINATION });
      }
    }
  };

  const getFemale = async (data) => {
    dispatch({ type: START_NOMINATION });
    try {
      const response = await axios.post(`${'https://legacy.tizeti.com/geniatechapi/index.php'}`, data);
      if (response.data) {
        // console.log(response.data.item);
        dispatch({
          type: GET_NOMINATION,
          payload: {
            item: response.data.result,
          },
        });
      }
    } catch (e) {
      toast.info('Could not fetch ');
      if (e.response) {
        dispatch({ type: STOP_NOMINATION });
      }
    }
  };
  return {
    getFemaleStaff,
    getFemale,
    list,
    item,
    isLoading,
  };
}

export default useFemaleStaff;

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Link, withRouter } from 'react-router-dom';
import Footer from '../layouts/Footer';
import { useAuth } from '../../hooks/auth/useAuth';
import './index.css';

const Login = (props) => {
  const {
    login,
    isLoading,
    token,
    onLoginChange,
    loginUser,
  } = useAuth();

  const submitForm = () => {
    const { email, password } = login;

    return email.length > 0 && password.length > 0;
  };

  const isEnabled = submitForm();
  if (token) {
    // eslint-disable-next-line react/prop-types
    props.history.push('/');
  }

 
  const displayButton = () => {
    if (isLoading) {
      return (
        <button className="btn btn-grey w-50" disabled={isLoading}>
          Submitting...
        </button>
      );
    }
    return (
      <button className="btn btn-grey w-50" disabled={!isEnabled}>
        LOGIN
      </button>
    );
  };

  const { email, password } = login;

  return (
    <span>
      <div className="container">
        <div className="row mt-5">
          <div className="col-12 mt-5 text-center col-md-5 mx-auto">
            <div className="ghana-logo">
              <img src="images/wificom_logo.png" alt="" />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                loginUser(login, props.history)
               
              }}
            >
              <div className="text-center mt-2 welcome-back">
                <p className="mb-4" style={{ fontSize: '25px' }}>
                  Welcome Back!
                </p>
              </div>
              <div className="form-horizontal col-md-12">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="email" className="welcome-back">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={onLoginChange}
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Email Address"
                  />
                </div>
                <div className="form-group text-left">
                  <label htmlFor="pwd" className="welcome-back">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={onLoginChange}
                    id="pwd"
                    className="form-control"
                    placeholder="Password"
                  />
                </div>

    
                <div className="form-group">{displayButton()}</div>
              </div>
            </form>
            <div className="forget-pwd">
              <p className="mb-2 welcome-back">
                <Link to="/forgot-password">Forgot Password?</Link>
              </p>
              {/* <p className="welcome-back">
                Do not have an account?{' '}
                <span>
                  <BigLink to="/">Sign Up</BigLink>
                </span>
              </p> */}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </span>
  );
};

export default withRouter(Login);

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer h-20 grow-0" id="">
      <div className="col-12 pt-2 d-flex justify-content-between footer-row d-md-none bg-white">
        <div className="font_small">
          <div className="social_media">
            <a
              href="https://wifi.com.ng/coverage/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Coverage Areas
            </a>
            <br />
            <a
              href="https://wifi.com.ng/terms/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </div>

          <div className="mt-1">
            <p>
              © {new Date().getFullYear()} Tizeti Network Limited <br />
              Suite C23 Cherub Mall, Eti-Osa, Lekki, Lagos
            </p>
          </div>
        </div>
        <div className="social_media pb-2">
          <a
            href="https://www.facebook.com/Tizeti"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f mr-2" />
          </a>
          <a
            href="https://twitter.com/tizeti"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter mr-2" />
          </a>
          <a
            href="https://www.instagram.com/tizeti.network/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-instagram mr-2" />
          </a>
          <a
            href="https://www.linkedin.com/company/tizeti"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin mr-2" />
          </a>
        </div>
      </div>

      <div className="d-none d-md-flex">
        <div className="col-12 pt-2 font_small d-flex justify-content-between footer-row bg-white">
          <div className="col-4">
            <p>
              © {new Date().getFullYear()} Tizeti Network Limited <br />
              Suite C23 Cherub Mall, Eti-Osa, Lekki, Lagos
            </p>
          </div>

          <div className="social_media">
            <a
              href="https://wifi.com.ng/coverage/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Coverage Areas
            </a>
            <br />
            <Link
              to="/terms-and-condition" 
            >
              Terms and Conditions
            </Link>
          </div>

          <div className="social_media col-4 pt-2 big_icons">
            <a
              href="https://www.facebook.com/Tizeti"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f mr-2" />
            </a>
            <a
              href="https://twitter.com/tizeti"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter mr-2" />
            </a>
            <a
              href="https://www.instagram.com/tizeti.network/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram mr-2" />
            </a>
            <a
              href="https://www.linkedin.com/company/tizeti"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin mr-2" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

function SmallScreenLogout(props) {
  const { history } = props;

  const logoutUser = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('email');
    // eslint-disable-next-line react/prop-types
    history.push('/');
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <span onClick={logoutUser} className="ml-4" style={{ cursor: 'pointer' }}>
      Logout
    </span>
  );
}

SmallScreenLogout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default withRouter(SmallScreenLogout);

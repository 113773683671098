import {
  FETCHED_PAYMENT_HISTORY,
  START_FETCHING_PAYMENT_HISTORY,
  STOP_FETCHING_PAYMENT_HISTORY,
} from '../../constants/types/accounts/AccountsTypes';

const initialState = {
  items: [],
  totalPages: '',
  allPages: '',
  currentPage: '',
  isLoading: false,
};

const PaymentHistoryReducerNew = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case START_FETCHING_PAYMENT_HISTORY:
      return {
        ...state,
        isLoading: true,
      };

    case FETCHED_PAYMENT_HISTORY:
      return {
        ...state,
        items: payload.items,
        totalPages: payload.totalPages,
        allPages: payload.allPages,
        currentPage: payload.currentPage,
        isLoading: false,
      };

    case STOP_FETCHING_PAYMENT_HISTORY:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default PaymentHistoryReducerNew;

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import React from 'react';

const ProfileHeader = () => {
  return (
    <div className="h-20 border-b-2 border-gray-300 text-center flex    items-center container ">
      <h4 className="">
        Profile &#8594;Manage Profile
      </h4>
    </div>
  );
};

export default ProfileHeader;

import { useReducer, useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import { AccountsReducer } from '../../../reducers/accounts/AccountsReducer';
import {
  GENIATECH_GET_USER_WITH_PHONE_OR_MAC_URL,
  GET_USER_PLAN_URL,
} from '../../../services/api/accounts/AccountsUrl';

const useMac = (mac) => {
  const [amount, setAmount] = useState(0);
  const [user, setUser] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [
    {
      detail,
      // amount
    },
  ] = useReducer(AccountsReducer, {
    detail: {},
    // amount: '',
  });

  useEffect(() => {
    const getDetailWithMac = () => {
      setIsLoading(true);
      const macidSearch = {
        macAddress: mac.trim(),
        phoneNumber: '090',
        macid: mac.trim(),
      };
      const getPlan = qs.stringify({
        action: 'getItemPrice',
        TB_PREF: '0_',
        user_id: 'cgate.tzt',
        password: 'megamound',
        macid: mac,
        item: 'INTERNET',
      });
      axios
        .all([
          axios.post(
            `${GENIATECH_GET_USER_WITH_PHONE_OR_MAC_URL}`,
            macidSearch,
          ),
          axios.post(`${GET_USER_PLAN_URL}`, getPlan, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
          }),
        ])
        .then((responseArr) => {
          setIsLoading(false);
          // this will be executed only when all requests are complete
          setUser(responseArr[0].data[0]);
          setAmount(responseArr[1].data.itemprice);
        });
    };

    getDetailWithMac();
  }, [mac]);

  return {
    // getDetailWithMac,
    detail,
    isLoading,
    amount,
    user,
  };
};
export default useMac;

import * as types from '../../constants/types/auth/AuthTypes';

// eslint-disable-next-line import/prefer-default-export
export const AellaPaymentReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case types.START_AELLA_PAYMENT:
      return {
        ...state,
        aellaLoading: true,
      };

    case types.STOP_AELLA_PAYMENT:
      return {
        ...state,
        aellaLoading: false,
      };

    default:
      // return state;
      throw new Error();
  }
};

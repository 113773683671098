import axios from 'axios';
import { toast } from 'react-toastify';
import { useReducer } from 'react';
import {
  GENIATECH_GET_PAYMENT_HISTORY_URL,
  GET_USER_PLAN_URL,
} from '../../services/api/accounts/AccountsUrl';
import {
  FETCHED_PAYMENT_HISTORY,
  START_FETCHING_PAYMENT_HISTORY,
  STOP_FETCHING_PAYMENT_HISTORY,
} from '../../constants/types/accounts/AccountsTypes';
import PaymentHistoryReducerNew from '../../reducers/payment-history/PaymentHistoryReducerNew';

const usePaymentHistoryNew = () => {
  const [
    { items, totalPages, currentPage, allPages, isLoading },
    dispatch,
  ] = useReducer(PaymentHistoryReducerNew, {
    items: [],
    payment: [],
    totalPages: '',
    allPages: '',
    currentPage: '',
    isLoading: false,
  });

  const getPaymentHistory = async (data) => {
    dispatch({ type: START_FETCHING_PAYMENT_HISTORY });
    try {
      const response = await axios.post(`${GET_USER_PLAN_URL}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (response.data) {
        dispatch({
          type: FETCHED_PAYMENT_HISTORY,
          payload: {
            items: response.data.items,
            totalPages: response.data.total_records,
            allPages: response.data.total_pages,
            currentPage: response.data.current_page,
          },
        });
      }
    } catch (e) {
      toast.info('Something went wrong!');
      if (e.response) {
        dispatch({ type: STOP_FETCHING_PAYMENT_HISTORY });
      }
    }
  };

  return {
    getPaymentHistory,
    items,
    totalPages,
    allPages,
    currentPage,
    isLoading,
  };
};

export default usePaymentHistoryNew;

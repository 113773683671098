/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import React from 'react';

const KycHeader = () => {
  return (
    <div
      className="font_small font-weight-light">
      <div className="container h-20 border-b-2 border-gray-300 text-center flex    items-center  ">
        <h4 className="">
          KYC  Upload Your File
        </h4>
      </div>
    </div>
  );
};

export default KycHeader;

export const START_LOGIN_USER = 'START_LOGIN_USER';
export const CHECK_IF_TOKEN_EXISTS = 'CHECK_IF_TOKEN_EXISTS';
export const STOP_LOGIN_USER = 'STOP_LOGIN_USER';
export const START_PRICING_PLAN = 'START_PRICING_PLAN';
export const STOP_PRICING_PLAN = 'STOP_PRICING_PLAN';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';
export const LOGIN_USER = 'LOGIN_USER';
export const START_FORGET_PASSWORD = 'START_FORGET_PASSWORD';
export const STOP_FORGET_PASSWORD = 'STOP_FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESSFUL = 'FORGET_PASSWORD_SUCCESSFUL';
export const UNABLE_TO_FORGET_PASSWORD = 'UNABLE_TO_FORGET_PASSWORD';
export const START_AELLA_PAYMENT = 'START_AELLA_PAYMENT';
export const STOP_AELLA_PAYMENT = 'STOP_AELLA_PAYMENT';
export const START_RESET_PASSWORD = 'START_RESET_PASSWORD';
export const STOP_RESET_PASSWORD = 'STOP_RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESSFUL = 'RESET_PASSWORD_SUCCESSFUL';
export const UNABLE_TO_RESET_PASSWORD = 'UNABLE_TO_RESET_PASSWORD';
export const START_CHANGING_PASSWORD = 'START_CHANGING_PASSWORD';
export const STOP_CHANGING_PASSWORD = 'STOP_CHANGING_PASSWORD';
export const CHANGING_PASSWORD_SUCCESSFUL = 'CHANGING_PASSWORD_SUCCESSFUL';
export const START_CREATING_REFERRAL = 'START_CREATING_REFERRAL';
export const STOP_CREATING_REFERRAL = 'STOP_CREATING_REFERRAL';
export const REFERRAL_SUCCESSFUL = 'REFERRAL_SUCCESSFUL';

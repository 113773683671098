/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import Nav from '../layouts/Nav';
import Footer from '../layouts/Footer';
import { usePaymentHistory } from '../../hooks/payment-history/usePaymentHistory';

const PaymentHistory = ({ location: { appState = {} } }) => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const {
    state: { paymentsHistory, isLoading },
    getPaymentsHistory,
    filterPaymentsHistory,
  } = usePaymentHistory();

  useEffect(() => {
    getPaymentsHistory(appState);
  }, []);

  const filterByDate = (e) => {
    e.preventDefault();
    const dates = {
      from: fromDate,
      to: toDate,
    };
    filterPaymentsHistory(appState.macid, dates);
  };

  const paymentHistoryBody =
    appState.macid === undefined ? (
      <tr>
        <td colSpan="5" className="text-center">
          Kindly Visit The Overview Page First
        </td>
      </tr>
    ) : typeof paymentsHistory === 'string' ? (
      <tr>
        <td colSpan="5">Payment History Is Currently Unavailable</td>
      </tr>
    ) : paymentsHistory && paymentsHistory.length ? (
      paymentsHistory.map((transaction, i) => (
        <tr key={i + 1}>
          <td>{i + 1}</td>
          <td>{transaction.Trans_no}</td>
          <td>{moment(transaction.Tran_date).format('DD-MMM-YYYY')}</td>
          <td>
            {transaction.Charge ? transaction.Charge : transaction.Payment}
          </td>
          <td>{transaction.Description}</td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="5">No Payment History</td>
      </tr>
    );

  return (
    <>
      <Nav />
      <hr className="mt-0 mb-3 d-flex d-md-none" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="col-12 zIndex_1">
              <form
                onSubmit={filterByDate}
                className="d-md-flex float-right mb-3"
              >
                <div className="d-md-flex">
                  <div className="col-md-6 col-sm-5 my-1 d-flex">
                    <label className="m-2 p-0">From:</label>
                    <input
                      type="date"
                      className="form-control col p-1 ml-1"
                      name="fromDate"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>

                  <div className="col-md-6 col-sm-5 my-1 d-flex">
                    <label className="m-2 p-0">To:</label>
                    <input
                      type="date"
                      className="form-control col p-1 dateField"
                      name="toDate"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end pr_14">
                  <button type="submit" className="btn btn-info my-1">
                    View
                  </button>
                </div>
              </form>
            </div>

            <div className="table-responsive rubik scroll tablePadding theadFixed">
              <table className="table text-center text-nowrap">
                <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Transaction ID</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody style={{ fontWeight: 'normal' }}>
                  {isLoading ? (
                    <tr>
                      <td colSpan="5">
                        <i
                          className="fa fa-spinner fa-spin"
                          style={{ fontSize: '120px' }}
                        />
                      </td>
                    </tr>
                  ) : (
                    paymentHistoryBody
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </>
  );
};

PaymentHistory.propTypes = {
  location: PropTypes.objectOf.isRequired,
};

export default PaymentHistory;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import StoreHeader from './StoreHeader';
import Store_Item from './Store_Item';

function StoreBody() {
  return (
    <div className="container">
      <StoreHeader />

      <div className=" border-2 p-4 mt-3 rounded-2xl border-gray-200 grid grid-cols-1 md:grid-cols-3 gap-3 mb-52">
        <Store_Item
          image_url="images/pole.png"
          item_description="Poles for Residential Customers"
          payment_url="https://paystack.com/pay/tzt-pole-for-residential"
        />
        <Store_Item
          image_url="images/radio.png"
          item_description="Ubiquiti/Cambium Radio"
          payment_url="https://paystack.com/pay/tzt-ubiquiti-cambium-radio"
        />
        <Store_Item
          image_url="images/POE.png"
          item_description="Ubiquiti/Cambium POE"
          payment_url="https://paystack.com/pay/tzt-ubiquiti-poe"
        />
        <Store_Item
          image_url="images/POE2.png"
          item_description="Cambium POE"
          payment_url="https://paystack.com/pay/tzt-cambium-poe"
        />
        <Store_Item
          image_url="images/Router.png"
          item_description="Router"
          payment_url="https://paystack.com/pay/tzt-router"
        />
        <Store_Item
          image_url="images/extender.png"
          item_description="Extender"
          payment_url="https://paystack.com/pay/tzt-extender"
        />
        <Store_Item
          image_url="images/pole.jpeg"
          item_description="Fabricated Pole"
          payment_url="https://paystack.com/pay/tzt-fabricated-pole"
        />
        <Store_Item
          image_url="images/router-adapter.jpeg"
          item_description=" Router Adapter"
          payment_url="https://paystack.com/pay/tzt-router-adapter"
        />
        <Store_Item
          image_url="images/Cable.png"
          item_description=" Cable(50 meters)"
          payment_url="https://paystack.com/pay/cable_50m"
        />
        <Store_Item
          image_url="images/Cable.png"
          item_description="Cable(70 meters)"
          payment_url="https://paystack.com/pay/cable_70m"
        />
        <Store_Item
          image_url="images/Cable.png"
          item_description="Cable(100 meters)"
          payment_url="https://paystack.com/pay/cable_100m"
        />
      </div>
    </div>
  );
}

export default StoreBody;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import qs from 'qs';
import { UseAccounts } from '../../../hooks/accounts/useAccounts';
import usePaymentHistoryNew from '../../../hooks/payment-history/usePaymentHistoryNew';
import PaymentHistoryTable from './PaymentHistoryTable';

const PaymentHistoryBody = () => {
  const { getPaymentHistory, items, isLoading } = usePaymentHistoryNew();
  const [{ accounts }] = UseAccounts();
  const [fromDate, setFromDate] = useState('2021-06-03');
  const [toDate, setToDate] = useState('2022-01-03');

  const macAddress = accounts[0]?.username;

  const onSubmit = (e) => {
    e.preventDefault();
    const data = qs.stringify({
   
      macid: macAddress,
      from: fromDate,
      to: toDate,
    });
    getPaymentHistory(data);
  };

  return (
    <div className=" border bg-gray-100 rounded-2xl mt-3 p-3 overflow-x-auto" id="" style={{ color: '#1E8E8B'}}  >
        <div className="flex justify-between">
           <p>Payment History</p>
        </div>
      <form onSubmit={onSubmit} className="">

      <div className="flex justify-between text-sm capitalize mb-3">
        <div>
          <label className="m-2 p-0 font_small">From:</label>
          <input
              type="date"
              className="form-control-sm"
              name="fromDate"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
          />
        </div>

        <div>
          <label className="m-2 p-0 font_small">To:</label>
          <input
              type="date"
              className="form-control-sm"
              name="toDate"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
          />
        </div>
        <button className=' p-4 rounded hover:bg-gray-500  hover:text-white  transition-all duration-150  ease-in-out '>View</button>
      </div>

        <PaymentHistoryTable items={items} isLoading={isLoading} />

      </form>


    </div>
  );
};

export default PaymentHistoryBody;

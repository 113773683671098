import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useReducer } from 'react';
import { START_NOMINATION, STOP_NOMINATION } from '../../constants/types/nomination/nominationTypes';
import nominationReducers from '../../reducers/nomination/nominationReducers';

toast.configure()

function useNomination() {
  const [
    { isLoading }, dispatch,
  ] = useReducer(nominationReducers, {
    isLoading: false,
  });

  const postNomination = async (data) => {
    dispatch({ type: START_NOMINATION });
    try {
      const response = await axios.post(`${'https://legacy.tizeti.com/geniatechapi/index.php'}`, data);
      if (response.data) {
        console.log(response);
        toast.info('Nomination submitted successfully');
        window.location.reload();
        dispatch({
          type: STOP_NOMINATION,
        });
      } else {
        toast.error('oops');
      }

    } catch (e) {
      toast.info(e.response.data.message);
      if (e.response) {
        dispatch({ type: STOP_NOMINATION });
      }
    }
  };


  return {
    postNomination,
    isLoading,
  };
}

export default useNomination;

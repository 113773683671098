/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import React from 'react';
import { UseAccounts } from '../../hooks/accounts/useAccounts';

const OverviewHeader = () => {
  const [{ accounts }] = UseAccounts();
  return (

    <div className='container grow-0'> 
        <div className='session flex flex-col-reverse  sm:flex-row justify-between  mt-3 '> 
          <div className='flex flex-col  justify-center  '>
            <h6 className='justify-self-end m-0 mt-2' style={{ color: '#1E8E8B' }}>  Welcome </h6>
            {(accounts.map((account) => (
          <h6 className="justify-self-start m-0 " style={{ color: '#1E8E8B' }}> {account.name}  </h6>
          )
            ))}
          </div>
          
          <div> 
             <a href="https://www.wifi.com.ng/oyo" className="md:w-full" target="_blank">
              <img
                  // src="images/banner.jpg"
                  src="https://res.cloudinary.com/dcsensx8u/image/upload/v1646690967/banner.jpg"
                  alt="Oyo Logo"
                  className="rounded-2xl"
                />
                </a>
            </div>
      </div>
    </div>
    // <div className="container pt-0 pb-0">
    //   <div className="d-inline-block" style={{ marginBottom: '-34px' }}>
    //     <p className="font_small font-weight-light pr-5 mr-5">
    //       Welcome
    //       <h6 className="font_big pr-5 mr-5 pt-2" style={{ color: '#1E8E8B' }}>
    //         {accounts[0]?.name ? accounts[0]?.name : 'N/A'}
    //       </h6>
    //     </p>
    //   </div>
    //   <div className="d-inline-block pl-5 ml-5">
    //     <a href="https://www.wifi.com.ng/oyo" target="_blank">
    //       <img
    //         src="images/banner.jpg"
    //         alt="Oyo Logo"
    //         className=""
    //         style={{ marginTop: '-25px', marginBottom: '-14px' }}
    //       />
    //     </a>
    //   </div>
    //   <hr />
    // </div>
  );
};

export default OverviewHeader;

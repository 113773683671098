/* eslint-disable react/jsx-indent */
import React from 'react';
import Layout from '../layouts';
import SubscribeBody from './SubscribeBody';

const Subscribe = () => {
  return (
    <div>
      <Layout>
        <SubscribeBody />
      </Layout>
    </div>
  );
};

export default Subscribe;

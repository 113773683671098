/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useParams, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import useMac from '../../../hooks/accounts/mac/useMac';

const Account = () => {
  const { macid } = useParams();

  const { isLoading, amount } = useMac(macid);



  const [monthNum, setMonthNum] = useState('1');
  // const [autoDebit, setAutoDebit] = useState(true);

  // const payWithPaystack = () => {
  //   if (autoDebit && monthNum === '1' && amount === '12500')
  //     subscribeForRecurringPlanWithPaystack(user);
  //   return subscribeForPlanWithPaystack(monthNum, amount, user);
  // };

  return (
    <div className="text-center">
      <span className="sub_text font_22">Current Plan</span>
      <div className="row justify-content-center align-items-center">
        {isLoading ? (
          <small>loading...</small>
        ) : (
          <div className="col-md-7 col-xl-5 mb-4">
            <div className="card box shadow h-100 py-2">
              <div className="card-body">
                <div className="row col-auto align-items-center">
                  <div className="col-auto">
                    <div className=" text-center d-flex mb-0">
                      <div>
                        <img
                          src="images/residential.png"
                          alt="House"
                          className="mr-2 mt-3"
                        />
                      </div>
                      <div className="text-left">
                        <div className="font_18 text-wrap bolder_font margin_bottom" />
                        <div className="form-group d-flex mb-0 pb-0">
                          <select
                            defaultValue={monthNum}
                            id="monthNum"
                            className="form-control form-control-sm mt-2 mr-3"
                            onChange={(e) => setMonthNum(e.target.value)}
                          >
                            <option value="1">1 Month</option>
                            <option value="2">2 Months</option>
                            <option value="3">3 Months</option>
                            <option value="4">4 Months</option>
                            <option value="5">5 Months</option>
                            <option value="6">6 Months</option>
                            <option value="7">7 Months</option>
                            <option value="8">8 Months</option>
                            <option value="9">9 Months</option>
                            <option value="10">10 Months</option>
                            <option value="11">11 Months</option>
                            <option value="12">12 Months</option>
                          </select>
                          {amount ? (
                            <label
                              className="d-flex mt-3 lighter_font"
                              htmlFor="monthNum"
                            >
                              NGN
                              <span className="ml-1">{monthNum * amount}</span>
                            </label>
                          ) : null}
                        </div>
                        {/* {amount === '12500' && monthNum === '1' ? (
                          <div className="custom-control custom-checkbox">
                            <label
                              htmlFor="autoDebit"
                              className="checkbox-inline mt-0 lighter_font text-nowrap"
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                checked={autoDebit}
                                id="autoDebit"
                                onChange={(e) => setAutoDebit(e.target.checked)}
                              />
                              <span className="ml-1 custom-control-label">
                                I want to be charged monthly
                              </span>
                            </label>
                          </div>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row col-auto justify-content-center align-items-center">
                <div className="col-auto align-items-center py-3 mr-2 d-flex">
                  <div className="payChannel_text text-nowrap m-0 p-0 pr-1 mt-1">
                    Pay with:
                  </div>
                  <div className="btn-group btn-group-sm" role="group">
                    <button
                      type="submit"
                      className="btn btn-outline-success"
                      // onClick={() => payWithPaystack()}
                    >
                      <img
                        alt="paystack logo"
                        src="../images/paystack.png"
                        width="115px"
                      />
                    </button>
                    {/* <a
                      href="https://www.quickteller.com/tizeti"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="submit" className="btn btn-outline-success">
                        <img
                          alt="quickteller_logo"
                          src="../images/quickteller.png"
                          width="115px"
                        />
                      </button>
                    </a> */}
                    {/* <a
                      href="https://login.remita.net/remita/onepage/7337967454/service.spa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="submit" className="btn btn-outline-success">
                        <img
                          alt="remita_logo"
                          src="../images/remita.jpeg"
                          width="115px"
                        />
                      </button>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

Account.proptype = {
  isLoading: PropTypes.bool,
  userPlans: PropTypes.object,
  user: PropTypes.object,
  loadingSlyde: PropTypes.bool,
  loadingTeller: PropTypes.bool,
};

export default withRouter(Account);

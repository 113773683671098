import * as types from '../../constants/types/accounts/AccountsTypes';

// eslint-disable-next-line import/prefer-default-export
export const AccountsReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_STARTS:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };

    case types.FETCHED_ACCOUNTS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        accounts: payload,
      };

    case types.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    case types.FETCH_USER_PLAN_STARTS:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCH_USER_PLAN:
      return {
        ...state,
        isLoading: false,
        itemPrices: payload,
      };

    case types.FETCH_USER_PLAN_STOPS:
      return {
        ...state,
        isLoading: false,
      };

    case types.START_FETCHING_USAGE:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCHED_USAGE:
      return {
        ...state,
        isLoading: false,
        usage: payload,
      };

    case types.FETCHED_THREE_MONTH_USAGE:
      return {
        ...state,
        isLoading: false,
        threeMonthUsage: payload,
      };

    case types.STOP_FETCHING_USAGE:
      return {
        ...state,
        isLoading: false,
      };

    case types.GET_EXPIRY_DATE:
      return {
        ...state,
        expiry: payload,
      };
    case types.FETCHED_DETAIL:
      return {
        ...state,
        detail: payload.detail,
        amount: payload.amount,
        isLoading: false,
      };

    default:
      throw new Error();
  }
};

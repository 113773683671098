/* eslint-disable react/jsx-indent */
import React from 'react';
import Layout from '../layouts';
import StoreBody from './StoreBody';

const Store = () => {
  return (
    <div>
      <Layout>
        <StoreBody />
      </Layout>
    </div>
  );
};

export default Store;

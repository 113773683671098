/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { UseAccounts } from '../../../hooks/accounts/useAccounts';
import { useUser } from '../../../hooks/user/useUser';
import Layout from '../../layouts';
import StandardPlanBody from './StandardPlanBody';
import StandardPlanHeader from './StandardPlanHeader';

const StandardPlan = () => {
  const transaction_limit = 800000;
  const [monthNumber, setMonthNumber] = useState('1');
  const standardprice = 750;
  const standardpriceTotal = monthNumber * 750;
  const month = [24, 36, 48];
  const [monthNum, setMonthNum] = useState('1');
  const months = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [{ itemPrices, accounts }] = UseAccounts();
  const [
    {
      user: { firstName, lastName },
    },
  ] = useUser();
  return (
    <div>
      <Layout>
        <div className="container">
          <StandardPlanHeader />
          <div className="flex  flex-col  mt-3 p-3  rounded-2xl  w-full border-1 border-gray-200">
            <h3 className="text-center w-full text-base">
              {' '}
              Paddy Cover Standard Plan
            </h3>
            <StandardPlanBody
              transaction_limit={transaction_limit}
              standardprice={standardprice}
              standardpriceTotal={standardpriceTotal}
              monthNumber={monthNumber}
              setMonthNumber={setMonthNumber}
              monthNum={monthNum}
              setMonthNum={setMonthNum}
              months={months}
              itemPrices={itemPrices}
              accounts={accounts}
              firstName={firstName}
              lastName={lastName}
              month={month}
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default StandardPlan;

import { API_URL } from '../BaseUrl';

// eslint-disable-next-line import/prefer-default-export
// export const GENIATECH_GET_USER_INFORMATION_URL = `http://api.tizeti.com:5000/api/v1/getUserInformation`;
export const GET_CUSTOMER_EXPIRY_URL = `https://api.tizeti.com/api/v1/legacy/getExpiration`;
export const GENIATECH_URL = `https://legacy.tizeti.com/geniatechapi/index.php`;
export const GENIATECH_GET_USER_INFORMATION_URL = `${API_URL}/getUserInformation`;
export const GET_USER_PLAN_URL = `https://api.tizeti.com/api/v1/legacy/getDebtorTransPaginated`;
export const GET_ITEM_PRICE_URL = `https://api.tizeti.com/api/v1/legacy/getItemPrice`;
export const GENIATECH_GET_USER_WITH_PHONE_OR_MAC_URL = `${API_URL}/getUserInfoWithPhoneorMac`;
export const GENIATECH_GET_PAYMENT_HISTORY_URL = `${API_URL}/frontAccounting/userTransaction/0`;

/* eslint-disable camelcase */
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GET_RADIO_PRICE } from '../../services/api/pricing-plan/PricingPlanUrl';

export const useRadioPrice = () => {
  const [radioPrice, setRadioPrice] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getRadioPrice = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(`${GET_RADIO_PRICE}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        const item = response.data.result.data;
        const filteredAndMappedData =
          item.length &&
          item
            .filter((ite) => ite.id === 'dcce12e8-e3fc-4950-bf94-c5152cfeef11') // Replace 'criteria' with your filter condition
            .map((ite) => ({
              // Replace with your desired properties
              id: ite.id,
              name: ite.itemName,
              price: ite.amount,
            }));

        // Step 4: Store the processed data in the state

        // Step 4: Store the processed data in the state
        setRadioPrice(filteredAndMappedData);
        setIsLoading(false);
      }
    } catch (e) {
      toast.error('Unsuccessful');
      setIsLoading(false);
    }
  };

  return { getRadioPrice, isLoading, radioPrice };
};

export default useRadioPrice;

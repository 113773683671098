/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../../services/api/BaseUrl';

const TermsandConditions = () => {
  const decodedParams = {};
  const location = useLocation();
  const decodeParam = (param) => atob(param);
  const queryParams = new URLSearchParams(location.search);

  const params = [
    { key: 'email', params: queryParams.get('email') },
    { key: 'name', params: queryParams.get('name') },
    { key: 'mac_id', params: queryParams.get('mac_id') },
    { key: 'user_id', params: queryParams.get('user_id') },
    { key: 'installation_id', params: queryParams.get('installation_id') },
  ];

  params.forEach((value, index) => {
    if (value.params) {
      decodedParams[value.key] = decodeParam(value.params);
    }
  });
  
  const [isloading, setIsLoading] = useState(false);
  const [hasAcceptedTerms, SetAcceptedTermsStatus] = useState();

  const url = window.location.href;
  const domainUrl = url.split('terms')[0];

  useEffect(() => {
    const checkTermsStatus = async () => {
      if (decodedParams.installation_id) {
        const res = await axios.get(
          `https://api.tizeti.com/api/v1/account/terms/${decodedParams.installation_id}`,
        );

        SetAcceptedTermsStatus(!!res.data);
      }
    };

    checkTermsStatus();
  });

  const handleCustomerOnboarding = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/account/accept_terms_condition`,
        {
          ...decodedParams,
        },
      );

      if (response.status === 200) {
        toast('Welcome aboard...');
        setIsLoading(false);
        setTimeout(() => {
          window.location.replace(domainUrl);
        }, 2000);
      }
    } catch (err) {
      if (err.response.data.message === 'Terms have already been approved') {
        toast.info('Terms have already been approved');
        setTimeout(() => {
          window.location.replace(domainUrl);
        }, 2000);
      } else {
        toast.error('Soomething went wrong, check your internet and try again');
      }
    }
  };

  return (
    <div className=" md:w-3/5  w-full m-auto">
      <Link to="/">
        <figure className="mx-auto my-10 w-fit">
          <img
            src="https://wifi.com.ng/wp-content/uploads/2019/07/wifilogo.png"
            alt="wifi logo"
          />
        </figure>
      </Link>

      <div
        id="terms_and_condition"
        className="w-full mx-auto flex flex-col gap-6 p-4"
      >
        <main className="w-full my-10">
          <div>
            <header>
              <h1 className="heading text-4xl mb-2">Terms and Conditions</h1>
              <p>
                Please read these terms and conditions carefully before using
                our services.
              </p>
            </header>

            <ul className="m-10 flex flex-col gap-6 list-decimal">
              <li>
                <header className="flex flex-row items-start">
                  <h4 className="sub-title">Definitions:</h4>
                </header>
                <p className="sub-heading">
                  In these Terms, unless the context otherwise provides, the
                  following words shall have the following meanings:
                </p>
                <section className="ml-2 flex my-4 flex-col gap-6">
                  <p>
                    <strong>“Agreement”</strong> means this Terms and Conditions
                    as read, understood and signed by the Customer, applicable
                    from time to time;
                  </p>
                  <p>
                    <strong>“Channel Partner”</strong> means a company that
                    partners with Tizeti to sell the Services;
                  </p>
                  <p>
                    <strong>“Customer”</strong> means any person or organization
                    who has agreed to utilize any of Tizeti’s Services pursuant
                    to these Terms and Conditions;
                  </p>
                  <p>
                    <strong>“Government Authority”</strong> means any national,
                    supranational, regional or local government or governmental,
                    legislative, administrative, fiscal, judicial or
                    government-owned body, department, commission, authority,
                    tribunal, agency or other entity exercising executive,
                    legislative, judicial, regulatory or administrative powers
                    or functions of or pertaining to government or any
                    subdivision thereof, including any duly authorized law
                    enforcement agencies; and
                  </p>
                  <p>
                    <strong>“Services”</strong> means the provision of internet
                    to its Customer and includes all service packages, service
                    plans, schemes and offers introduced by Tizeti from time to
                    time.
                  </p>
                </section>
              </li>

              <li>
                <header>
                  <h4 className="sub-title">Our service scope:</h4>
                </header>
                <ul className="ml-2 flex my-4 flex-col gap-6">
                  <li>
                    <p>
                      <strong>2.1.</strong> Tizeti shall provide Broadband
                      Access Services to the Customer for the period which the
                      Customer has subscribed to the use of the Services.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2.2.</strong> Tizeti reserves the right to
                      withdraw, amend, modify or delete account related codes,
                      IDs, email ID, user id, or passwords/s
                      allocated/assigned/shared by Tizeti to the Customer, at
                      any time without assigning any reason thereof.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2.3.</strong> The Services remain exclusive
                      property of TIZETI, and the Customer is authorized to use,
                      only during the sustenance of this Agreement. The right to
                      use all Services, Application or features or Access
                      parameters including but not limited to email ID, user ID,
                      password etc. as set out under the services, will cease to
                      exist on the day your Agreement expires with Tizeti.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2.4.</strong> Tizeti reserves the right to
                      withdraw, amend, modify or delete Services/s, Service
                      packages, Service plans, Schemes, Offers, at any time
                      without assigning any reasons thereof, provided that
                      notice of such action shall be given to the User at a
                      reasonable time thereafter.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.5.</strong> Tizeti shall not be liable for any
                      loss, cost or damage arising from transactions the
                      Customer enters into with third parties using the Services
                      (such transactions shall include and are not limited to
                      buying or renting goods or services from such third
                      parties or ordering goods from other organizations using
                      our internet services).
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.6.</strong> Neither Tizeti nor the Channel
                      Partners warrants that the Services will be uninterrupted
                      or error-free or that any information, software or other
                      material accessible on the Service isfree of viruses,
                      worms, Trojan horses or other harmful components.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2.7.</strong> Neither Tizeti nor the Channel
                      Partner shall be responsible for the installation of
                      hardware or software needed for the Service package and
                      the Customer shall ensure that competent persons have
                      satisfactorily done the same. Without prejudice to the
                      foregoing Tizeti or the Channel Partner will render
                      customer advisory service sans recourse.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2.8.</strong> Tizeti or its Channel partners shall
                      not be responsible for compatibility problem(s) of
                      hardware/software at the customer premises. The customer
                      herewith expressly acknowledges and agrees that
                      installation of applications at its hardware shall be
                      carried out by a competent person and Tizeti shall not be
                      liable for any loss or damage to Customer’s hardware
                      (PC/Computer/Server) during the course of such
                      installation of sustenance of this Agreement.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.9.</strong> Tizeti accepts no responsibility
                      whatsoever for the content of any material from other
                      websites, organizations, which may be accessed through the
                      Services.
                    </p>
                  </li>

                  <li>
                    <p>
                      <strong> 2.10.</strong> Tizeti reserves the right to block
                      access to any such information which is not permissible as
                      per the law of the Federal Republic of Nigeria and/or as
                      directed by the authorized honourable representative
                      offices of Federal Government of Nigeria.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.11.</strong> Our day commences on 00:00:00 am
                      and ends in another 23:59:59pm and internet expire
                      00:00:00 of the expiration day.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.12.</strong> The devices belong to the company
                      and will be withdrawn from the customer if the customer
                      account is expired for 90 days.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.12.1</strong> For installations done based on
                      promotional prize offers, devices will be withdrawn if
                      account is expired for 7days.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.13</strong> Upon damage to the device, the
                      customer takes full responsibility. Prices for replacement
                      of devices are subject to market forces.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.14</strong> However, the safety of the devices
                      is the customer’s responsibility. Hence, Customer is
                      advised to take full responsibility and its safety
                      precaution in safe-guarding the device.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.15</strong> Unless within the reasonable
                      confines of any applicable law, no member of our staff
                      shall be held hostage.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.16</strong> The relocation of a fixed CPE is
                      subject to cost and must be within the coverage area.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.17</strong> Upon no coverage, Customer will not
                      be refunded on setup if the customer has already been
                      installed and confirmed the internet is working or refund
                      of subscription.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.18</strong> Customer’s card will be charged
                      within 7 days if our device has not been retrieved. The
                      Customer is required to ensure that the registered card
                      can be charged as the need arises, failing which legal
                      action may be taken.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.19</strong> A customer account cannot be
                      suspended or put on hold once payment is made.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.20</strong> There will be multiple SSID on your
                      device for test and development. If for any reason you do
                      not want this, there will be a charge to this.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.21</strong> Tizeti has the right to apply
                      strict restrictions to any site depending on the demand on
                      the network.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.22</strong> Tizeti reserves the right to
                      upgrade the network capacity so as to provide the desired
                      speed of Services to the Customer, without prior
                      information either to the Customer or the Channel partner.
                      However, the customer understands and agrees that Tizeti
                      services may be affected by factors including but not
                      limited to the type of equipment being used by you, the
                      internet site/server being visited, network availability,
                      the number of users accessing the internet network in an
                      area/Location; strength of the signal and equipment
                      distance from the nearest antenna and ecological
                      conditions which shall impact operation at the desired
                      speed at all times.nd on the network.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.23</strong> The customer understands further
                      that the internet contains unedited materials, some of
                      which are sexually explicit or may be offensive to some
                      people. Customer’s access to such materials will be at his
                      own risk. Tizeti or the Channel partners have no control
                      over the same and accepts no responsibility whatsoever or
                      such materials.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.24</strong> Tizeti observes a zero-tolerance
                      policy regarding the delivery of Spam or unwanted
                      commercial messages using the Service. Notwithstanding
                      anything else in this agreement, the Customer will not
                      send, cause to be sent or facilitate, Spam using the
                      Service. Accounts that send Spam or that we believe may be
                      facilitating Spam may be suspended and terminated and
                      Tizeti will work with the proper authorities in the event
                      of a prosecution.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.25</strong> Tizeti or its channel partner
                      reserves the right to release details of a customer to any
                      security agents, as required by law, in a case the account
                      was used to commit fraud.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.26</strong> Tizeti or its Channel partner will
                      not be held responsible if the service is used for
                      impersonation.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.27</strong> Tizeti requires the customer to
                      provide information on the customer’s profile at the time
                      of sign up.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.28</strong> Tizeti reserves the right to
                      monitor network traffic and usage patterns for security
                      and maintenance purposes.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.29</strong> The customer acknowledges that
                      Tizeti may need to access and inspect their equipment or
                      premises to troubleshoot network issues or ensure
                      compliance with service terms.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.30</strong> Tizeti may periodically update or
                      modify the terms and conditions of service, and it is the
                      customer's responsibility to stay informed about any
                      changes.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.31</strong> The customer agrees not to engage
                      in any activities that may disrupt or degrade the network
                      performance for other users, including but not limited to
                      excessive bandwidth consumption or malicious network
                      attacks.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.32</strong> Tizeti may suspend or terminate
                      service without notice if there is evidence of fraudulent
                      activity, illegal content transmission, or violation of
                      service terms.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.33</strong> The customer is responsible for
                      maintaining the security of their network and devices
                      connected to the Tizeti service, including implementing
                      appropriate firewall and antivirus measures.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.34</strong> Tizeti reserves the right to
                      throttle or restrict bandwidth for users who exceed
                      specified data usage limits to ensure fair and equitable
                      access for all customers
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.35</strong> The customer acknowledges that
                      Tizeti cannot guarantee the security or privacy of data
                      transmitted over the internet and agrees to use encryption
                      or other security measures as necessary.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.36</strong> Tizeti may collaborate with law
                      enforcement agencies or regulatory authorities to
                      investigate and prosecute illegal activities conducted
                      using its network services.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.37</strong> The customer agrees to indemnify
                      and hold Tizeti harmless from any claims, damages, or
                      losses arising from their use of the service, including
                      but not limited to copyright infringement, defamation, or
                      unauthorized access.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.38</strong> Tizeti reserves the right to
                      terminate service for customers who repeatedly violate
                      service terms or engage in prohibited activities, without
                      refund or compensation.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.39</strong> The account owner is responsible
                      for ensuring that individuals connected to their network
                      comply with the terms and conditions of service, and shall
                      be held liable for any misuse or illegal activities
                      conducted through their connection.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.40</strong> The account owner is responsible
                      for ensuring that individuals connected to their network
                      comply with the terms and conditions of service, and shall
                      be held liable for any misuse or illegal activities
                      conducted through their connection.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.41</strong> The account owner agrees to take
                      reasonable measures to secure their network and devices,
                      including implementing password protection and access
                      controls, to prevent unauthorized usage.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.42</strong> Tizeti may hold the account owner
                      financially liable for damages or losses incurred as a
                      result of unauthorized activities conducted through their
                      network connection, including but not limited to copyright
                      infringement, hacking, or fraud.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong> 2.43</strong> The account owner acknowledges that
                      they are solely responsible for monitoring and managing
                      the usage of their network, and shall promptly report any
                      suspicious or unauthorized activity to Tizeti for
                      investigation.
                    </p>
                  </li>
                </ul>
              </li>

              <li>
                <header>
                  <h3 className="sub-title">Privacy :</h3>
                </header>
                <ul className="ml-2 flex my-4 flex-col gap-6">
                  <li>
                    <p>
                      <strong> 3.1.</strong>
                      Tizeti may provide Customer’s information, in response to
                      a request from Government Authority or other website
                      administrators, to detect and prevent security incidents,
                      crime and prosecute offenders. In line with the relevant
                      laws of the Federal Republic of Nigeria, Tizeti may also
                      provide information to protect national security.
                    </p>
                    <p>
                      <strong> 3.2.</strong> Additional information relating to
                      privacy shall be contained in Tizeti’s Privacy Policy.
                    </p>
                  </li>
                </ul>
              </li>

              <li>
                <header>
                  <h3 className="sub-title">Customer's Obligations :</h3>
                </header>
                <p>
                  You may not use the Services in a way that risks degradation
                  of services levels to other customers, third parties and put
                  our system at risk. If we believe that you are using our
                  Services in any of these ways, as mentioned, we are entitled
                  to reduce, suspend and/or terminate any or all of our Services
                  without giving you prior notice.
                </p>
              </li>

              <li>
                <header>
                  <h3 className="sub-title">Force Majeure :</h3>
                </header>
                <p>
                  Tizeti is not liable for failure to perform its obligation if
                  such failure arises as a result of a Force Majeure which
                  includes (but is not limited to) acts of God (including fire,
                  flood, earthquake, storm, hurricane or other natural
                  disasters), war, invasion, an act of foreign enemies,
                  hostilities (regardless of whether war is declared), civil
                  war, rebellion, revolution, insurrection, military or usurped
                  power or confiscation, terrorist activities, nationalization,
                  government sanction, blockage, embargo, labour dispute,
                  strike, lockout or industrial disputes or interruption or
                  failure of electricity or telecommunications service; actions
                  of the regulator or local or national governments or other
                  authorities.
                </p>
              </li>

              <li>
                <header>
                  <h3 className="sub-title">Suspension of Suspense :</h3>
                </header>
                <p>
                  Tizeti may suspend any or all of Services immediately without
                  notice if: payments made to Tizeti have not been realized for
                  any reason due to Customer’s fault; Tizeti has to do so by law
                  or in line with an Agreement; Tizeti has reason to believe
                  that the Customer has provided Tizeti with false, inaccurate
                  or misleading information either for the purpose of obtaining
                  our Services and/or Access Devices; Tizeti believes that the
                  Customer or another person at the Customer’s office or home
                  has committed, or maybe committing, any act which contravenes
                  the terms of the Services Agreement by using the Services; or
                  Tizeti needs to carry out any maintenance, repairs or
                  improvements to any part of its services or its systems,
                  provided that reasonable notice may be given for this purpose.
                </p>
              </li>

              <li>
                <header>
                  <h3 className="sub-title">Disclaimer :</h3>
                </header>
                <p>
                  While every effort is made by Tizeti to provide the highest
                  quality of Service, the Customer acknowledges that Tizeti
                  network is interconnected with high-speed internet links
                  provided by other service providers/ entities that are
                  responsible to maintain these links as reliable as possible.
                  Tizeti does not own any responsibility in case of
                  interruptions in the inter-connect network beyond its
                  reasonable control. Tizeti shall not be responsible whatsoever
                  for any interruption caused by the quality of these links,
                  defect in connectivity or any inconvenience, damages or any
                  other liability whatsoever wither from the Customer or anyone
                  else in this regard.
                </p>
              </li>

              <li>
                <header>
                  <h3 className="sub-title">
                    Amendment of Terms and Conditions :
                  </h3>
                </header>
                <p>
                  Tizeti may at any time improve, modify, amend or alter the
                  terms and conditions of the Services if there is any change or
                  amendment to any law or regulation which applies to Tizeti or
                  the Services; Tizeti decides that the Services should be
                  altered for reasons of quality of service or otherwise for the
                  benefit of its customers or, in Tizeti’s reasonable option, it
                  is necessary to do so; for security, technical or operational
                  reasons; in all other events, where Tizeti reasonably
                  determines that any modification to its system or change in
                  its trading, operating or business practice or policy is
                  necessary to maintain or improve the Services.
                </p>
              </li>
            </ul>
          </div>
        </main>

        {decodedParams.email && (
          <>
            {!hasAcceptedTerms && (
              <>
                <p>
                  By clicking <strong> "I Agree"</strong> or accessing our
                  services, you acknowledge that you have read, understood, and
                  agree to be bound by the terms and conditions outlined herein.
                  If you do not agree with any part of these terms and
                  conditions, please do not proceed further.
                </p>
                <p>
                  These terms and conditions govern your use of our services
                  provided by Tizeti Network Limited, including but not limited
                  to internet access, network services, and related features.
                  They also encompass your obligations, rights, and limitations
                  when using our services.
                </p>
              </>
            )}

            <div className="ml-auto font-semibold">
              <p className="mb-0  w-fit">Signatory</p>
              <p>{decodedParams.email || ''}</p>
            </div>

            {!hasAcceptedTerms && (
              <div className="flex item-center gap-[20%]">
                {!isloading && (
                  <a
                    href="mailto:sales@tizeti.com"
                    disabled={isloading}
                    className="px-6 py-3 bg-red-500 w-full mx-auto text-center rounded-lg text-white mt-6 mx-auto"
                    id="i_reject"
                  >
                    Reject
                  </a>
                )}
                <button
                  disabled={isloading}
                  className={`px-6 py-3 w-full mx-auto  rounded-lg text-gray-200 mt-6 mx-auto ${
                    isloading
                      ? 'bg-gray-500 opacity-40 w-2/5 ml-auto'
                      : 'bg-green-500'
                  }`}
                  id="i_agree"
                  onClick={handleCustomerOnboarding}
                >
                  I Agree
                </button>
              </div>
            )}

            {hasAcceptedTerms && (
              <p className="text-center">
                *<em> Terms have been accepted </em>*
              </p>
            )}
          </>
        )}
      </div>

      <div className="mt-20"></div>
    </div>
  );
};

export default TermsandConditions;

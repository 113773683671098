import React, { useEffect, useState } from 'react';
import qs from 'qs';
import Select from 'react-select';
import useNomination from '../../hooks/nomination/useNomination';
import './App.css';
import useTizetiCloud from '../../hooks/tizeti-cloud/useTizetiCloud';

const TizetiCloud = () => {

  const { list, postCloud, getCloudPlans } = useTizetiCloud();
  const [cloudplans, setCloudPlans] = useState();
  const [cloudname, setCloudName] = useState();
  const [cloudemail, setCloudEmail] = useState();



  // console.log(item.name);
  const data = qs.stringify({
    action: 'post',
    name: cloudname,
    plans: cloudplans?.value,
    email: cloudemail,
  });

  console.log(data)

  const onSubmit = (e) => {
    e.preventDefault();
    postCloud(data);
  };
  
  const handleChange = value => {
    setCloudPlans(value);
  };

  useEffect(() => {
    getCloudPlans( qs.stringify({ action: 'get_staff'  }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


 let CloudOptions = list.map(function (lis) {
  return { value: lis.cloudplans, label: lis.cloudplans };
})
  

  return (
    <body>
    <nav class="navbar fixed-top navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#"><img src="images/tizeti-logo.png" alt="" class="img-fluid"/></a>
      </div>
    </nav>  
    <section class="cat" id="category"  style={{ backgroundImage:  `url("images/tiz.jpeg")`, opacity: `0.7` }}>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-5 pt-3">
        
            <h2>Tizeti <br/>Cloud</h2>
            <div class="box">
              <form   onSubmit={onSubmit}>  
              <div class="mb-4">
                <label class="mb-1">Name</label>
                <input class="form-control" type="name" aria-label="Default select example" name="cloudname" value={cloudname}   placeholder="Enter your name" onChange={(e) => setCloudName(e.target.value)} required>
                    </input>
                    </div>
                    <div class="mb-4">
                <label class="mb-1">Email</label>
                <input class="form-control" type="email" aria-label="Default select example" name="cloudemail" value={cloudemail}   placeholder="Enter your email address" onChange={(e) => setCloudEmail(e.target.value)} required>
                    </input>
                    </div>
                <div class="mb-4">
                <label class="mb-1">Plans</label>
                    <Select 
                        name="cloudplans"
                        class="form-select"
                        onChange={handleChange}
                        labelKey='cloudplans'
                        valueKey='cloudplans'
                        value={cloudplans}
                        options={CloudOptions} />
                    </div>
                    
                <div>
                  <button class="btn btn-primary px-4 py-2 mt-4">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center">
            <small>©2022 Tizeti Inc.</small>
          </div>
        </div>
      </div>
    </footer>
  </body>


);
}

export default TizetiCloud;

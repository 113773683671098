// eslint-disable-next-line import/prefer-default-export
import React from 'react';

export default {
  getTodaysDate() {
    return new Date().toJSON().slice(0, 10);
  },

  getSevenDaysAgoDate() {
    const date = new Date();
    return new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000)
      .toJSON()
      .slice(0, 10);
  },

  getThreeMonthsAgoDate() {
    const date = new Date();
    return new Date(date.getTime() - 90 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10);
  },

  getDateAndMonth(item) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const date = new Date(item.from).getDate();
    const month = monthNames[new Date(item.from).getMonth()];
    return <td>{`${date}-${month}`}</td>;
  },

  formatCurrency(wallet) {
    return Number(wallet).toLocaleString();
  },
  generateReferenceForPaystackPayment() {
    return `TZT_NIG_${Math.floor(Math.random() * 100000000000 + 1)}`;
  },
  formattedPaystackSubscriptionAmount(monthNum, itemprice) {
    const newAmount = (monthNum * itemprice * 100 + 10000) / (1 - 0.015);
    return Math.ceil(newAmount / 100) * 100;
  },
  isPhoneIsValid(phoneNumber) {
    return /^[0]\d{10}$/.test(phoneNumber);
  },
};

/* eslint-disable react/jsx-one-expression-per-line */
import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { KycContext } from '../../../context/kyc/KycContext';
import './index.css';

const FilePreview = ({}) => {
  const { view } = useContext(KycContext);
  const showPreview = () => {
    if (typeof view === 'object') {
      return view.map((vi) => {
        return (
          <>
            <img src={vi} alt="view" className="w-100" />
            <hr />
          </>
        );
      });
    }
    return <img src={view} alt="view" className="w-100" />;
  };

  return (
    <div className="">
      <ToastContainer />

      <div
        className="border-0 m-5 p-5 rounded flex flex-col space-y-4"
        style={{ backgroundColor: '#F5F7F7' }}
      >
        <h4
          className=" font-weight-light"
          style={{ fontSize: '18px', color: '#0C2F52' }}
        >
          View KYC
        </h4>
        <p className="ont-weight-light" style={{ fontSize: '13px' }}>
          Documents uploaded shoul be strictly: <br />
        </p>
        <p className="fa-dot-circle-o" style={{ fontSize: '13px' }}>
          {' '}
          National Identification Number (NIN)
        </p>
        <p className="fa-dot-circle-o" style={{ fontSize: '13px' }}>
          {' '}
          Driver's License
        </p>
        <p className="fa-dot-circle-o" style={{ fontSize: '13px' }}>
          {' '}
          International Passport Page
        </p>
        <div
          className="border border-primary text-center justify-content-center"
          style={{ width: '300px' }}
        >
          <div className="card p-4 text-center card-verify">
            {view ? showPreview() : <span className="far fa-file-image" />}

            <p>File Preview</p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default FilePreview;

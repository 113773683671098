import React from 'react';
import { Link } from 'react-router-dom';

const NotificationBar = () => {
  return (
    <div className="col-12 bg-danger" style={{ height: '60px' }}>
      <p className="pt-3 text-center notifyFont">
        <Link
          to="/profile"
          className="text-white"
          style={{ textDecorationLine: 'none', fontFamily: 'sans-serif' }}
        >
          Kindly Update Profile With Your NIN. Click Here
        </Link>
      </p>
    </div>
  );
};

export default NotificationBar;
